import React, { useState, useEffect } from 'react';
import actions from '../../redux/actions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import ScreenLoader from '../../components/ScreenLoader';
import ConfirmationModal from '../../components/Models/ConfirmationModal';
import { isRoleExits } from '../../utils';

const FancyResult = () => {
    const RunningFancyList = useSelector((state) => state.match?.RunningFancyList?.Result);

    let { marketid } = useParams();
    const [loader, setLoader] = useState(true);
    const [type, setType] = useState(false)
    const [data, setData] = useState([])
    const [modalData, setModalData] = useState({
        title: '',
        selectionId: '',
        marketId: '',
        description: '',
        operationName: '',
        runValue: ''
    })

    useEffect(() => {
        if (RunningFancyList) { setData(RunningFancyList) }
    }, [RunningFancyList])

    useEffect(() => {
        (async () => {
            await actions.getRunningFancyAction(marketid, false)
            setLoader(false)
        })();
    }, [marketid]);

    const handleRunningFancy = async (type) => {
        setType(type)
        setLoader(true)
        await actions.getRunningFancyAction(marketid, type)
        setLoader(false)
    }

    const handleButtonClick = (title, selectionId, marketId, runValue, description, operationName) => {
        setModalData({
            title,
            selectionId,
            marketId,
            description,
            operationName,
            runValue
        })
    }

    const handleRunValue = (value, index) => {
        setData((prevData) => {
            const newData = [...prevData]; // Create a new array to avoid directly modifying the existing state
            newData[index] = { ...newData[index], runValue: value }; // Create a new object for the modified item
            return newData;
        });
    };


    const handleConfirm = async () => {
        if (modalData?.operationName === "transfer") {
            await actions.transferFancyCoinsAction(modalData?.marketId, modalData?.selectionId, modalData?.runValue)
        }
        if (modalData?.operationName === "delete") {
            await actions.terminateFancyAction(modalData?.marketId, modalData?.selectionId)
        }
        if (modalData?.operationName === "rollback") {
            await actions.rollBackFancyResultAction(modalData?.marketId, modalData?.selectionId)
        }
        actions.getRunningFancyAction(marketid, type)
    }

    const RunningFancyTable = () => {
        return (
            <div className="card shadow mb-4">
                <ConfirmationModal data={modalData} handleConfirm={handleConfirm} />
                <div className="">
                    <div className="table-responsive">
                        <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                            <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                                <thead>
                                    <tr role="row">
                                        <th>Sr No.</th>
                                        <th>Session ID</th>
                                        <th>Session Name</th>
                                        <th>Run</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && Array.isArray(data) && data.map((fancy, index) => (
                                        <tr key={index} className={index % 2 === 0 ? "even hover-row" : "odd hover-row"} >
                                            <td>{index + 1}</td>
                                            <td>{fancy?.selectionId}</td>
                                            <td>{fancy?.sessionName}</td>
                                            <td>{!type ? <input type="text" className='form-control' value={fancy?.runValue} placeholder="Enter run" style={{ height: '28px' }} onChange={(e) => handleRunValue(e.target.value, index)} /> : fancy?.runValue}</td>
                                            <td>
                                                {!type ?
                                                    (isRoleExits('fancy_result') && <button className='custom-yellow-btn' data-toggle="modal" data-target="#confirmModal" onClick={() => handleButtonClick(fancy?.sessionName, fancy?.selectionId, fancy?.marketId, fancy?.runValue, `Declare ${fancy?.runValue} as a Result`, 'transfer')}>Coin Transfer</button>) :
                                                    (isRoleExits('fancy_rollback') && <button className='custom-yellow-btn' data-toggle="modal" data-target="#confirmModal" onClick={() => handleButtonClick(fancy?.sessionName, fancy?.selectionId, fancy?.marketId, fancy?.runValue, 'Do you want to Reverse fancy Coins', 'rollback')}>Rollback</button>)
                                                }
                                                <button className='custom-yellow-btn' data-toggle="modal" data-target="#confirmModal" onClick={() => handleButtonClick(fancy?.sessionName, fancy?.selectionId, fancy?.marketId, fancy?.runValue, 'Terminate Fancy', 'delete')}>Delete Bets</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <NavbarSidebarWrapper>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tab2" onClick={() => handleRunningFancy(false)}>
                        Set Result
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab3" onClick={() => handleRunningFancy(true)}>
                        Posted Result
                    </a>
                </li>
            </ul>
            {loader ? <ScreenLoader /> :
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="tab2">
                        {RunningFancyTable()}
                    </div>
                    <div className="tab-pane fade" id="tab3">
                        {RunningFancyTable()}
                    </div>
                </div>}
        </NavbarSidebarWrapper>
    );
}

export default FancyResult;



// {
//     "id": 38,
//     "sessionName": "1st wkt lost to IRE balls(ENG vs IRE)adv",
//     "selectionId": "1stwktlosttoireballsengvsireadv",
//     "marketId": "1.218425494",
//     "runValue": "44",
//     "IsCoinTransfer": false
// },
// {
//     "id": 37,
//     "sessionName": "10 over runs ENG(ENG vs IRE)adv",
//     "selectionId": "10overrunsengengvsireadv",
//     "marketId": "1.218425494",
//     "runValue": "",
//     "IsCoinTransfer": false
// }



