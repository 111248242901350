import React, { useState } from 'react'

const ChangePasswordModal = ({ handleNewPassword }) => {
    const [data, setData] = useState({
        newPassword: ''
    });

    const handleChangePassword = () => {
        handleNewPassword('cp', data?.newPassword)
        setData({
            newPassword: ''
        })
    }
    return (
        <div>
            <div
                className="modal fade"
                id="changePasswordModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Password Reset
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className=" col-sm-12 mb-3 mb-sm-0">
                                    <input
                                        type="password"
                                        className='form-control'
                                        id="NewPassword'"
                                        name='newPassword'
                                        placeholder="new password"
                                        value={data.newPassword}
                                        onChange={(e) => setData({ ...data, newPassword: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                onClick={handleChangePassword}
                            >
                                Reset
                            </button>
                            <button
                                className="modal-cancel-btn"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ChangePasswordModal
