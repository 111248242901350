import React, { useState, useEffect } from "react";
import actions from "../../redux/actions";
import { notifyError, notifySuccess } from "../ToastContext";
import { BiFontSize } from "react-icons/bi";

const ChangePassword = ({ showModal, handleModalClose }) => {
  const [newPassword, setNewPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setOpenModal(showModal);
  }, [showModal]);

  const changePassword = async (e) => {
    e.preventDefault();
    let data = {
      newPwd: newPassword,
      oldPwd: cnfPassword,
    };
    if (newPassword?.trim() === "") {
      alert("enter new password");
      return;
    }
    if (cnfPassword?.trim() === "") {
      alert("enter old password");
      return;
    }
    let res = await actions?.changePwd(data);

    if (res?.data?.Status === false) {
      setErrorMessage(res?.data?.Result);
      return;
    }
    notifySuccess("Password changed successfully");
    setCnfPassword("");
    setNewPassword("");
    setErrorMessage("");
    handleModalClose();
  };
  return (
    <div>
      {openModal && (
        <div
          className="modal"
          id="changePassword"
          tabIndex={-1}
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="exampleModalLabel">
                  Change Password
                </h6>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-user"
                      name="cnfPassword"
                      placeholder="Enter old password"
                      value={cnfPassword}
                      onChange={(e) => setCnfPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-user"
                      name="newPassword"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <span style={{ color: "red", fontSize: "12 px" }}>
                {errorMessage}
              </span>
              <div className="modal-footer">
                <button
                  className="modal-submit-btn"
                  type="button"
                  data-dismiss="modal"
                  onClick={changePassword}
                >
                  change password
                </button>
                <button
                  className="modal-cancel-btn"
                  type="button"
                  data-dismiss="modal"
                >
                  cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {openModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default ChangePassword;
