import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const TableItemComponent = ({ data }) => {
    const marketAnalysisData = useSelector((state) => state.match?.MarketAnalysis?.Result);

    const navigate = useNavigate();

    const handleRowClick = (match) => {
        // console.log("Row clicked:", match);
        navigate(`/my-book-event/${match?.MarketId}/${match?.EventId}/${match?.SportsId}`, { state: { matchName: match?.MarketName } });
    };

    return (
        <div>
            <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                <thead>
                    <tr role="row">
                        <th className="text-center" style={{ width: "80.2px" }}>
                            EventId
                        </th>
                        <th className="text-center" style={{ width: "280.2px" }}>
                            Match Name
                        </th>
                        <th className="text-center" style={{ width: "40.2px" }}>
                            Profit Loss
                        </th>
                        <th className="text-center" style={{ width: "98.2px" }}>
                            Bet Count
                        </th>
                        <th className="text-center" style={{ width: "98.2px" }}>
                            MarketTime
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(marketAnalysisData) && marketAnalysisData?.map((match, index) => (
                        <tr key={index} className={index % 2 === 0 ? "even" : "odd"} style={{ cursor: "pointer" }} onClick={() => handleRowClick(match)}>
                            <td>{match.EventId}</td>
                            <td>{match.MarketName}</td>
                            <td className="text-center">{match.profitLoss}</td>
                            <td className="text-center">
                                {match.betcount}
                                {/* <div className="text-danger">{match.score1}</div> */}
                            </td>
                            <td className="text-center">
                                {match.MarketTime}
                                {/* <div className="text-danger">{match.score2}</div> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableItemComponent
