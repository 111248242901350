import React, { useEffect, useState } from 'react';
import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import moment from 'moment';
import actions from '../../redux/actions';
import ScreenLoader from '../../components/ScreenLoader';
import EditNews from '../../components/Models/EditNews';
import { useSelector } from 'react-redux';
import { isRoleExits } from '../../utils';

const News = () => {
  const newsList = useSelector((state) => state.news.newsList?.Result)
  const [loader, setLoader] = useState(false);
  const [newsErr, setNewsErr] = useState("");
  const [data, setData] = useState({
    id: 0,
    deleted: false,
    status: true,
    createdOn: new Date(),
    updateOn: new Date(),
    Message: "",
    Role: "Admin",
  })
  const [newsEditData, setNewsEditData] = useState({})
  const [sucessMess, setSucessMes] = useState("");

  const handleGetNews = async () => {
    await actions.getNewsListAction()
    setLoader(false)
  }
  useEffect(() => {
    setLoader(true)
    handleGetNews()
  }, [])

  const saveNews = async () => {
    if (data?.Message?.trim() === "") {
      setNewsErr("please enter news headline");
      return;
    }
    setNewsErr("");
    setData({ ...data, Message: "", Role: "Admin" })
    setSucessMes("News saved sucessfully !!");
    await actions.saveNewsAction(data);
    handleGetNews()
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value })
  }

  const activateNews = async (obj) => {
    await actions.saveNewsAction({ ...obj, status: !obj?.status });
    handleGetNews()
  }

  const deleteNews = async (obj) => {
    await actions.saveNewsAction({ ...obj, deleted: true })
    handleGetNews()
  }

  return (
    <NavbarSidebarWrapper>
      {loader ? <ScreenLoader /> :
        <div className="card shadow p-2">
          <EditNews newsData={newsEditData} />
          <div className='content-header mb-4'>News</div>
          <div className="">
            {isRoleExits('add_news') && <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">News</label>
                  <input
                    type="text"
                    className="form-control"
                    name="Message"
                    value={data?.Message}
                    onChange={handleChange}
                  />
                  <p className='red-text' style={{ fontSize: "12px" }}>{newsErr}</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">Role</label>
                  <select id="userRole" className="form-control" name="Role" value={data?.Role} onChange={handleChange}>
                    <option value="Admin">Admin</option>
                    <option value="SubAdmin">SubAdmin</option>
                    <option value="Master">Master</option>
                    <option value="Agent">Agent</option>
                    <option value="Client">Client</option>
                    <option value="Manager">Manager</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">&nbsp;</label>
                  <div className="form-actions no-margin-bottom load-btn-container">
                    <button style={{ marginLeft: 15 }} onClick={saveNews}>
                      Save
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-3 col-sm-6"></div>

            </div>
            }
            <p className='green-text' style={{ fontSize: "14px" }}>{sucessMess}</p>
            <div className="table-responsive">
              <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th className="col-sno">S.No.</th>
                      <th className="col-date">News</th>
                      <th className="col-desc">Create Date</th>
                      <th className="col-desc">Update Date</th>
                      <th className="col-credit">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      newsList && Array.isArray(newsList) && newsList?.map((data, index) => (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <th className="text-no-wrap">{data?.Message}</th>
                          <th>{moment(data?.createdOn).format('DD-MM-YYYY h:mm A')}</th>
                          <th>{moment(data?.updateOn).format('DD-MM-YYYY h:mm A')}</th>
                          <th>
                            <button className='custom-grey-btn' data-toggle="modal" data-target="#newsModal" onClick={() => setNewsEditData(data)}>Edit</button>
                            <button className='custom-grey-btn' onClick={() => activateNews(data)}>{data?.status ? "Activate" : "Deactivate"}</button>
                            {isRoleExits('delete_news') && <button className='custom-grey-btn' onClick={() => deleteNews(data)}>Delete</button>}
                          </th>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }
    </NavbarSidebarWrapper>
  )
}

export default News;
