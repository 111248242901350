import React, { useState } from 'react';
import actions from '../../redux/actions';
import { notifySuccess } from '../ToastContext';

const AddFancyModal = ({ MarketId, EventId }) => {

    const [data, setData] = useState({
        "MarketId": "",
        "EventId": "",
        "RunnerName": "",
        "SessionID": "",
        "BackPrice1": "",
        "BackSize1": "",
        "LayPrice1": "",
        "LaySize1": "",
        "Max": 0,
        "Min": 0,
        "IsActive": true,
        "IsCustome": true,
        "GameStatus": ""
    })

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value })
    }

    const saveFancy = async () => {
        await actions.saveCustomFancyAction({ ...data, MarketId, EventId })
        notifySuccess("Fancy added sucessfully");
        setData({
            "MarketId": "",
            "EventId": "",
            "RunnerName": "",
            "SessionID": "",
            "BackPrice1": "",
            "BackSize1": "",
            "LayPrice1": "",
            "LaySize1": "",
            "Max": 0,
            "Min": 0,
            "IsActive": true,
            "IsCustome": true,
            "GameStatus": ""
        })
    }

    return (
        <div>
            <div
                className="modal fade"
                id="addFancyModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Add Fancy
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col pr-0">
                                        <label htmlFor="textbox1">Fancy Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="RunnerName"
                                            value={data?.RunnerName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 col-md-2 mt-4">
                                        <b>NO</b>
                                    </div>
                                    <div className="col pr-0">
                                        <label htmlFor="textbox1">RUN</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="BackPrice1"
                                            value={data?.BackPrice1}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col pr-0 pl-1">
                                        <label htmlFor="textbox1">SIZE</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="BackSize1"
                                            value={data?.BackSize1}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col pr-0 pl-1">
                                        <label htmlFor="textbox1">MAX</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="Max"
                                            value={data?.Max}
                                            onChange={(e) => setData({ ...data, Max: parseInt(e.target.value) })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3 col-md-2 mt-4 ">
                                        <b>YES</b>
                                    </div>
                                    <div className="col pr-0">
                                        <label htmlFor="textbox1"></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="LayPrice1"
                                            value={data?.LayPrice1}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col pr-0 pl-1">
                                        <label htmlFor="textbox1"></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="LaySize1"
                                            value={data?.LaySize1}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col pr-0 pl-1">
                                        <label htmlFor="textbox1">MIN</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="Min"
                                            value={data?.Min}
                                            onChange={(e) => setData({ ...data, Min: parseInt(e.target.value) })}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                onClick={saveFancy}
                            >
                                Save
                            </button>
                            <button className="modal-cancel-btn" data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddFancyModal;
