import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleStyle } from "../../redux/slices/styleSlice";
import * as FcIcons from "react-icons/fc";
import {
  isRoleExits,
  isSuperAdmin,
  showForAllExceptManager,
} from "../../utils";

const Sidebar = () => {
  const style = useSelector((state) => state?.style?.style);
  const dispatch = useDispatch();

  const changeStyle = () => {
    dispatch(toggleStyle());
  };
  const getHostname = () => {
    try {
      var host = window.location.hostname;
      const dots = host.split(".");
      return dots.at(-2).replace(/\d/g, "");
    } catch (ex) {}
  };
  return (
    <div>
      <ul className={style} id="accordionSidebar">
        {/* Sidebar - Brand */}
        <div
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
        >
          <div className="sidebar-brand-text mx-3">
            <a href="/home">
              <img src={`/img/${getHostname()}.png`} className="w-100" />
            </a>
          </div>
        </div>
        {/* Divider */}
        <hr className="sidebar-divider my-0" />

        <li className="nav-item">
          <NavLink className="nav-link" activeclassname="active" to="/home">
            <FcIcons.FcBarChart className="mr-2" />
            <span>Dashboard</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink
            className="nav-link"
            to={`/clients/0/10/${encodeURIComponent(
              JSON.parse(sessionStorage.getItem("loginUserDetails"))?.uid
            )}/${JSON.parse(sessionStorage.getItem("loginUserDetails"))?.Role}`}
          >
            <FcIcons.FcManager className="mr-2" />
            <span>Clients List</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/view-match">
            <FcIcons.FcViewDetails className="mr-2" />
            <span>View Match</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/game-control">
            <FcIcons.FcRules className="mr-2" />
            <span>Game Control</span>
          </NavLink>
        </li>
        <li className="nav-item">
            <NavLink className="nav-link" to="/casinobets">
              <FcIcons.FcRules className="mr-2" />
              <span>Live Casino</span>
            </NavLink>
          </li>
        <li className="nav-item">
          <div
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#reports"
            aria-expanded="true"
            aria-controls="reports"
          >
            <FcIcons.FcFile className="mr-2" />
            <span>Reports</span>
          </div>
          <div
            id="reports"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <NavLink to="/account-statement" className="collapse-item">
                Account Statement
              </NavLink>

              <NavLink to="/marketpl" className="collapse-item">
                Market P/L
              </NavLink>

              <NavLink to="/user-report/null" className="collapse-item">
                Profil Loss User Wise
              </NavLink>
            </div>
          </div>
        </li>

        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={changeStyle}
          />
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
