import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { notifyError } from "../../components/ToastContext";
import ChangePassword from "../../components/Models/ChangePassword";

const SignIn = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    Username: "",
    Password: "",
    LatLong: "string",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    /* if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              alert("Please Allow Location to Access this Application");
            }
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
      // Fetch IP Address
      // fetch('https://ipinfo.io/json')
      //   .then(response => response.json())
      //   .then(data => setIp(data.ip))
      //   .catch(error => console.error('Error fetching IP:', error));
      */
  }, []);
  /*
    useEffect(() => {
      if (latitude !== null && longitude !== null) {
        fetchCoordinates(latitude, longitude);
      }
    }, [latitude, longitude]);
  */
  function fetchCoordinates(latitude, longitude) {
    const locationURL = `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}`;
    fetch(locationURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        //console.log(data)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const login = async (e) => {
    e.preventDefault();
    if (data.Password.trim() !== "" && data.Username.trim() !== "") {
      setIsLoading(true);
      try {
        const res = await actions.loginAction(data);
        if (res?.status === 200) {
          if (!res?.data?.Status) {
            notifyError(res?.data?.Result);
          } else {
            sessionStorage.setItem(
              "loginUserDetails",
              JSON.stringify(res?.data)
            );
            if (res?.data?.Role === "Manager") {
              let roleByUser = await actions.getRoleByUser(res?.data?.uid);
              let roleData = roleByUser?.data?.Result;
              let roleKeyArray = Array.isArray(roleData)
                ? roleData.map((item) => item.Role_key)
                : [];
              sessionStorage.setItem(
                "ManagerRole",
                JSON.stringify(roleKeyArray)
              );
            } else {
              sessionStorage.removeItem("ManagerRole");
            }
            window.location = "/home";
          }
        } else {
          notifyError(res?.data?.Result);
        }
      } catch (error) {
        //console.log('Error during login:', error);
        console.log("res", error);
        notifyError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="bg-gradient-primary" style={{ minHeight: "100vh" }}>
      <div className="container">
        {/* Outer Row */}
        <ChangePassword
          handleModalClose={handleCloseModal}
          showModal={showModal}
        />
        <div className="row justify-content-center h-100">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div
              className="card o-hidden border-0 shadow-lg my-5"
              style={{ height: "400px" }}
            >
              <div className="card-body p-0 h-100">
                {/* Nested Row within Card Body */}
                <div className="row h-100">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form className="user">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            name="Username"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Username..."
                            value={data?.Username}
                            onChange={(e) =>
                              setData({ ...data, Username: e.target.value })
                            }
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="exampleInputPassword"
                            placeholder="Password"
                            name="Password"
                            value={data?.Password}
                            onChange={(e) =>
                              setData({ ...data, Password: e.target.value })
                            }
                          />
                        </div>
                        {/* <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div> */}
                        <button
                          type="submit"
                          className="btn btn-primary  w-100"
                          onClick={login}
                          disabled={isLoading}
                        >
                          {isLoading ? "Loading..." : "Submit"}
                        </button>
                        <span
                          style={{
                            color: "blue",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                          data-toggle="modal"
                          data-target="#changePassword"
                          onClick={handleShowModal}
                        >
                          change password ?
                        </span>
                        {/* <hr /> */}
                        {/* <a
                          href="index.html"
                          className="btn btn-google btn-user btn-block"
                        >
                          <i className="fab fa-google fa-fw" /> Login with Google
                        </a>
                        <a
                          href="index.html"
                          className="btn btn-facebook btn-user btn-block"
                        >
                          <i className="fab fa-facebook-f fa-fw" /> Login with
                          Facebook
                        </a> */}
                      </form>
                      {/* <hr /> */}
                      <div className="text-center">
                        {/* <a className="small" href="forgot-password.html">
                          Forgot Password?
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
