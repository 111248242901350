import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../redux/actions';

const BookModal = () => {

    const [currentPosition, setCurrentPosition] = useState([]);
    const [currentPosition1, setCurrentPosition1] = useState([]);

    const userBookList = useSelector((state) => state.bookEvent.useBookData);
    const teamName = useSelector((state) => state.bookEvent.userBookName);

    const parts = teamName.split(" v ");

    const getPL = (userName) => {
        const existArray = currentPosition1.filter((element) => element.userName === userName);
        return existArray;
    }

    const handleUsernameClick = (userPlData) => {
        let data = {
            marketId: userPlData?.MarketId,
            uid: encodeURIComponent(userPlData?.uid),
        }
        actions.getUserBook(data);
    }

    useEffect(() => {
        const userBookListResult = userBookList?.Result?.filter((element) => element.filterOrder === 1)
        const userBookListResult1 = userBookList?.Result?.filter((element) => element.filterOrder === 0)
        setCurrentPosition(userBookListResult)
        setCurrentPosition1(userBookListResult1)
    }, [userBookList]);

    return (
        <div>
            <div className="modal fade" id="bookModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Position Of {teamName}
                            </h6>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info">
                            <thead>
                                <tr role="row">
                                    <th>User Name</th>
                                    {parts.map((part, index) => (
                                        <th key={index}>{part}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {currentPosition?.map((plData, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className="username" style={{ cursor: 'pointer' }} onClick={() => handleUsernameClick(plData)}>{plData.userName}</span>
                                        </td>
                                        {getPL(plData.userName).map((mkt, j) => (
                                            <td key={j} style={{color:mkt.pL < 0 ? 'red' : 'green'}}>
                                                {mkt.pL.toFixed(2)}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookModal
