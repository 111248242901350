import React, { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "../ToastContext";
const MarketPleaceBet = ({ MarketId, EventId, sportsId }) => {
  let userlist = useSelector((state) => state.auth.userDataList?.Result);
  let marketSelectionData = useSelector( (state) => state.bookEvent.matchSelectionData);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [userInput, setUserInput] = useState(""); // Initialize the input value
  const [formErrors, setFormErrors] = useState({});
  const [isFormFilled, setIsFormFilled] = useState(true);

  const [data, setData] = useState({
    UserId: 0,
    sportsId: sportsId,
    MarketId: MarketId,
    EventId: EventId,
    SelectionID: "",
    SelectionName: "",
    Odds: 0,
    Price: 0,
    Stack: 0,
    Exposure: 0,
    ReturnBalance: 0,
    BetType: "Back",
    BetStatus: "Pending",
    MarketType: "",
  });

  useEffect(() => {
    if (
      userlist !== undefined &&
      userlist !== "No Data" &&
      userInput?.trim() !== ""
    ) {
      setUserSuggestions(userlist);
    } else {
      setUserSuggestions([]);
    }
  }, [userlist]);

  useEffect(() => {
    const firstRunner = marketSelectionData.runner[0];
    setData({
      ...data,
      SelectionID: firstRunner?.id?.toString(),
      SelectionName: firstRunner?.RunnerName,
      MarketType: marketSelectionData?.marketType,
      sportsId: sportsId,
      MarketId: MarketId,
      EventId: EventId,
    });
  }, [MarketId, EventId, sportsId, marketSelectionData]);

  const handleChange = (e) => {
    let { value, name } = e.target;
    let newData = { ...data, [name]: value };
    setData(newData);
    setFormErrors({ ...formErrors, [name]: null });
    if (validateForm(newData)) {
      setIsFormFilled(false);
    }
  };

  const saveMarketBet = async () => {
    if (validateForm(data)) {
      //console.log("dataaaaa", data);
      let res = await actions.postMarketBetAdmin(data);
      setUserInput("");
      setUserSuggestions([]);
      setData({
        UserId: 0,
        sportsId: sportsId,
        MarketId: MarketId,
        EventId: EventId,
        SelectionID: "",
        SelectionName: "",
        Odds: 0,
        Price: 0,
        Stack: 0,
        Exposure: 0,
        ReturnBalance: 0,
        BetType: "Back",
        BetStatus: "Pending",
        MarketType: "",
      });
      if (res?.data?.Status) {
        notifySuccess(res?.data?.Result);
      } else {
        notifyError(res?.data?.Result);
      }
    }
  };

  const handleUserInputChange = (e) => {
    const { value } = e.target;
    setUserInput(value);
  };

  const handleselectionInputChange = (e) => {
    const { value } = e.target;
    const selectedOption = marketSelectionData?.runner?.find(
      (item) => item.id.toString() === value.toString()
    );

    const id = selectedOption.id.toString();
    if (selectedOption) {
      setData({
        ...data,
        SelectionID: id,
        SelectionName: selectedOption.RunnerName,
      });
    } else {
      setData({
        ...data,
        SelectionID: "",
        RunnerName: "",
      });
    }
  };

  useEffect(() => {
    if (userInput?.trim() !== "") {
      actions.getUserList(userInput);
    }
  }, [userInput]);

  const handleUserSelection = (user) => {
    setUserSuggestions([]);
    let newData = { ...data, UserId: user.id };
    setData(newData);
    setUserInput(user.username);
    setFormErrors({ ...formErrors, UserId: null });
    if (validateForm(newData)) {
      setIsFormFilled(false);
    }
  };

  const validateForm = (newData) => {
    const errors = {};
    if (newData.UserId === 0) {
      errors.UserId = "User is required.";
    }
    if (newData.Odds <= 0) {
      errors.Odds = "RUN must be greater than 0.";
    }
    if (newData.Stack <= 0) {
      errors.Stack = "Stack must be greater than 0.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <div>
      <div
        className="modal fade"
        id="marketPleaceBet"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                Market Pleace Bet
              </h6>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="px-2">
                <div className="row">
                  <div className="col ">
                    <label htmlFor="textbox1">Selection</label>
                    <select
                      className="form-control"
                      name="SelectionID"
                      value={data.SelectionID}
                      onChange={handleselectionInputChange}
                    >
                      {marketSelectionData?.runner?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.RunnerName}
                        </option>
                      ))}
                      {/* <option value="LAY">LAY</option> */}
                    </select>
                  </div>
                  <div className="col ">
                    <label htmlFor="textbox1">User</label>
                    <input
                      type="text"
                      className="form-control"
                      name="UserId"
                      value={userInput}
                      onChange={handleUserInputChange}
                    />
                    {formErrors.UserId && (
                      <span className="error-message">{formErrors.UserId}</span>
                    )}
                    {userSuggestions.length > 0 && (
                      <ul className="suggestion-list">
                        {userSuggestions.map((user, index) => (
                          <li
                            key={index}
                            onClick={() => handleUserSelection(user)}
                          >
                            {user?.username}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor="textbox1">Bet Type</label>
                    <select
                      className="form-control"
                      name="BetType"
                      value={data.BetType}
                      onChange={handleChange}
                    >
                      <option value="Back">BACK</option>
                      <option value="Lay">LAY</option>
                    </select>
                  </div>

                  <div className="col ">
                    <label htmlFor="textbox1">RUN</label>
                    <input
                      type="number"
                      className="form-control"
                      name="Odds"
                      value={data?.Odds}
                      onChange={handleChange}
                    />
                    {formErrors.Odds && (
                      <span className="error-message">{formErrors.Odds}</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col  pl-1">
                    <label htmlFor="textbox1">Stack</label>
                    <input
                      type="number"
                      className="form-control"
                      name="Stack"
                      value={data?.Stack}
                      onChange={handleChange}
                    />
                    {formErrors.Stack && (
                      <span className="error-message">{formErrors.Stack}</span>
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="modal-cancel-btn" data-dismiss="modal">
                Close
              </button>
              <button
                className="modal-submit-btn"
                type="button"
                data-dismiss="modal"
                disabled={isFormFilled}
                onClick={saveMarketBet}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPleaceBet;
