import React from 'react';
import InPlay from '../../screen/InPlay';
import Cricket from '../../screen/Cricket';
import Soccer from '../../screen/Soccer';
import Tennis from '../../screen/Tennis';
import MyMarkets from '../../screen/MyMarkets';

const TabsComponent = () => {
    return (
        <div className="mt-4">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tab1">
                        In Play
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab2">
                        Cricket
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab3">
                        Soccer
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab4">
                        Tennis
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab5">
                        My Markets
                    </a>
                </li>
            </ul>
            <div className="tab-content mt-3">
                <div className="tab-pane fade show active" id="tab1">
                    <InPlay />
                </div>
                <div className="tab-pane fade" id="tab2">
                    <Cricket />
                </div>
                <div className="tab-pane fade" id="tab3">
                    <Soccer />
                </div>
                <div className="tab-pane fade" id="tab4">
                    <Tennis />
                </div>
                <div className="tab-pane fade" id="tab5">
                    <MyMarkets />
                </div>
            </div>
        </div>
    );
};

export default TabsComponent;
