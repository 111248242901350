import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profitLossByUser: [],
    profitLossByUserSpots: [],
    accountStatement: [],
    marketBetsOfUser: [],
    marketSharingDetails: [],
    overAllPandL: [],
    saveUserLog: [],
    settlementSummary: [],
    chipSummary: [],
    profitLossEventData: [],
    sharingDetials: [],
    saveEventname: [],
    savedeleteBets: [],
}

const reportSlice = createSlice({
    name: 'profitLossByUser',
    initialState: initialState,
    reducers: {
        savedeleteBets: (state, action) => {
            state.savedeleteBets = action.payload
        },
        saveEventname: (state, action) => {
            state.saveEventname = action.payload
        },
        savePLByUser: (state, action) => {
            state.profitLossByUserSpots = action.payload
        },
        savePLByUserSpots: (state, action) => {
            state.profitLossByUser = action.payload
        },
        saveAccountStatement: (state, action) => {
            state.accountStatement = action.payload
        },
        saveOverAllPandL: (state, action) => {
            state.overAllPandL = action.payload
        },
        saveMarketSharingDetails: (state, action) => {
            state.marketSharingDetails = action.payload
        },
        saveUserLog: (state, action) => {
            state.saveUserLog = action.payload
        },
        saveSettlementSummary: (state, action) => {
            state.settlementSummary = action.payload
        },
        saveChipSummary: (state, action) => {
            state.chipSummary = action.payload
        },
        saveProfitLossEvent: (state, action) => {
            state.profitLossEventData = action.payload
        },
        saveProfitLossSports: (state, action) => {
            state.profitLossSportsData = action.payload
        },
        setSharingDetials: (state, action) => {
            state.sharingDetials = action.payload
        },
        resetReportData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const {resetReportData, savePLByUser, saveEventname, savedeleteBets, saveAccountStatement, savePLByUserSpots, saveChipSummary, saveProfitLossEvent, saveProfitLossSports, saveSettlementSummary, saveMarketSharingDetails, saveMarketBetsOfUser, saveUserLog, saveOverAllPandL, setSharingDetials } = reportSlice.actions;
export default reportSlice.reducer
