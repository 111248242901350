import { saveUserData, userList, saveBalance } from "../slices/authSlice";
import AuthService from "../../services/authService";
import store from "../store";

export const loginAction = (data) => {
    return new Promise((resolve, rejact) => {
        AuthService.loginService(data).then((res) => {
            store.dispatch(saveUserData(res.data))
            resolve(res)
        }).catch((error) => {
            rejact(error)
        })
    })
}

export function getUserSearchList(userName) {
    return new Promise((resolve, rejact) => {
        AuthService.getUserSearchListService(userName).then((res) => {
            store.dispatch(userList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}
export function getUserList(userName) {
    return new Promise((resolve, rejact) => {
        AuthService.getUserListService(userName).then((res) => {
            store.dispatch(userList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function postSignUp(data) {
    return new Promise((resolve, rejact) => {
        AuthService.postSignUpService(data).then((res) => {
            // store.dispatch(userList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}
export function updateSignUp(data) {
    return new Promise((resolve, rejact) => {
        AuthService.updateSignUpService(data).then((res) => {
            // store.dispatch(userList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
        })
    })
}

export function getBalanceDetails() {
    return new Promise((resolve, rejact) => {
        AuthService.getBalanceDetailsService().then((res) => {
            store.dispatch(saveBalance(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getBalanceDetails", error)
        })
    })
}

export function changePwd(data) {
    return new Promise((resolve, rejact) => {
        AuthService.changePwdService(data).then((res) => {
            // store.dispatch(saveBalance(res.data))
            //console.log("changePwdService",res);
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getBalanceDetails", error)
        })
    })
}