import React, { useState, useEffect } from 'react';
import actions from '../../redux/actions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import moment from 'moment';
import * as RiIcons from 'react-icons/ri';
import ScreenLoader from '../../components/ScreenLoader';

const FancysSetting = () => {
    const fancylistdata = useSelector((state) => state.fancy.fancyList?.Result);
    let { marketid } = useParams();
    const [editedValues, setEditedValues] = useState({}); 
    const [loader, setLoader] = useState(false);

    const modifiedFancyListData = fancylistdata?.map(item => ({
        ...item,
        Min: item.min,
        Max: item.max,
        min: undefined, 
        max: undefined, 
    }));

    useEffect(() => {
        if (marketid) {
            actions.GetFancyList(marketid);
        }
    }, [marketid]);

    const changeval = (e, data) => {
        const { value, name } = e.target;
        const editedItem = { ...editedValues[data.SelectionId], [name]: value };
        setEditedValues({ ...editedValues, [data.SelectionId]: editedItem });
    }

    const updateFancyData = async (data) => {
        const isActive = !data.isActive; 
        const editedItem = editedValues[data.SelectionId];
        const updatedData = {
            ...data,
            isActive: isActive,
        };
        if (editedItem) {
            updatedData.Min = editedItem.Min !== undefined ? editedItem.Min : updatedData.Min;
            updatedData.Max = editedItem.Max !== undefined ? editedItem.Max : updatedData.Max;
            updatedData.delay = editedItem.delay !== undefined ? editedItem.delay : updatedData.delay;
        }
        

        await actions.PostUpadteFancyList(updatedData);
        actions.GetFancyList(marketid);

        setEditedValues({
            ...editedValues,
            [data.SelectionId]: undefined,
        });
    }

    return (
        <NavbarSidebarWrapper>
            <div className='content-header'>Fancy List</div>
            <div className="form-container">
                {loader ? <ScreenLoader /> : <></>}
                <div>
                    <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                        <thead>
                            <tr role="row">
                                <th>SessionID</th>
                                <th>RunnerName</th>
                                <th>Delay</th>
                                <th>Min</th>
                                <th>Max</th>
                                <th>isActive</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modifiedFancyListData && Array.isArray(modifiedFancyListData) && modifiedFancyListData?.map((match, index) => (
                                <tr key={index} className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}>
                                    <td>{match?.SelectionId}</td>
                                    <td>{match?.RunnerName}</td>
                                    <td><input style={{ "width": "40px" }} onChange={(e) => changeval(e, match)} name="delay" type="number" value={editedValues[match.SelectionId]?.delay || match.delay}/></td>
                                    <td><input style={{ "width": "50px" }} onChange={(e) => changeval(e, match)} name="Min" type="number" value={editedValues[match.SelectionId]?.Min || match.Min}/></td>
                                    <td><input style={{ "width": "80px" }} onChange={(e) => changeval(e, match)} name="Max" type="number" value={editedValues[match.SelectionId]?.Max || match.Max}/></td>
                                    <td><button className={match.isActive ? "custom-green-btn" : "custom-red-btn"} onClick={() => updateFancyData(match)}>{match.isActive ? "Active" : "Deactive"}</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </NavbarSidebarWrapper>
    )
};

export default FancysSetting;