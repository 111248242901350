import React from "react";
import moment from 'moment';
const BetTableList = ({ data }) => {

    return (
        <div className="table-responsive">
            <div className="table-wrapper">
                <div className="table">
                    <React.Fragment >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>UserName</th>
                                    <th>Runner</th>
                                    <th>Odds</th>
                                    <th>Stack</th>
                                    <th>Bet Type</th>
                                    <th>Market Type</th>
                                    <th>IP</th>
                                    <th>createdOn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((bet, index) => (
                                    <tr key={index} className={bet.BetType === "No" || bet.BetType === "Lay" ? "lay" : "back"}>
                                        <td>{index + 1}</td>
                                        <td>{bet?.UserName}</td>
                                        <td>{bet?.SelectionName}</td>
                                        <td>{bet?.MarketType === 'FANCY' ? bet?.Odds + '(' + bet?.Price + ')' : bet?.Odds}</td>
                                        <td>{bet?.Stack}</td>
                                        <td>{bet?.BetType}</td>
                                        <td>{bet?.MarketType}</td>
                                        <td>{bet?.IP_Address}</td>
                                        
                                        <td>{moment(bet?.createdOn).format('DD-MM-YYYY h:mm A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </React.Fragment>

                </div>
            </div>
        </div>
    )
}

export default BetTableList;