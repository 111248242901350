import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import { useLocation, useParams } from "react-router-dom";

const ScoreBoard = () => {
    let { eventId } = useParams();
    let location = useLocation();
    const scoreDetail = useSelector((state) => state.bookEvent.scoreData);
    let { matchName, seriesName } = location.state;

    useEffect(() => {
        const fetchData = () => {
            actions.GetScoreData(eventId);
        };
        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [eventId])

    return (
        <div className="container-fluid score-bg">
            <div className="tag-container white-tag-container py-1 d-flex  align-items-center">
                <span className="tag-text text-white">{scoreDetail?.Innings ? matchName + ' - ' + seriesName : '---'}</span>
            </div>
            {Array.isArray(scoreDetail?.Innings) && scoreDetail?.Innings &&
                <>
                    {scoreDetail?.Innings?.length >= 1 &&
                        <div className="d-flex flex-wrap score-text mt-2">
                            <div className="col-12 col-md-7 text-start"><span>{scoreDetail?.Innings[0]?.Team}</span> <span style={{ marginLeft: "20px" }}>{scoreDetail?.Innings[0]?.Summary}({scoreDetail?.Innings[0]?.Overs}... CRR {scoreDetail?.Innings[0]?.CRR})</span></div>
                            <div className="col-12 col-md-5 text-end text-right mb-1 desktop-balls-over" >
                                {scoreDetail?.CurrentOver?.Balls?.map((overs, index) => (
                                    <span className={`currentOver balls-${overs}`} key={index}>{overs}</span>
                                ))}
                            </div>
                        </div>
                    }
                    {scoreDetail?.Innings?.length > 1 &&
                        <div className="d-flex flex-wrap score-text">
                            <div className="col-8 text-start"><span >{scoreDetail?.Innings[1]?.Team}</span> <span style={{ marginLeft: "20px" }} >{scoreDetail?.Innings[1]?.Summary}({scoreDetail?.Innings[1]?.Overs}... CRR {scoreDetail?.Innings[1]?.CRR})</span></div>
                            <div className="col-4 text-end text-right" >
                                {scoreDetail?.RequiredRunRate && scoreDetail?.RequiredRunRate.trim() !== "" && <span>RRR {scoreDetail?.RequiredRunRate}</span>}
                            </div>
                        </div>
                    }
                    {scoreDetail?.Innings?.length === 0 &&
                        <div className="d-flex flex-wrap score-text mt-2">
                            <div className="col-12 text-start"><span >{matchName?.split('v')[0]}</span> </div>
                        </div>
                    }
                    {(scoreDetail?.Innings?.length === 1 || scoreDetail?.Innings?.length === 0) &&
                        <div className="d-flex flex-wrap score-text ">
                            <div className="col-12 text-start"><span >{matchName?.split('v')[1]}</span> </div>
                        </div>
                    }

                    <div className="d-flex flex-wrap mt-2">
                        <div className="col-12 col-md-6 text-start match-commentary">{scoreDetail?.MatchCommentary} </div>
                        <div className="col-12 col-md-6 text-end text-right desktop-balls-over ">
                            {scoreDetail?.LastOver?.Balls?.length > 0 && <span className="score-text">Last Over: </span>}
                            {scoreDetail?.LastOver?.Balls?.length > 0 && scoreDetail?.LastOver?.Balls?.map((overs, index) => (
                                <span className="lastball-runs " key={index}>{overs}</span>
                            ))}
                        </div>
                    </div>

                    <div className="d-flex flex-wrap mt-2 mobile-balls-over">
                        <div className="col-8 text-start text-left over-balls">
                            {scoreDetail?.LastOver?.Balls?.length > 0 && scoreDetail?.LastOver?.Balls?.map((overs, index) => (
                                <span className="lastball-runs " key={index}>{overs}</span>
                            ))}
                        </div>
                        <div className="col-4 text-end text-right mb-1 over-balls" >
                            {scoreDetail?.CurrentOver?.Balls?.length > 0 && scoreDetail?.CurrentOver?.Balls?.map((overs, index) => (
                                <span className="lastball-runs" key={index}>{overs}</span>
                            ))}
                        </div>
                    </div>
                </>
            }
            {/* // <div className=" grey-background">
                //     <div className="row score-text mt-2">
                //         <div className="col text-start">{matchName?.MarketName?.split('v')[0]}</div>
                //     </div>
                //     <div className="row score-text mt-2">
                //         <div className="col text-start">{matchName?.MarketName?.split('v')[1]}</div>
                //     </div>
                // </div> */}

        </div>
    )
}

export default ScoreBoard;