import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import moment from "moment";

const BlockEvents = () => {

  const data = useSelector((state) => state.bookEvent.blockEventData);


  if (!data || !data.Result || !Array.isArray(data.Result)) {
    return <div className="d-flex justify-content-center">No data available.</div>;
  }

  return (
    <div>
      <div className="card shadow">
        <div className="card-body">
          <div className="table-responsive">
            {data.Result.map((match, matchIndex) => (
              <div key={matchIndex}>
                <div className="tag-container white-tag-container align-items-center mb-1 p-1">
                  <span className="tag-text text-white">
                    {match?.EventId} {match?.MarketName} {moment(data?.createdOn).format('DD-MM-YYYY h:mm:ss A')}
                  </span>
                  {/* <i className="ml-1 fas fa-edit ml-auto m-1 p-1" style={{ cursor: 'pointer', color: 'black' }}></i> */}
                  {/* <input
                    className=" form-check-input"
                    type="checkbox"
                    checked={match?.status}
                    style={{ width: '15px', height: '15px' }}
                  // onChange={handleCheckboxChange}
                  /> */}
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered" width="100%" cellSpacing={0}>
                    {/* <thead>
                            <tr>
                              <th>Market Name</th>
                            </tr>
                          </thead> */}
                    <tbody>
                      {match.Markets.map((market, marketIndex) => (
                        <tr key={marketIndex}>
                          <td>
                            {market?.MarketId + "-" + market?.MarketName}
                            {/* <input
                              className="ml-2 form-check-input"
                              type="checkbox"
                              checked={market?.status}
                              style={{ width: '15px', height: '15px', marginTop: '1px' }}
                            // onChange={handleCheckboxChange}
                            /> */}
                            {/* <i
                                className="ml-1 fas fa-edit ml-auto m-1 p-1"
                                style={{ cursor: 'pointer', color: 'black' }}
                              // onClick={handleEditClick}
                              ></i> */}
                            {/* <i className="ml-1 fas fa-edit ml-auto m-1 p-1" style={{ cursor: 'pointer', color: 'black' }}></i> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div >
  );
};

export default BlockEvents;
