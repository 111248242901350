import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
    name: 'reportSliceData',
    initialState: {
        accountStatementDetails: [],
        accountBetHistoryDetails: []
    },
    reducers: {
        saveAccountStatement: (state, action) => {
            state.accountStatementDetails = action.payload
        },
        saveAccountBetHistory: (state, action) => {
            state.accountBetHistoryDetails = action.payload
        }
    }
})

export const { saveAccountStatement, saveAccountBetHistory } = reportSlice.actions;
export default reportSlice.reducer