import React from 'react'
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import LogoutModel from '../../components/Models/LogoutModel';
import TabsComponent from '../../components/MarketManagement/TabsComponent';


const MarketManagement = () => {

  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div id="wrapper">
          {/* Sidebar */}
          <Sidebar />
          {/* Content Wrapper */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              {/* Topbar */}
              <Navbar navTitle={"All Markets"} />
              {/* Begin Page Content */}
              <div className="container-fluid">
                {/* Page Heading */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                </div>
                {/* Tabs Nav */}
                <TabsComponent />
              </div>
            </div>
            {/* Footer */}
            <Footer />
          </div>
        </div>
        {/* Logout Modal*/}
        <LogoutModel />
      </>

    </div>
  )
};

export default MarketManagement;
