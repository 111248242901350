import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetMarketData } from '../redux/slices/bookEventSlice';
import { resetReportData } from '../redux/slices/reportSlice';
import { resetMatchData } from '../redux/slices/matchSlice';
import { resetClientData } from '../redux/slices/clientSlice';
import { resetFancyData } from '../redux/slices/fancySlice';

const ResetMarketDataWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Dispatch the resetMarketData action when the location changes
    dispatch(resetMarketData());
    dispatch(resetReportData());
    dispatch(resetMatchData());
    dispatch(resetClientData());
    dispatch(resetFancyData());
  }, [dispatch, location.pathname]); // Trigger the effect whenever the location pathname changes

  // Render the children components
  return <>{children}</>;
};

export default ResetMarketDataWrapper;
