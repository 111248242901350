import axios from 'axios';
import {SAVENEWS, GETNEWSLIST,GETNEWSAPI} from './url';


class NewsServiceClass {
    async getNewsListService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.get(`${GETNEWSLIST}`, {
            headers: headers,
        });
    }
    async saveNewsService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };
        return await axios.post(`${SAVENEWS}`, data,{
            headers: headers,
        });
    }
    async GetNewsService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${GETNEWSAPI}`, {
            headers: headers,
        });
    }
}


const NewsService = new NewsServiceClass();
export default NewsService;
