import React, { useEffect } from 'react'
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import LogoutModel from '../../components/Models/LogoutModel';
import TabsComponent from '../../components/GameControlTab/TabsComponent';
import actions from '../../redux/actions';


const GameControl = () => {

  useEffect(() => {
    actions.GetBlockSportList();
  }, []);


  return (
    <div>
      <>
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Navbar navTitle={"All Markets"} />
              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                </div>
                <TabsComponent />
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <LogoutModel />
      </>

    </div>
  )
};

export default GameControl;
