import React, { useEffect, useState } from 'react'
import AccountStatementTable from './AccountStatementTable';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import moment from 'moment';
import actions from '../../../redux/actions';
import ScreenLoader from '../../../components/ScreenLoader';
import { useSelector } from 'react-redux';

const AccountStatement = () => {
  const accountStatementData = useSelector((state) => state.report?.accountStatement?.Result)
  let totalrow = useSelector((state) => state.report?.accountStatement?.Count);
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState('All');

  const handleAccountStatement = async () => {
    setLoader(true)
    let data = {
      skipRec: 0,
      id: 1,
      type: 'All',
      startDate: fromDate,
      endDate: toDate,
      creditType: 'All',
      sportsId: 0
    }
    await actions.accountStatementAction(data)
    setLoader(false)
  }

  useEffect(() => {
    handleAccountStatement()
  }, [])

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    let data = {
      skipRec: 0,
      id: 1,
      type: type,
      startDate: fromDate,
      endDate: toDate,
      creditType: 'All',
      sportsId: 0
    }
    await actions.accountStatementAction(data)
    setLoader(false)
  };

  return (
    <NavbarSidebarWrapper>
      {loader ? <ScreenLoader /> :
        <div className="card shadow mb-4 p-2">
          <div className="">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">From:</label>
                  <input
                    type="date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">To:</label>
                  <input
                    type="date"
                    value={toDate}
                    onChange={handleToDateChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">Type:</label>
                  <select class="form-control" value={type} onChange={handleTypeChange}>
                    <option value="All">All</option>
                    <option value="FreeChips">Deposit/Withdraw Report</option>
                    <option value="Settlement">Settlement</option>
                    <option value="ProfitLoss">Game Report</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">&nbsp;</label>
                  <div className="form-actions no-margin-bottom load-btn-container">
                    <button onClick={handleSubmit} style={{ marginLeft: 15 }}>
                      Load
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-3 col-sm-6"></div>
            </div>
            <div className="table-responsive">
              <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <AccountStatementTable data={accountStatementData} fromDate={fromDate} toDate={toDate} totalrow={totalrow} type={type} />
              </div>
            </div>
          </div>
        </div>
      }
    </NavbarSidebarWrapper>
  )
}

export default AccountStatement;
