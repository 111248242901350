import React from 'react';
import { useSelector } from 'react-redux';

const FancyCurrentPostionModal = () => {

    const fancyBetList = useSelector((state) => state.bookEvent.setCurrentFPData?.Result);
    let selectionData = useSelector((state) => state.bookEvent.selectionData);

    return (
        <div>
            <div className="modal fade" id="currentFancyModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                {selectionData?.RunnerName}
                            </h6>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                            <thead>
                                <tr role="row">
                                    <th>Runs</th>
                                    <th>Position</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fancyBetList?.map((item, index) => (
                                    <tr key={index} className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}>
                                        <td class="font-weight-bold" >{item?.RunValue}</td>
                                        <td class="font-weight-bold" style={{ color: item.Pl < 0 ? 'red' : 'green' }}>{item?.Pl}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FancyCurrentPostionModal
