import React from 'react'
import ClinentFindListTable from './ClinentFindListTable';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';

const ClinentFindList = () => {
  return (
    <NavbarSidebarWrapper>
      <ClinentFindListTable />
    </NavbarSidebarWrapper>
  )
}

export default ClinentFindList;
