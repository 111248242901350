import React from "react";
import TableItemComponent from '../../components/home/TableItemComponent';

const data = [
  {
    matchName: "England vs Aus",
    bets: 0,
    team1: "England",
    score1: 0,
    team2: "Aus",
    score2: 0,
    score3: 0
  }
];

const InPlay = () => {
  return (
    <div className="card shadow mb-4">
      <div className="card-body">
        <div className="table-responsive">
          <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
            <div className="row">
              <div className="col-sm-12">
                <TableItemComponent data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InPlay;
