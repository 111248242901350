import TableItemComponent from './TableItemComponent';


const TableWrapper = ({ data, marketIdsList, showAction, handleAction }) => {

    return (
        <div className="card shadow mb-4">
            <div className="">
                <div className="table-responsive">
                    <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                        <div className="">
                            <div className="">
                                <TableItemComponent marketList={data} marketIdsList={marketIdsList} showAction={showAction} handleAction={handleAction} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableWrapper;
