import React, { useState, useEffect } from 'react';

function RoleDropdown({ loggedInUserRole, onRoleChange , readOnlyRole}) {
  const roles = {
    SuperAdmin: ['Admin', 'SubAdmin', 'Master', 'Agent', 'Client','Manager'],
    Admin: ['SubAdmin', 'Master', 'Agent', 'Client'],
    SubAdmin: ['Master', 'Agent', 'Client'],
    Master: ['Agent', 'Client'],
    Agent: ['Client'],
  };

  const [selectedRole, setSelectedRole] = useState('');
  const availableRoles = roles[loggedInUserRole] || [];

  useEffect(() => {
    // Set the selected role to the first option in the availableRoles
    if (availableRoles.length > 0) {
      setSelectedRole(availableRoles[0]);
      onRoleChange(availableRoles[0]);
    } else {
      setSelectedRole(''); // If no roles left, set selectedRole to an empty string
    }
  }, [loggedInUserRole]);

  const handleRoleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRole(selectedValue);

    // Call the callback function with the selected value
    onRoleChange(selectedValue);
  };

  return (

    <>
      <label>User Role</label>
      <select id="userRole" onChange={handleRoleChange} value={selectedRole} className="form-control" readOnly={readOnlyRole}>
        {availableRoles.map((role) => (
          <option key={role} value={role}>
            {role}
          </option>
        ))}
      </select>
      <span>Selected Role: {selectedRole}</span>
    </>
  );
}

export default RoleDropdown;