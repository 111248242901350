import React from "react";
import AllMarketTableItem from "../../components/MarketManagement/AllMarketTableItem";
import { useNavigate } from "react-router-dom";

const data = [
  {
    matchName: "Tennis / L Samsonova v K Siniakova / WTA Montreal 2023",
    bets: 0,
    team1: "England",
    score1: 0,
    team2: "Aus",
    score2: 0,
    score3: 0
  }
];

const AllMatchsTable = () => {

  const navigate = useNavigate();


  const handleMyBookAction = () => {
    // Navigate(`/my-book-event/${sportId}/${eventID}`);
    navigate('/my-book-event/4/4');
}

  return (
    <div>
      {/* strart */}
      <div className="row col-sm-12 mb-3">
        <div className="form-check custom-radio">
          <input
            className="form-check-input"
            type="radio"
            name="status"
            id="Running"
          />
          <label className="form-check-label" htmlFor="Running">
            Running
          </label>
        </div>
        <div className="form-check mx-4 custom-radio">
          <input
            className="form-check-input"
            type="radio"
            name="status"
            id="Suspended"
          />
          <label className="form-check-label" htmlFor="Suspended">
            Suspended
          </label>
        </div>

        <div className="form-check custom-radio">
          <input
            className="form-check-input form-check-lg"
            type="radio"
            name="status"
            id="Completed"
          />
          <label className="form-check-label" htmlFor="Completed">
            Completed
          </label>
        </div>
      </div>

      {/* end */}
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
              <div className="row">
                <div className="col-sm-12">
                  <AllMarketTableItem data={data} handleMyBookAction={handleMyBookAction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default AllMatchsTable;
