import { createSlice } from "@reduxjs/toolkit";

const initialState= {
    clientDataList: [],
    clientNewDataList: [],
    clientFindDataList: [],
    amount: {},
    userRolesList: [],
    userRoles: []
}

const clientSlice = createSlice({
    name: 'clientData',
    initialState: initialState,
    reducers: {
        clientList: (state, action) => {
            state.clientDataList = action.payload
        },
        clientNewList: (state, action) => {
            state.clientNewDataList = action.payload
        },
        clientFindList: (state, action) => {
            state.clientFindDataList = action.payload
        },
     
        saveAmount: (state, action) => {
            state.amount = action.payload
        },
        saveUserSetting: (state, action) => {
            state.userSettingList = action.payload
        },
        saveUserRolesList: (state, action) => {
            state.userRolesList = action.payload
        },
        saveRoleByUser: (state, action) => {
            state.userRoles = action.payload
        },
        resetClientData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const {resetClientData, clientList,clientNewList,clientFindList, saveAmount, saveUserSetting, saveUserRolesList, saveRoleByUser } = clientSlice.actions;
export default clientSlice.reducer
