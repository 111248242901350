import React, { useState, useEffect } from "react";
import Sidebar from '../../../components/sidebar';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import LogoutModel from '../../../components/Models/LogoutModel';
import actions from '../../../redux/actions';
import { useSelector } from "react-redux";
import moment from 'moment';
import { useNavigate, useParams } from "react-router-dom";
//import PLBetListModal from "../../../components/Models/PLBetListModal";

const EventPLTable = () => {
    let profitLossEventData = useSelector((state) => state.report.profitLossEventData?.Result);
    const [Total, setTotal] = useState(0);
    let { uid, eventid, type } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (uid && eventid) {
            actions.getProfitLossEventAction({ uid: encodeURIComponent(uid), eventid, type });
        }
    }, [uid, eventid, type])

    useEffect(() => {
        if (profitLossEventData && Array.isArray(profitLossEventData)) {
            const totals = {};
            for (const obj of profitLossEventData) {
                for (const key in obj) {
                    if (totals.hasOwnProperty(key)) {
                        totals[key] += obj[key];
                    } else {
                        totals[key] = obj[key];
                    }
                }
            }
            setTotal(totals);
        }

    }, [profitLossEventData])

    const handlePlBetList = (plMarket) => {
        console.log("uiduid", uid);
        const data = {
            fancyId: plMarket?.FancyId,
            marketType: plMarket?.MType,
            marketId: plMarket?.MarketId,
            uid:encodeURIComponent(uid)
        }
        console.log("data",data);
        // marketId=marketid&uid=uid&MarketType=markettype&fancyid=fancyid
        navigate(`/market-pl-bet`);
        actions.getMarketBetPLList(data);
        actions.GetSharingDetails(data);

    }

    return (
        <div>
            {/* Page Wrapper */}
            <div id="wrapper">
                {/* Sidebar */}
                <Sidebar />
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* Main Content */}
                    <div id="content">
                        {/* Topbar */}
                        <Navbar navTitle={"Profit Loss"} />
                        {/* Begin Page Content */}
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div
                                        id="dataTable_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4">
                                        <div className="">
                                            <div className="">
                                                <table className="table table-bordered dataTable" >
                                                    <thead>
                                                        <tr className="bg-gray-200">
                                                            <th className="col-date">EVENT</th>
                                                            <th className="col-date">P&L</th>
                                                            <th className="col-debit">COMMISION</th>
                                                            <th className="col-debit">TOTAL P&L</th>
                                                            <th className="col-debit">COMM DENA</th>
                                                            <th className="col-credit">COMM LENA</th>
                                                            <th className="col-credit">DOWN LINE</th>
                                                            <th className="col-balance">UP LINE</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {
                                                            profitLossEventData && Array.isArray(profitLossEventData) && profitLossEventData?.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ color: '#4e73df', cursor: 'pointer' }} className="text-no-wrap" onClick={() => handlePlBetList(data)}><span>{data?.Sport}{" > "}{data?.Match}  ({data?.MType})</span> </td>

                                                                    <td data-value={data?.pf} >{data?.pf}</td>
                                                                    <td data-value={data?.comm}>{data?.comm}</td>
                                                                    <td data-value={data?.totalpl}>{data?.totalpl}</td>
                                                                    <td data-value={data?.Comm_Dena}>{data?.Comm_Dena}</td>
                                                                    <td data-value={data?.Comm_Lena}>{data?.Comm_Lena}</td>
                                                                    <td data-value={data?.DPL}>{data?.DPL}</td>
                                                                    <td data-value={data?.UPL}>{data?.UPL}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr className="bg-gray-400">
                                                            <td>
                                                                <b className="text-no-wrap">TOTAL</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.Pf}>{Total?.pf}</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.comm}>{Math.round(Total?.comm)}</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.totalpl}>{Math.round(Total?.totalpl)}</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.Comm_Dena}>{Math.round(Total?.Comm_Dena)}</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.Comm_Lena}>{Math.round(Total?.Comm_Lena)}</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.DPL}>{Math.round(Total?.DPL)}</b>
                                                            </td>
                                                            <td>
                                                                <b data-value={Total?.UPL}>{Math.round(Total?.UPL)}</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* Footer */}
                    <Footer />
                </div>
            </div>
            {/* Logout Modal*/}
            <LogoutModel />
            {/* <PLBetListModal />*/}
        </div>
    );
};

export default EventPLTable;
