import React, { useEffect, useState } from 'react';
import style from './editbm.css';
import { GetCustomeMarket, postCustomeMarket } from '../../redux/actions/bookEventActions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { notifyError } from '../../components/ToastContext';
const BMEditForm = () => {

    const { marketid } = useParams();
    const [isCheckedManual, setIsCheckedManual] = useState(false);
    const [isCheckedSameBhav, setIsCheckedSameBhav] = useState(false);
    const [txtRateDiff, setTxtRateDiff] = useState("0");

    const [formData, setFormData] = useState({
        MarketId: marketid,
        RunnerName: '',
        selectionId: '',
        BackPrice1: '0',
        BackSize1: '0',
        LayPrice1: '0',
        LaySize1: '0',
        Max: 10000,
        Min: 0,
        IsActive: false,
        IsCustome: false,
        status: "ACTIVE",
    });

    const [formData2, setFormData2] = useState({
        MarketId: marketid,
        RunnerName: '',
        selectionId: '',
        BackPrice1: '0',
        BackSize1: '0',
        LayPrice1: '0',
        LaySize1: '0',
        Max: 10000,
        Min: 0,
        IsActive: false,
        IsCustome: false,
        status: "SUSPENDED",
    });

    const [previousFormData, setPreviousFormData] = useState(formData);
    const [previousFormData2, setPreviousFormData2] = useState(formData2);

    const customeMarketData = useSelector((state) => state.bookEvent.customeMarketData?.Result);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (isCheckedSameBhav) {
            handleInputChangeCommon(name, event);
        } else {
            // Listen for Enter key press
            if (event.key === 'Enter') {
                // Update radio input value
                const newStatus = formData.status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    status: newStatus,
                }));

                if (newStatus === 'ACTIVE') {
                    setFormData2((prevFormData) => ({
                        ...prevFormData,
                        status: 'SUSPENDED',
                    }));
                }
            }

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                LayPrice1: (parseFloat(value) + parseFloat(txtRateDiff)).toString(),
            }));
            // postCustomeMarket(formData);
        }
    };


    const handleInputChange2 = (event) => {
        const { name, value } = event.target;

        if (isCheckedSameBhav) {
            handleInputChangeCommon(name, event);
        } else {
            // Listen for Enter key press
            if (event.key === 'Enter') {
                // Update radio input value
                const newStatus = formData2.status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';

                setFormData2((prevFormData) => ({
                    ...prevFormData,
                    status: newStatus,
                }));

                if (newStatus === 'ACTIVE') {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: 'SUSPENDED',
                    }));
                }
            }

            setFormData2((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                LayPrice1: (parseFloat(value) + parseFloat(txtRateDiff)).toString(),
            }));

            // postCustomeMarket(formData2);
        }
    };
    const handleInputCC = (event) => {
        const { name, value } = event.target;

        if (isCheckedSameBhav) {
            handleInputChangeCommon(name, event);
        } else {
            // Listen for Enter key press
            if (event.key === 'Enter') {
                // Update radio input value
                const newStatus = formData2.status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';

                setFormData2((prevFormData) => ({
                    ...prevFormData,
                    status: newStatus,
                }));

                if (newStatus === 'ACTIVE') {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: 'SUSPENDED',
                    }));
                }
            }

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));

            // postCustomeMarket(formData);
        }
    };


    const handleCheckSB = () => {
        setIsCheckedSameBhav(!isCheckedSameBhav);
        setFormData2((prevFormData) => {
            const { RunnerName, selectionId, ...restFormData } = formData;
            return {
                ...prevFormData,
                ...restFormData,
                LayPrice1: '-',
                BackPrice1: !isCheckedSameBhav ? formData.BackPrice1 : '0',
            };
        });
        setFormData((prevFormData) => ({
            ...prevFormData,
            LayPrice1: '-',
        }));
    };


    const handleCheckManual = () => {
        setIsCheckedManual(!isCheckedManual);
        setFormData2((prevFormData) => ({
            ...prevFormData,
            IsCustome: !isCheckedManual,
        }));
        setFormData((prevFormData) => ({
            ...prevFormData,
            IsCustome: !isCheckedManual,
        }));

        // postCustomeMarket({ ...formData, IsCustome: !isCheckedManual, });
        // postCustomeMarket({ ...formData2, IsCustome: !isCheckedManual, });

    };

    const handleInputChangeCommon = (name, event) => {
        if (event.key === 'Enter') {
            // Update radio input value
            const newStatus = formData2.status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';

            setFormData((prevFormData) => ({
                ...prevFormData,
                status: newStatus,
            }));

            setFormData2((prevFormData) => ({
                ...prevFormData,
                status: newStatus,
            }));

        }

        setFormData((prevFormData) => {
            let updatedFormData = {
                ...prevFormData,
                [name]: event.target.value,
            };

            // Check if the name is "Min" or "Max"
            if (name === "Min" || name === "Max") {
                // If the name is "Min" or "Max", update LayPrice1 based on the condition
                updatedFormData.LayPrice1 = (parseFloat(prevFormData.LayPrice1) + parseFloat(txtRateDiff)).toString();
            } else {
                // For other names, update LayPrice1 normally
                // updatedFormData.LayPrice1 = (parseFloat(event.target.value) + parseFloat(txtRateDiff)).toString();
            }

            return updatedFormData;
        });
        setFormData2((prevFormData) => {
            let updatedFormData = {
                ...prevFormData,
                [name]: event.target.value,
            };

            // Check if the name is "Min" or "Max"
            if (name === "Min" || name === "Max") {
                // If the name is "Min" or "Max", update LayPrice1 based on the condition
                updatedFormData.LayPrice1 = (parseFloat(prevFormData.LayPrice1) + parseFloat(txtRateDiff)).toString();
            } else {
                // For other names, update LayPrice1 normally
                // updatedFormData.LayPrice1 = (parseFloat(event.target.value) + parseFloat(txtRateDiff)).toString();
            }

            return updatedFormData;
        });

        // postCustomeMarket(formData)
        // postCustomeMarket(formData2)
    };

    useEffect(() => {
        if (!customeMarketData) {
            actions.GetCustomeMarket(marketid);
        }
        if (customeMarketData && customeMarketData.length > 0) {
            setIsCheckedManual(customeMarketData[0].IsCustome)
            setFormData(customeMarketData[0]);
            setFormData2(customeMarketData[1]);

        }
    }, [marketid, customeMarketData]);

    
    useEffect(() => {
        const updateFormData = async (formData, previousFormData) => {
            const isFormDataChanged =
                JSON.stringify(formData) !== JSON.stringify(previousFormData);
            if (isFormDataChanged) {
                try {
                    await actions.postCustomeMarket(formData);
                } catch (error) {
                    notifyError(error);
                }
            }
            setPreviousFormData(formData);
        };
        updateFormData(formData, previousFormData);
    }, [formData, previousFormData]);

    useEffect(() => {
        const updateFormData2 = async (formData2, previousFormData2) => {
            const isFormDataChanged =
                JSON.stringify(formData2) !== JSON.stringify(previousFormData2);
            if (isFormDataChanged) {
                try {
                    await actions.postCustomeMarket(formData2);
                } catch (error) {
                    notifyError(error);
                }
            }
            setPreviousFormData2(formData2);
        };
        updateFormData2(formData2, previousFormData2);
    }, [formData2, previousFormData2]);


    return (
        <div className="container-fluid">
            {/* Page Heading */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            </div>

            {/* Form Content */}
            <div className='card shadow mb-4 col-lg-12'>
                <div className='card-body'>
                    <form className="fancy">
                        <div className="row">
                            {/* <div className="col-lg-12 col-sm-12"> */}
                            <div className="col-lg-12 col-sm-12 py-1 d-flex align-items-center">
                                <input id="checkcustome" checked={isCheckedManual} onChange={handleCheckManual} type="checkbox" name="checkcustome" />
                                <label htmlFor="checkcustome" className='px-1'>Is Manual</label>
                            </div>

                            <div className="col-lg-12 col-sm-12">
                                <div className="matero-col p-r-0">
                                    <table className="table nestedTable ">
                                        <thead>
                                            <tr className="white-tag-container">
                                                <th width="40%"><span className="tbl-title text-white">To Win the Toss</span></th>
                                                <th width="10%" className="text-center text-white">Back</th>
                                                <th width="10%" className="text-center text-white">Lay</th>
                                                <th width="10%" className="text-center text-white" rowSpan={2}>MIN/Max</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <!-- Row 1 --> */}
                                            <tr className="">
                                                <td width="40%">
                                                    <p className="m-y-4">
                                                        <a><span id="lblr1">{formData.RunnerName}</span></a>
                                                    </p>
                                                    <small className="text-grey"></small>
                                                </td>
                                                <td width="10%" className="bg-blue-300 tbl-rate-col text-center">
                                                    {formData.status === "SUSPENDED" ? <div className="bet-locked runner1 "><b className="text-red-900">SUSPENDED</b></div> : ""}
                                                    <p className="m-y-4 r1back">{formData.BackPrice1}</p>
                                                    <small className="text-grey">-</small>
                                                </td>
                                                <td width="10%" className="bg-pink-50 tbl-rate-col text-center">
                                                    <p className="m-y-4 r1lay">{formData.LayPrice1}</p>
                                                    <small className="text-grey">-</small>
                                                </td>
                                                <td width="10%" className="text-center ">
                                                    <p className="m-t-0 m-b-4 text-grey">{formData.Min}</p>
                                                    <p className="m-t-0 m-b-4 text-grey">{formData.Max}</p>
                                                </td>
                                            </tr>

                                            {/* <!-- Row 2 --> */}
                                            <tr className="">
                                                <td width="40%">
                                                    <p className="m-y-4">
                                                        <a><span id="lblr2">{formData2.RunnerName}</span></a>
                                                    </p>
                                                    <small className="text-grey"></small>
                                                </td>
                                                <td width="10%" className="bg-blue-300 tbl-rate-col text-center">
                                                    {formData2.status === "SUSPENDED" ? <div className="bet-locked runner1 "><b className="text-red-900">SUSPENDED</b></div> : ""}
                                                    <p className="m-y-4 r2back">{formData2.BackPrice1}</p>
                                                    <small className="text-grey">-</small>
                                                </td>
                                                <td width="10%" className="bg-pink-50 tbl-rate-col text-center">
                                                    <p className="m-y-4 r2lay">{formData2.LayPrice1}</p>
                                                    <small className="text-grey">-</small>
                                                </td>
                                                <td width="10%" className="text-center ">
                                                    <p className="m-t-0 m-b-4 text-grey">{formData.Min}</p>
                                                    <p className="m-t-0 m-b-4 text-grey">{formData.Max}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-12">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">Rate Difference </label>
                                        <input name="txtRateDiff" type="number" value={txtRateDiff} onChange={(event) => setTxtRateDiff(event.target.value)} id="txtRateDiff" className="form-control" />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" id="chkbhav"
                                                checked={isCheckedSameBhav}
                                                onChange={handleCheckSB} />
                                            <label className="form-check-label" htmlFor="exampleCheck1">
                                                Same Bhav
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-sm-12">
                                <div className="matero-col p-r-0">
                                    <table className="table nestedTable mat-elevation-z8">
                                        <thead>
                                            <tr className="white-tag-container">
                                                <th width="40%"><span className="tbl-title text-white">To Win the Toss</span></th>
                                                <th width="10%" className="text-center text-white">Back</th>
                                                <th width="10%" className="text-center text-white">Lay</th>
                                                <th width="20%" className="text-center text-white">Action</th>
                                                <th width="10%" className="text-center text-white">MIN/Max</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <!-- Row 1 --> */}
                                            <tr className="">
                                                <td width="40%">
                                                    <p className="m-y-4"><a><span id="lblcr1">{formData.RunnerName}</span></a></p>
                                                    <small className="text-grey"></small>
                                                </td>
                                                <td width="10%" className="bg-blue-300 tbl-rate-col text-center">
                                                    <p className="m-y-4"><input name="BackPrice1" type="number" value={formData.BackPrice1} id="txtr1back" className="form-control myenter1" onChange={handleInputChange} onKeyDown={handleInputChange} /></p>
                                                </td>
                                                <td width="10%" className="bg-pink-50 tbl-rate-col text-center">
                                                    <p className="m-y-4"><input name="LayPrice1" type="number" value={formData.LayPrice1} id="txtr1lay" className="form-control myenter1" /></p>
                                                </td>
                                                <td width="20%" className="text-center ">
                                                    <div className="btn-group">
                                                        <input type="radio" className="btn-check" name="R1gstatus" id="R1option1" value="ACTIVE" checked={formData.status === 'ACTIVE'} />
                                                        <label className="btn" htmlFor="R1option1">Live</label>

                                                        <input type="radio" className="btn-check" name="R1gstatus" id="R1option2" value="SUSPENDED" checked={formData.status === 'SUSPENDED'} />
                                                        <label className="btn" htmlFor="R1option2">SUSPENDED</label>
                                                    </div>
                                                </td>
                                                <td width="10%" className="text-center ">
                                                    <input name="Min" type="number" value={formData.Min} id="txtmin" className="form-control" onChange={handleInputCC} />
                                                </td>
                                            </tr>

                                            {/* <!-- Row 2 --> */}
                                            <tr className="">
                                                <td width="40%">
                                                    <p className="m-y-4">
                                                        <a><span id="lblCr2">{formData2.RunnerName}</span></a>
                                                    </p>
                                                    <small className="text-grey"></small>
                                                </td>
                                                <td width="10%" className="bg-blue-300 tbl-rate-col text-center">
                                                    <p className="m-y-4">
                                                        <input name="BackPrice1" type="number" value={formData2.BackPrice1} className="form-control myenter2" onChange={handleInputChange2} onKeyDown={handleInputChange2} />
                                                    </p>
                                                </td>

                                                <td width="10%" className="bg-pink-50 tbl-rate-col text-center">
                                                    <p className="m-y-4">
                                                        <input name="LayPrice1" type="number" value={formData2.LayPrice1} id="txtr2lay" className="form-control myenter2" />
                                                    </p>
                                                </td>
                                                <td width="20%" className="text-center ">
                                                    <div className="btn-group">
                                                        <input type="radio" className="btn-check" name="R2gstatus" id="R2option1" value="ACTIVE" checked={formData2.status === 'ACTIVE'} />
                                                        <label className="btn" htmlFor="R2option1">Live</label>
                                                        <input type="radio" className="btn-check" name="R2gstatus" id="R2option3" value="SUSPENDED" checked={formData2.status === 'SUSPENDED'} />
                                                        <label className="btn" htmlFor="R2option3">SUSPENDED</label>
                                                    </div>
                                                </td>
                                                <td width="10%" className="text-center ">
                                                    <input name="Max" type="number" value={formData.Max} id="txtmax" className="form-control" onChange={handleInputCC} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BMEditForm;
