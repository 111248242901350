import React from 'react';
import AllMatchsTable from '../../screen/MarketManagement/AllMatchsTable';
import { useNavigate } from 'react-router-dom';

const TabsComponent = () => {

    const navigate = useNavigate();

    const handleMyBookAction = () => {
        // Navigate(`/my-book-event/${sportId}/${eventID}`);
        navigate('/my-book-event/4/4');
    }

    return (
        <div className="mt-4">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#tab1">
                        Cricket
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab2">
                        Soccer
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab3">
                        Tennis
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab4">
                        T20
                    </a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#tab5">
                        OneDay
                    </a>
                </li>
            </ul>
            <div className="tab-content mt-3">
                <div className="tab-pane fade show active" id="tab1">
                    <AllMatchsTable handleMyBookAction={handleMyBookAction} />
                </div>
                <div className="tab-pane fade" id="tab2">
                    <AllMatchsTable handleMyBookAction={handleMyBookAction} />
                </div>
                <div className="tab-pane fade" id="tab3">
                    <AllMatchsTable handleMyBookAction={handleMyBookAction} />
                </div>
                <div className="tab-pane fade" id="tab4">
                    <AllMatchsTable handleMyBookAction={handleMyBookAction} />
                </div>
                <div className="tab-pane fade" id="tab5">
                    <AllMatchsTable handleMyBookAction={handleMyBookAction} />
                </div>
            </div>
        </div>
    );
};

export default TabsComponent;
