import React from 'react'
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import UserReportTable from './UserReportTable';



const UserReport = () => {
  return (
    <NavbarSidebarWrapper>
      <UserReportTable />
    </NavbarSidebarWrapper>
  )
}

export default UserReport;
