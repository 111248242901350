import { combineReducers } from 'redux';
import styleReducer from '../slices/styleSlice';
import authReducer from '../slices/authSlice';
import matchReducer from "../slices/matchSlice";
import bookEventSlice from '../slices/bookEventSlice';
import reportReducer from '../slices/reportSlice'
import fancyReducer from '../slices/fancySlice'
import clientReducer from '../slices/clientSlice'
import newsSlice from '../slices/newsSlice';
import clientStatementSlice from '../slices/clientStatementSlice';

const rootReducer = combineReducers({
  style: styleReducer,
  auth: authReducer,
  match: matchReducer,
  bookEvent: bookEventSlice,
  report: reportReducer,
  fancy: fancyReducer,
  client: clientReducer,
  news: newsSlice,
  clientStatement: clientStatementSlice
});

export default rootReducer;
