import React, { useEffect, useState } from 'react'
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import actions from '../../../redux/actions';
import ScreenLoader from '../../../components/ScreenLoader';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const ProfitLossStatement = () => {
    const ProfitLossStatementData = useSelector((state) => state.report?.marketSharingDetails?.Result)
    const { userId, marketId } = useParams();
    const [profitLossStatement, setProfitLossStatement] = useState([]);
    const [loader, setLoader] = useState(false);

    const handleProfitLossStatementList = async () => {
        setLoader(true)
        let data = {
            userId: userId,
            marketId: marketId
        }
        await actions.marketSharingDetailsAction(data)
        setLoader(false)
    }

    useEffect(() => {
        handleProfitLossStatementList()
    }, [])

    useEffect(() => {
        setProfitLossStatement(ProfitLossStatementData)
    }, [ProfitLossStatementData])


    return (
        <NavbarSidebarWrapper>
            <div className='content-header'>Profit/Loss Settlement</div>
            {loader ? <ScreenLoader /> :
                <div className="card shadow mb-4">
                    <div className="">
                        <div className="table-responsive">
                            <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                <div>
                                    <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textWrap: 'nowrap' }} className="col-sno">#</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-date">Match Name</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-date">Market Name</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-desc">Selection Name</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-credit">Odds</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Stack</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">User</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Bet Type</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Bet Status</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Result</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Exp/Result</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Ip Address</th>
                                                <th style={{ textWrap: 'nowrap' }} className="col-balance">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                profitLossStatement && Array.isArray(profitLossStatement) && profitLossStatement?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.MatchName}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.MarketType}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.SelectionName}</td>
                                                    
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.MarketType === 'FANCY' ? item?.Odds + '(' + item?.Price + ')' : item?.Odds}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.Stack}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.UserName}</td>
                                                        <td style={{ textWrap: 'nowrap' }}> <span style={{ background: '#72bbef' }} className='pl-1 pr-1'><b>{item?.BetType}</b></span></td>
                                                        <td style={{ textWrap: 'nowrap' }} className="red-text">{item?.BetStatus}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.Role}</td>
                                                        <td style={{ textWrap: 'nowrap' }} className="red-text">{item?.Exposure}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{item?.IP_Address}</td>
                                                        <td style={{ textWrap: 'nowrap' }}>{moment(item?.createdOn).format('DD-MM-YYYY h:mm:ss A')}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div >
                            </div>
                        </div>
                    </div>
                </div>
            }
        </NavbarSidebarWrapper>
    )
}

export default ProfitLossStatement;