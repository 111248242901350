import React, { useEffect, useState } from "react";
import RoleDropdown from "../RoleDropdown";
import actions from "../../redux/actions";
import { notifySuccess, notifyError } from "../ToastContext";

const CreateClientModal = ({
  mid,
  role,
  handleClientList,
  showModal,
  handleModalClose,
}) => {
  const roles = {
    SuperAdmin: ["Admin", "SubAdmin", "Master", "Agent", "Client"],
    Admin: ["SubAdmin", "Master", "Agent", "Client"],
    SubAdmin: ["Master", "Agent", "Client"],
    Master: ["Agent", "Client"],
    Agent: ["Client"],
  };
  const [formData, setFormData] = useState({
    Name: "",
    UserName: "",
    Password: "",
    ReferById: 0,
    mid: mid, //uid send
    role: roles[role]?.length > 0 ? roles[role][0] : "",
    ExposureLimit: 0,
    MCO: "2",
    SCO: "0",
    BCO: "0",
    Share: 0,
    DomainColour: "",
    Remark: "",
  });
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      mid: mid,
      role: roles[role]?.length > 0 ? roles[role][0] : "",
    });
  }, [mid, role]);

  useEffect(() => {
    setOpenModal(showModal);
    // console.log(showModal);
  }, [showModal]);

  const [selectedRole, setSelectedRole] = useState("");
  // const loggedInUserRole = JSON.parse(sessionStorage.getItem("loginUserDetails")).Role;
  const loggedInUserRole = role;

  const handleRoleChange = (selectedValue) => {
    setSelectedRole(selectedValue);
    setFormData({ ...formData, role: selectedValue });
  };
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [id]: undefined }));
  };

  const validateForm = () => {
    const validationErrors = {};

    if (!formData.Name?.trim()) {
      validationErrors.Name = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.Name)) {
      validationErrors.Name = "Name is not valid";
    }

    if (!formData.UserName) {
      validationErrors.UserName = "User Name is required";
    }
    if (formData.UserName && !/^[a-zA-Z0-9_-]*$/.test(formData.UserName)) {
      validationErrors.UserName = "User Name is not valid";
    }

    if (!formData.role) {
      validationErrors.role = "User Role is required";
    }

    if (!formData.Password) {
      validationErrors.Password = "Password is required";
    }
    if (!formData.ExposureLimit && selectedRole === "Client") {
      validationErrors.ExposureLimit = "Exposure is required";
    }

    if (
      (formData.Share === undefined || formData.Share < 0) &&
      selectedRole !== "Client"
    ) {
      validationErrors.Share = "Share is required";
    } else if (formData.Share < 0 && selectedRole !== "Client") {
      validationErrors.Share = "Share must be a positive";
    }

    if (formData.BCO < 0) {
      validationErrors.BCO =
        "Book Maker Commission should be a number greater than 0";
    }

    if (!formData.BCO.match(/^(\d+(\.\d{0,2})?|\.\d{1,2})$/)) {
      validationErrors.BCO = "Invalid Book Maker Commission";
    }

    if (formData.MCO < 0) {
      validationErrors.MCO =
        "Match Commission should be a number greater than 0";
    }

    if (!formData.MCO.match(/^(\d+(\.\d{0,2})?|\.\d{1,2})$/)) {
      validationErrors.MCO = "Invalid  Match Commission";
    }

    if (formData.SCO < 0) {
      validationErrors.SCO =
        "Fancy Commission should be a number greater than 0";
    }

    if (!formData.SCO.match(/^(\d+(\.\d{0,2})?|\.\d{1,2})$/)) {
      validationErrors.SCO = "Invalid Fancy Commission";
    }

    if (formData.role < 0) {
      validationErrors.role = "User Role should be a positive number";
    }
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length === 0) {
      try {
        const res = await actions.postSignUp({
          ...formData,
          MCO: parseFloat(formData.MCO),
          BCO: parseFloat(formData.BCO),
          SCO: parseFloat(formData.SCO),
        });
        handleModalClose();
        setFormData({
          Name: "",
          UserName: "",
          Password: "",
          ReferById: 0,
          mid: mid, //uid send
          role: roles[role]?.length > 0 ? roles[role][0] : "",
          ExposureLimit: 0,
          MCO: "2",
          SCO: "0",
          BCO: "0",
          Share: 0,
          DomainColour: "",
          Remark: "",
        });
        if (res?.data?.Status) {
          notifySuccess(res?.data?.Result);
          handleClientList();
        } else {
          notifyError(res?.data?.Result);
        }
      } catch (error) {
        // console.log("Error:", error);
      }
    }
  };
  return (
    <div>
      {openModal && (
        <div
          className="modal"
          id="createClientModal"
          tabIndex={-1}
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="exampleModalLabel">
                  Add Client
                </h6>
                <button
                  className="close"
                  type="button"
                  aria-label="Close"
                  onClick={() => {
                    handleModalClose();
                    setErrors({});
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <div className=" col-sm-6 mb-3 mb-sm-0">
                    <label>Name</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.Name ? "is-invalid" : ""
                      }`}
                      id="Name"
                      name="Name"
                      placeholder="Name"
                      value={formData.Name}
                      onChange={handleChange}
                    />
                    {errors.Name && (
                      <div className="invalid-feedback">{errors.Name}</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label>User Name</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.UserName ? "is-invalid" : ""
                      }`}
                      id="UserName"
                      name="UserName"
                      placeholder="User Name"
                      value={formData.UserName}
                      onChange={handleChange}
                    />
                    {errors.UserName && (
                      <div className="invalid-feedback">{errors.UserName}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <RoleDropdown
                      loggedInUserRole={role}
                      onRoleChange={handleRoleChange}
                      readOnlyRole={false}
                    />
                    {errors.role && (
                      <div className="invalid-feedback">{errors.role}</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label>BookMaker Commission</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.BCO ? "is-invalid" : ""
                      }`}
                      id="BCO"
                      name="BCO"
                      placeholder="BookMaker Commission"
                      value={formData.BCO}
                      onChange={(e) =>
                        setFormData({ ...formData, BCO: e.target.value })
                      }
                    />
                    {errors.BCO && (
                      <div className="invalid-feedback">{errors.BCO}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Password</label>

                    <input
                      type="Password"
                      className={`form-control  ${
                        errors.Password ? "is-invalid" : ""
                      }`}
                      id="Password"
                      name="Password"
                      placeholder="Password"
                      value={formData.Password}
                      onChange={handleChange}
                    />
                    {errors.Password && (
                      <div className="invalid-feedback">{errors.Password}</div>
                    )}
                  </div>

                  <div className="col-sm-6 mb-3 mb-sm-0">
                    {selectedRole !== "Client" ? (
                      <>
                        <label>Share</label>
                        <input
                          type="number"
                          className={`form-control  ${
                            errors.Share ? "is-invalid" : ""
                          }`}
                          id="Share"
                          name="Share"
                          placeholder="Share"
                          value={formData.Share}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              Share: parseInt(e.target.value),
                            })
                          }
                        />

                        {errors.Share && (
                          <div className="invalid-feedback">{errors.Share}</div>
                        )}
                      </>
                    ) : (
                      <>
                        <label>Exposure</label>
                        <input
                          type="number"
                          className={`form-control  ${
                            errors.ExposureLimit ? "is-invalid" : ""
                          }`}
                          id="ExposureLimit"
                          name="ExposureLimit"
                          placeholder="Exposure"
                          value={formData.ExposureLimit}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              ExposureLimit: parseInt(e.target.value),
                            })
                          }
                        />
                        {errors.ExposureLimit && (
                          <div className="invalid-feedback">
                            {errors.ExposureLimit}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="form-group row mb-3">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Commission</label>
                    <input
                      type="text"
                      readOnly
                      className={`form-control  ${
                        errors.MCO ? "is-invalid" : ""
                      }`}
                      id="MCO"
                      placeholder="Match Commission"
                      value={formData.MCO}
                      onChange={(e) =>
                        setFormData({ ...formData, MCO: e.target.value })
                      }
                    />
                    {errors.MCO && (
                      <div className="invalid-feedback">{errors.MCO}</div>
                    )}
                  </div>
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Fancy Commission</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.SCO ? "is-invalid" : ""
                      }`}
                      id="SCO"
                      placeholder="Fancy Commission"
                      value={formData.SCO}
                      onChange={(e) =>
                        setFormData({ ...formData, SCO: e.target.value })
                      }
                    />
                    {errors.SCO && (
                      <div className="invalid-feedback">{errors.SCO}</div>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-3">
                  <div className="col-sm-6 mb-3 mb-sm-0">
                    <label>Remark</label>
                    <input
                      type="text"
                      className={`form-control  ${
                        errors.Remark ? "is-invalid" : ""
                      }`}
                      id="MCO"
                      placeholder="Remark"
                      value={formData.Remark}
                      onChange={(e) =>
                        setFormData({ ...formData, Remark: e.target.value })
                      }
                    />
                    {errors.Remark && (
                      <div className="invalid-feedback">{errors.Remark}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="modal-submit-btn"
                  type="button"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  className="modal-cancel-btn"
                  type="button"
                  onClick={() => {
                    handleModalClose();
                    setErrors({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}{" "}
      {openModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default CreateClientModal;
