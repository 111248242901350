import React, { useState } from 'react';
import moment from 'moment';

const CalendarModel = ({ handleUpdateMarketTime }) => {

    const [marketTime, setMarketTime] = useState("")

    const handleSubmitMarketTime = () => {
        handleUpdateMarketTime(moment(marketTime)?.toISOString())
    }

    return (
        <div>
            <div
                className="modal fade"
                id="calendarModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Change Event Time
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label>Market/Event Time</label>
                            <input type="datetime-local" className='form-control' value={marketTime} onChange={(e) => setMarketTime(e.target.value)} />
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                onClick={handleSubmitMarketTime}
                            >
                                Submit
                            </button>
                            <a className="modal-cancel-btn" data-dismiss="modal">
                                No
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CalendarModel
