import React, { useEffect, useRef, useState } from 'react';
import './editfancy.css';
import { useLocation } from 'react-router-dom';
import actions from '../../redux/actions';
import { notifyError } from '../../components/ToastContext';
import { getCustomFancyById } from '../../redux/actions/bookEventActions';
const FancyEditForm = () => {

    const location = useLocation();
    const receivedRunnerData = location.state;

    const [txtRateDiff, setTxtRateDiff] = useState(parseFloat(receivedRunnerData.BackPrice1) - parseFloat(receivedRunnerData.LayPrice1));
    const [txtPointDiff, setTxtPointDiff] = useState(parseFloat(receivedRunnerData.BackSize1) - parseFloat(receivedRunnerData.LaySize1));
    const layPriceInputRef = useRef(null);

    const [formData, setFormData] = useState({
        MarketId: receivedRunnerData.marketID,
        EventId: receivedRunnerData.eventId,
        RunnerName: receivedRunnerData.RunnerName,
        SelectionId: receivedRunnerData.SelectionId,
        BackPrice1: receivedRunnerData.BackPrice1,
        BackSize1: receivedRunnerData.BackSize1,
        LayPrice1: receivedRunnerData.LayPrice1,
        LaySize1: receivedRunnerData.LaySize1,
        Max: receivedRunnerData.max,
        Min: receivedRunnerData.min,
        delay: 0,
        IsActive: true,
        IsCustome: false,
        GameStatus: ""
    });
    const [previousFormData, setPreviousFormData] = useState(formData);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
console.log("e.target",name, value, type, checked);
        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else if (name === 'LayPrice1' || name === 'BackPrice1') {
            setFormData((prevData) => ({
                ...prevData,
                LayPrice1: value,
                BackPrice1: (parseFloat(value) + parseFloat(txtRateDiff)).toString(),
            }));

        } else {

            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        if (e.key === 'Enter') {
            // Update radio input value
            const newStatus = formData.GameStatus === '' ? 'Ball Running' : '';
            if (formData.GameStatus === 'Ball Running' || formData.GameStatus === 'SUSPENDED') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    GameStatus: '',
                }));
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    GameStatus: newStatus,
                }));
            }
        }
        if (name === "IsCustome" && checked == false) {
            setFormData((prevData) => ({
                ...prevData,
                BackPrice1: receivedRunnerData.BackPrice1,
                BackSize1: receivedRunnerData.BackSize1,
                LayPrice1: receivedRunnerData.LayPrice1,
                LaySize1: receivedRunnerData.LaySize1,
                Max: receivedRunnerData.max,
                Min: receivedRunnerData.min,
            }));
        }
    }


    useEffect(() => {
        const isFormDataChanged = JSON.stringify(formData) !== JSON.stringify(previousFormData);
        if (isFormDataChanged) {
            actions.postCustomeFancy(formData).then(() => {
                setPreviousFormData(formData);
            }).catch((error) => {
                notifyError(error);
            });
        }
    }, [formData, previousFormData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getCustomFancyById({ MarketId: receivedRunnerData.marketID, selectionId: receivedRunnerData.SelectionId });
                if (response.data.Status) {
                    setFormData((prevData) => ({
                        ...prevData,
                        IsCustome: response?.data?.Result?.isCustome
                    }));
                }
                else {
                    notifyError(response.data.Result)
                }
            } catch (error) {
                notifyError(error);
            }

        };

        fetchData();

    }, []);



    const setSizeHandler = (value1, value2) => {
        layPriceInputRef.current.focus();
        if (!formData.IsCustome) {
            setFormData((prevData) => ({
                ...prevData,
                BackPrice1: receivedRunnerData.BackPrice1,
                BackSize1: receivedRunnerData.BackSize1,
                LayPrice1: receivedRunnerData.LayPrice1,
                LaySize1: receivedRunnerData.LaySize1,
                Max: receivedRunnerData.max,
                Min: receivedRunnerData.min,
            }));
        } else {
            const ratDiff = value1 - value2 >= 0 ? 1 : 0;
            setTxtRateDiff(ratDiff);
            setTxtPointDiff(value1 - value2);
            setFormData((prevData) => ({
                ...prevData,
                LaySize1: value2.toString(),
                BackSize1: value1.toString(),
                BackPrice1: (parseFloat(formData.LayPrice1) + parseFloat(ratDiff)).toString(),
            }));
        }
    }
    const setRateDiffHandler = (e) => {
        const { name, value } = e.target;
        setTxtRateDiff(value);
        setFormData((prevData) => ({
            ...prevData,
            BackPrice1: (parseFloat(formData.LayPrice1) + parseFloat(value)).toString(),
        }));
    }


    return (
        <div className="container-fluid">
            {/* Page Heading */}
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
            </div>
            {/* From Contant */}
            <div className='card shadow mb-4 col-lg-12'>
                <div className='card-body'>
                    <form className="fancy">
                        <div className="form-group row">
                            <div className="col-sm-1 mb-3 mb-sm-3">
                                <label className="chkLabel"> IsManual</label>
                                <label className="toggle">
                                    <input name="IsCustome" type="checkbox" checked={formData.IsCustome} onChange={handleInputChange} />
                                    <div className="slider"></div>
                                </label>
                            </div>
                            <div className="row col-sm-9 mb-3">
                                <div className="form-check custom-radio">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="GameStatus"
                                        id="R2option1"
                                        value=""
                                        checked={formData.GameStatus === ''}
                                        onChange={handleInputChange}

                                    />
                                    <label className="form-check-label" htmlFor="R2option1">
                                        Live
                                    </label>
                                </div>
                                <div className="form-check mx-4 custom-radio">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="GameStatus"
                                        id="R2option2"
                                        value="Ball Running"
                                        checked={formData.GameStatus === 'Ball Running'}
                                        onChange={handleInputChange}

                                    />
                                    <label className="form-check-label" htmlFor="R2option2">
                                        Ball Running
                                    </label>
                                </div>

                                <div className="form-check custom-radio">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="GameStatus"
                                        id="R2option3"
                                        value="SUSPENDED"
                                        checked={formData.GameStatus === 'SUSPENDED'}
                                        onChange={handleInputChange}

                                    />
                                    <label className="form-check-label" htmlFor="R2option3">
                                        SUSPENDED
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <label>Min</label>
                                <input name="Min" type="number" value={formData.Min} id="txtmin" className="form-control" onChange={handleInputChange} />
                            </div>
                            <div className="col-sm-6">
                                <label>Max</label>
                                <input name="Max" type="number" value={formData.Max} id="txtmax" className="form-control" onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="matero-col  ratediv"  >
                                    <table className="nestedTable">
                                        <thead>
                                            <tr>
                                                <th width="50%">Session Runs</th>
                                                <th width="10%" className="text-center bg-pink-200"><span>No</span></th>
                                                <th width="10%" className="text-center bg-blue-300"><span>Yes</span></th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ "position": "relative" }}>
                                                <td width="50%">
                                                    <div className="event-name" style={{ color: "rgb(15, 35, 39)" }}>
                                                        <input type="hidden" name="ctl00$ContentPlaceHolder1$hdnfancyname" id="hdnfancyname" value="35 over run BAN" />
                                                        <span id="lblrunner">{formData.RunnerName}</span>
                                                    </div>
                                                </td>
                                                <td style={{ "position": "inherit" }} width="10%" className="bg-pink-200 text-center">
                                                    {formData.GameStatus !== "" && <div className="suspended-bet ng-star-inserted"><b className="text-red-900">{formData.GameStatus}</b></div>}
                                                    <p className="m-t-0 m-b-4"><strong className="lblnorun">{formData.LayPrice1}</strong></p>
                                                    <small className="text-grey lblnosize">{formData.LaySize1}</small></td>
                                                <td width="10%" className="bg-blue-300 text-center">
                                                    <p className="m-t-0 m-b-4"><strong className="lblyesrun">{formData.BackPrice1}</strong></p>
                                                    <small className="text-grey lblyessize">{formData.BackSize1}</small></td>
                                                <td>
                                                    <p className="m-t-0 m-b-4 text-grey">Max Bet :{formData.Max}</p>
                                                    <p className="m-t-4 m-b-0 text-grey">Min Bet :{formData.Min}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputEmail4">Rate Difference </label>
                                        <input name="txtRateDiff" type="number" value={txtRateDiff} onChange={setRateDiffHandler} id="txtRateDiff" className="form-control" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="inputPassword4">Point Difference</label>
                                        <input name="txtPointDiff" type="number" value={txtPointDiff} onChange={(event) => setTxtPointDiff(event.target.value)} id="txtPointDiff" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className='padintop10'>
                                    <table>
                                        <tbody><tr>
                                            <td></td>
                                            <td>RUN</td>
                                            <td>SIZE</td>
                                        </tr>
                                            <tr>
                                                <td>No</td>
                                                <td>
                                                    <input name="LayPrice1" type="number" id="txtyesrun" className="form-control myenter" value={formData.LayPrice1} onChange={handleInputChange} onKeyDown={handleInputChange} ref={layPriceInputRef} />
                                                </td>
                                                <td>
                                                    <input name="LaySize1" type="number" id="txtyessize" className="form-control myenter" value={formData.LaySize1} onChange={handleInputChange} onKeyDown={handleInputChange} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Yes</td>
                                                <td>
                                                    <input name="BackPrice1" type="number" id="txtnorun" className="form-control myenter" value={formData.BackPrice1}
                                                        onChange={handleInputChange} onKeyDown={handleInputChange}
                                                    />
                                                </td>
                                                <td>
                                                    <input name="BackSize1" type="number" id="txtnosize" className="form-control myenter" value={formData.BackSize1}
                                                        onChange={handleInputChange} onKeyDown={handleInputChange}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody></table>
                                </div>
                            </div>
                            <input disabled={!formData.IsCustome} type="button" onClick={() => setSizeHandler(95, 105)} className="btn btn-success btn-xs" value="95-105" />
                            <input disabled={!formData.IsCustome} type="button" onClick={() => setSizeHandler(90, 110)} className="btn btn-success btn-xs" value="90-110" />
                            <input disabled={!formData.IsCustome} type="button" onClick={() => setSizeHandler(85, 115)} className="btn btn-success btn-xs" value="85-115" />
                            <input disabled={!formData.IsCustome} type="button" onClick={() => setSizeHandler(100, 100)} className="btn btn-success btn-xs" value="100-100" />
                            <input disabled={!formData.IsCustome} type="button" onClick={() => setSizeHandler(80, 120)} className="btn btn-success btn-xs" value="80-120" />
                            <input disabled={!formData.IsCustome} type="button" onClick={() => setSizeHandler(100, 150)} className="btn btn-success btn-xs" value="100-150" />

                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export default FancyEditForm;
