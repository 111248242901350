import React, { useState, useEffect } from 'react'
import moment from 'moment'
import actions from '../../redux/actions'
import { notifySuccess } from '../ToastContext';

const CreateCustomMarketModal = () => {

    const [formData, setFormData] = useState({
        "SportsId": "4",
        "MarketTime": moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        "SportsName": "Cricket",
        "CompetitionId": "",
        "CompetitionName": "",
        "EventId": "",
        "EventName": "",
        "EventDate": moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "MarketId": "",
        "MarketName": "MATCH_ODDS",
        "Runnercount": 2,
        "MarketStatus": 1,
        "Runners": [
            {
                "RunnerName": "",
                "SelectionId": 1
            }, {
                "RunnerName": "",
                "SelectionId": 2
            }
        ]
    })

    useEffect(() => {
        // Update the Runners array when Runnercount changes
        const updatedRunners = Array.from({ length: formData.Runnercount }, (_, index) => (
            formData.Runners[index] || { "RunnerName": "", "SelectionId": index + 1 }
        ));

        setFormData(prevData => ({
            ...prevData,
            Runners: updatedRunners
        }));
    }, [formData.Runnercount]);


    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === 'SportsId') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                SportsName: e.target.options[e.target.selectedIndex].text,
            }));
        }
        else {
            setFormData({ ...formData, [name]: value })
        }
        // console.log("formData", formData);
    }

    const handleRunnerNameChange = (index, value) => {
        const updatedRunners = [...formData.Runners];
        updatedRunners[index].RunnerName = value;

        setFormData({
            ...formData,
            Runners: updatedRunners
        });
    };

    const runnerTextboxes = Array.from({ length: formData.Runnercount }, (_, index) => (
        <div key={index}>
            <label>{`Runner ${index + 1}:`}</label>
            <input
                type="text"
                value={formData.Runners[index]?.RunnerName}
                className='form-control'
                onChange={(e) => handleRunnerNameChange(index, e?.target?.value ? e?.target?.value : 0)}
            />
        </div>
    ));


    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData)
        await actions.postMarketCustomAction(formData)
        notifySuccess("data submitted");
    };

    return (
        <div>
            <div
                className="modal fade"
                id="createCustomMarketModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Create Match
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className=" col-sm-6 mb-3 mb-sm-0">
                                    <label>EventID</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name='EventId'
                                        value={formData.EventId}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label>EventName</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name='EventName'
                                        value={formData.EventName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className=" col-sm-6 mb-3 mb-sm-0">
                                    <label>CompetitionId</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name='CompetitionId'
                                        value={formData.CompetitionId}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label>CompetitionName</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name='CompetitionName'
                                        value={formData.CompetitionName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-6">
                                    <label>MarketId</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name='MarketId'
                                        value={formData.MarketId}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label>MarketName</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name='MarketName'
                                        value={formData.MarketName}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="form-group row mb-3">
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label>EventDate</label>
                                    <input
                                        type="datetime-local"
                                        className='form-control'
                                        name="EventDate"
                                        value={moment(formData.EventDate).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}

                                    />
                                </div>
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label>Runnercount</label>
                                    <input
                                        type="number"
                                        className='form-control'
                                        name='Runnercount'
                                        value={formData.Runnercount}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="form-group row mb-3">
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <label>SportsId</label>
                                    <select className='form-control' name='SportsId' value={formData.SportsId} onChange={handleChange}>
                                        <option value="4">Cricket</option>
                                        <option value="1">Soccer</option>
                                        <option value="2">Tennis</option>
                                    </select>
                                </div>
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    {runnerTextboxes}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                onClick={handleSubmit}
                                data-dismiss="modal"
                            >
                                Submit
                            </button>
                            <button
                                className="modal-cancel-btn"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateCustomMarketModal
