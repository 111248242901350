import React from 'react'
import ClientListTable from './ClientListTable';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';

const ClientList = () => {
  return (
    <NavbarSidebarWrapper>
      <ClientListTable />
    </NavbarSidebarWrapper>
  )
}

export default ClientList;
