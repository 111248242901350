import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import actions from "../../../redux/actions";

import ScreenLoader from "../../../components/ScreenLoader";

 
const ClinentFindListTable = () => {
  const clientData = useSelector(
    (state) => state.client.clientFindDataList?.Result
  );
  const totalrow = useSelector(
    (state) => state.client.clientFindDataList?.Count
  );
  let navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(0);
  const [editClientUid, setEditClientUid] = useState(0);
  const [managerUid, setManagerUid] = useState(0);
 
  const [loader, setLoader] = useState(true);
 

  let { pageIndex, pageSize, mid, role } = useParams();
  let [clientActionReq, setClientActionReq] = useState({
    uid: decodeURIComponent(mid),
    searchData: "",
    Status: false,
    PageIndex: parseInt(pageIndex),
    PageSize: parseInt(pageSize),
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleEditShow = () => {
    setShowEditModal(true);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };
  const handleCreateShow = () => setShowCreateModal(true);
  const handleCreateClose = () => setShowCreateModal(false);
  const [active, setActive] = useState(true);
  const [deactive, setDeactive] = useState(false);

  const handleClientList = async (data) => {
    setLoader(true);
    await actions.getClientFindList(data ? data : clientActionReq);
    setLoader(false);
  };
  useEffect(() => {
    let data = {
      uid: decodeURIComponent(mid),
      searchData: "",
      Status: false,
      PageIndex: parseInt(pageIndex),
      PageSize: parseInt(pageSize),
    };
    handleClientList(data);
    setClientActionReq(data);
  }, [pageIndex, pageSize, mid]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / pageSize));
  }, [totalrow, pageSize]);

  const handlePageNoChange = (newPageNo) => {
    setClientActionReq((prevState) => ({ ...prevState, PageIndex: newPageNo }));
    let data = { ...clientActionReq, PageIndex: newPageNo };
    actions.getClientFindList(data);
  };

  const DynamicPagination = (number) => {
    let divCount = number;
    if (number > 4) {
      divCount = 4;
    }
    const divs = [];
    for (let i = number - divCount + 1; i <= number; i++) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              clientActionReq?.PageIndex + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setClientActionReq((prevState) => ({
                ...prevState,
                PageIndex: i,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };
 

  const handleSearch = (searchText) => {
    actions.getClientFindList({ ...clientActionReq, searchData: searchText });
  };

  
 

  const handleConfig = (userId) => {
    navigate("/user-setting", { state: { userId: userId } });
  };

  const handleClientActiveDeactive = (status) => {
    setClientActionReq((prevState) => ({ ...prevState, Status: status }));
    handleClientList({ ...clientActionReq, Status: status });
  };

  return (
    <>
       
   
      
     
      <div className="content-header-btn">
        Client find   
      </div>
      {loader ? (
        <ScreenLoader />
      ) : (
        <div className="card shadow mb-4">
          <div className="row">
            <div
              className="col-5 col-md-2 col-lg-1 mt-2"
              style={{ marginLeft: "30px" }}
            >
              <label className="form-check-label">
                <input
                  type="radio"
                  checked={active}
                  className="form-check-input mr-2"
                  onChange={(e) => {
                    handleClientActiveDeactive(false);
                    setActive(true);
                    setDeactive(false);
                  }}
                />
                Active
              </label>
            </div>
            <div className="col-5 col-md-5 col-lg-5 mt-2">
              <label className="form-check-label">
                <input
                  type="radio"
                  checked={deactive}
                  className="form-check-input mr-2"
                  onChange={(e) => {
                    handleClientActiveDeactive(true);
                    setDeactive(true);
                    setActive(false);
                  }}
                />{" "}
                De-active
              </label>
            </div>
          </div>
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div className="d-flex d-wrap">
                <div className="col-sm-12 d-flex justify-content-end">
                  <label>
                    {/* Search: */}
                    <input
                      type="search"
                      className="form-control form-control-sm mt-1"
                      placeholder="Search"
                      aria-controls="dataTable"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <table
                className="table table-bordered dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                role="grid"
                aria-describedby="dataTable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr role="row" className="bg-gray-300">
                    <th
                      className="sorting text-wrap sorting_asc"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Name: activate to sort column descending"
                      style={{ width: 200 }}
                    >
                      Name [UserName]
                    </th>
                    <th
                      className="sorting sorting_asc"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Name: activate to sort column descending"
                      style={{ width: 100 }}
                    >
                      agent
                    </th>
                    <th
                      className="sorting sorting_asc"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Name: activate to sort column descending"
                      style={{ width: 200 }}
                    >
                      Master
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Position: activate to sort column ascending"
                      style={{ width: "350px" }}
                    >
                      Subadmin
                    </th>
                    <th
                      className="sorting text-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Office: activate to sort column ascending"
                      style={{ width: 145 }}
                    >
                    Admin
                    </th>
                         
                  </tr>
                </thead>
                <tbody>
                  {clientData &&
                    Array.isArray(clientData) &&
                    clientData?.map((client, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even" : "odd"}
                      >
                     
                        
                        <td>
                        
                           
                            <span className="text-no-wrap">
                              {" "}
                              <button className="custom-yellow-btn">
                                {client.role}
                              </button>
                              {client.Name}[{client.UserName}]
                            </span>
                        
                        </td>
                        <td>
                          <span className="text-wrap">{client.Agent}</span>
                        </td>
                        <td>
                          <span className="text-wrap">{client.Master}</span>
                        </td>
                        <td>
                          <span className="text-wrap">{client.Subadmin}</span>
                        </td>
                        <td>
                          <span className="text-wrap">{client.Admin}</span>
                        </td>

                       
                      </tr>
                    ))}
                </tbody>
              </table>
              {clientData &&
                Array.isArray(clientData) &&
                clientData?.length > 0 && (
                  <div className="row m-1">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="dataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Total Page : {totalPage}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="dataTable_paginate"
                      >
                        <ul className="pagination d-flex justify-content-end">
                          <li
                            className="paginate_button page-item previous disabled"
                            id="dataTable_previous"
                          >
                            <button
                              className="pagination-action-btn"
                              disabled={
                                clientActionReq?.PageIndex === 0 ? true : false
                              }
                              onClick={() => {
                                handlePageNoChange(
                                  clientActionReq?.PageIndex - 1
                                );
                              }}
                            >
                              Previous
                            </button>
                          </li>
                          {DynamicPagination(totalPage)}
                          <li
                            className="paginate_button page-item next"
                            id="dataTable_next"
                          >
                            <button
                              className="pagination-action-btn"
                              disabled={
                                clientActionReq?.PageIndex === totalPage - 1
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                handlePageNoChange(
                                  clientActionReq?.PageIndex + 1
                                );
                              }}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClinentFindListTable;
