import React from 'react'

const AllMarketTableItem = ({ data, handleMyBookAction }) => {
    return (
        <div>
            <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                <thead>
                    <tr role="row">
                        <th className="text-center" style={{ width: "40.2px" }}>
                            id
                        </th>
                        <th className=" text-center" style={{ width: "480.2px" }}>
                            Match
                        </th>
                        <th className="text-center" style={{ width: "200.2px" }}>
                            OpenDate
                        </th>
                        <th className="text-center" style={{ width: "98.2px" }}>
                            Result
                        </th>
                        <th className="text-center" style={{ width: "40.2px" }}>
                            Match Book
                        </th>
                        <th className="text-center" style={{ width: "40.2px" }}>
                            Session P/L
                        </th>
                        <th className="text-center" style={{ width: "40.2px" }}>
                            Toss Book
                        </th>
                        <th className="text-center" style={{ width: "40.2px" }}>
                            Client P/L
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((match, index) => (
                        <tr key={index} className={index % 2 === 0 ? "even" : "odd"}>
                            <td>{match.bets}</td>
                            <td>{match.matchName}</td>
                            <td>2/2/2023 5:30:00 AM</td>
                            <td>Running</td>
                            <td >
                                <button className="btn btn-success btn-sm text-no-wrap text-white" onClick={() => handleMyBookAction(index)}>Match Book</button>
                            </td>
                            <td >
                                <button className="btn btn-primary btn-sm text-no-wrap text-white">Session P/L</button>
                            </td>
                            <td >
                                <button className="btn btn-success btn-sm text-no-wrap text-white">Toss Book</button>
                            </td>
                            <td >
                                <button className="btn btn-success btn-sm text-no-wrap text-white">Client Book</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AllMarketTableItem
