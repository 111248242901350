import FancyService from "../../services/fancyService";
import {saveFancyList } from "../slices/fancySlice";
import store from "../store";

 
export function GetFancyList (marketId) {
    return new Promise((resolve, rejact) => {
        FancyService.getFancyListService(marketId).then((res) => {
            store.dispatch(saveFancyList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

export function PostUpadteFancyList(data) {
    return new Promise((resolve, rejact) => {
        FancyService.postUpdateFancytService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketSettingModelapi", error)
            // rejact(error)
        })
    })
}
 
      