import React, { useEffect, useState } from 'react'
import CasinoBetsTable from './CasinoBetsTable';
import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import moment from 'moment';
import actions from "../../redux/actions";
import ScreenLoader from '../../components/ScreenLoader';
import { useSelector } from 'react-redux';


const CasinoBets = () => {
   
   const plBetList = useSelector((state) => state.report?.savedeleteBets?.Result);
    console.log("Casino_BetList",plBetList);
  const [loader, setLoader] = useState(false);
  const [eventid, setEventid] = useState('');
 
  useEffect(() => {
    const fetchData = async () => {
        await  actions.CasinoBetListAction()
      };
      const checkTokenValidity = async () => {
        await fetchData();
      };
      checkTokenValidity();
      const intervalId = setInterval(checkTokenValidity, 10000);
      return () => clearInterval(intervalId);
   
  }, []);
 
  

  return (
    <NavbarSidebarWrapper>
         <div className="content-header-btn">
        Live Casino Bets   
      </div>
      {loader ? <ScreenLoader /> :
        <div className="card shadow mb-4">
          <div className="">
          
            <div className="table-responsive">
              <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <CasinoBetsTable plBetList={plBetList}/>
              </div>
            </div>
          </div>
        </div>
      }
    </NavbarSidebarWrapper>
  )
}

export default CasinoBets;
