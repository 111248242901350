import React, { useState, useEffect } from "react";
import Sidebar from '../../../components/sidebar';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import LogoutModel from '../../../components/Models/LogoutModel';
import actions from '../../../redux/actions';
import { useSelector } from "react-redux";
//import PLBetListModal from "../../../components/Models/PLBetListModal";
import moment from "moment";

const MarketPLBetTable = () => {

    let sharingDetialsData = useSelector((state) => state.report.sharingDetials?.Result);
    const plBetList = useSelector((state) => state.bookEvent.fancyBetList);

    const [Total, setTotal] = useState({
        totalMinus: 0,
        totalPlus: 0,
    });

    //console.log("sharingDetialsData", sharingDetialsData?.plusMinuAccounts);

    useEffect(() => {
        if (sharingDetialsData?.plusMinuAccounts && Array.isArray(sharingDetialsData?.plusMinuAccounts)) {
            const totals = {
                totalMinus: 0,
                totalPlus: 0,
            };

            for (const obj of sharingDetialsData?.plusMinuAccounts) {
                if (obj.marketType.toLowerCase() === 'minus') {
                    totals.totalMinus += obj.profitLoss;
                } else if (obj.marketType.toLowerCase() === 'plus') {
                    totals.totalPlus += obj.profitLoss;
                }
            }

            setTotal(totals);
        }
    }, [sharingDetialsData?.plusMinuAccounts]);


    const handlePlBetList = (plMarket) => {
        const data = {
            fancyId: plMarket?.id,
            marketType: plMarket?.MType,
            marketId: plMarket?.MarketId,
        }
        // actions.getMarketBetPLList(data);
    }

    return (
        <div>
            {/* Page Wrapper */}
            <div id="wrapper">
                {/* Sidebar */}
                <Sidebar />
                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* Main Content */}
                    <div id="content">
                        {/* Topbar */}
                        <Navbar navTitle={"Profit Loss"} />
                        {/* Begin Page Content */}
                        <div className="card shadow mb-4">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <div
                                        id="dataTable_wrapper"
                                        className="dataTables_wrapper dt-bootstrap4">
                                        {/* above section */}
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6">
                                                <div className="table-responsive">
                                                    <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                                        <div className="">
                                                            <div className="">
                                                                <table className="table table-bordered dataTable" >
                                                                    <thead >
                                                                        <tr className="bg-primary">
                                                                            <th colSpan="2" className="text-white">
                                                                                Plus Account
                                                                            </th>
                                                                        </tr>
                                                                        <tr className="bg-success">
                                                                            <th className="text-white col-desc">UserName</th>
                                                                            <th className="text-white col-balance">Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {sharingDetialsData?.plusMinuAccounts && Array.isArray(sharingDetialsData?.plusMinuAccounts) && sharingDetialsData?.plusMinuAccounts.filter((item) => item.marketType.toLowerCase() === "plus").map((item, index) => (
                                                                            <tr className="odd" key={index}>
                                                                                <td>
                                                                                    <span className="text-no-wrap">{item?.userName}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-no-wrap green-text">{item?.profitLoss}</span>
                                                                                </td>

                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr style={{ backgroundColor: "#e3e6f0" }}>
                                                                            <td>
                                                                                <b>Total</b>
                                                                            </td>
                                                                            <td>
                                                                                <b>{Total.totalPlus}</b>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="table-responsive">
                                                    <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                                        <div className="">
                                                            <div className="">
                                                                <table className="table table-bordered dataTable" >
                                                                    <thead >
                                                                        <tr className="bg-primary">
                                                                            <th colSpan="2" className="text-white">
                                                                                Minus Account
                                                                            </th>
                                                                        </tr>
                                                                        <tr className="bg-danger">
                                                                            <th className="text-white col-desc">UserName</th>
                                                                            <th className="text-white col-balance">Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {sharingDetialsData?.plusMinuAccounts && Array.isArray(sharingDetialsData?.plusMinuAccounts) && sharingDetialsData?.plusMinuAccounts.filter((item) => item.marketType.toLowerCase() === "minus").map((item, index) => (
                                                                            <tr className="odd" key={index + 1}>
                                                                                <td>
                                                                                    <span className="text-no-wrap" >{item?.userName}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-no-wrap red-text">{item?.profitLoss}</span>
                                                                                </td>

                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                    <tfoot>
                                                                        <tr style={{ backgroundColor: "#e3e6f0" }}>
                                                                            <td>
                                                                                <b>Total</b>
                                                                            </td>
                                                                            <td>
                                                                                <b>{Total.totalMinus}</b>
                                                                            </td>
                                                                        </tr>
                                                                    </tfoot>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end section */}
                                        <div className="">
                                            <div className="">
                                                <table className="table table-bordered dataTable" >
                                                    <thead>
                                                        <tr className="bg-gray-200">
                                                            <th className="col-balance">Match Name</th>
                                                            <th className="col-date">Market Name</th>
                                                            <th className="col-credit">Selction Name</th>
                                                            <th className="col-sno">Odds</th>
                                                            <th className="col-sno">Stack</th>
                                                            <th className="col-date">User</th>
                                                            <th className="col-sno">Bet Type</th>
                                                            <th className="col-sno">Bet Status</th>
                                                            <th className="col-sno">Result</th>
                                                            <th className="col-sno">Exp/Profit</th>
                                                            <th className="col-balance text-no-wrap">IP Address</th>
                                                            <th className="col-date">Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {
                                                            plBetList && Array.isArray(plBetList) && plBetList?.map((data, index) => (
                                                                <tr key={index} className={data.BetType === "No" || data.BetType === "Lay" ? "lay" : "back"}>
                                                                    {/* <td onClick={() => handlePlBetList(data)}><span>{data?.Sport}{" > "}{data?.Match}  ({data?.MType})</span> </td> */}
                                                                    <td>{data?.MatchName}</td>
                                                                    <td>{data?.MarketType}</td>
                                                                    <td>{data?.SelectionName}</td>
                                                                    <td>{data?.Odds}</td>
                                                                    <td>{data?.Stack}</td>
                                                                    <td>{data?.UserName}</td>
                                                                    <td>{data?.BetType}</td>
                                                                    <td className={data?.BetStatus.toLowerCase()==="win"?"green-text":"red-text"}>{data?.BetStatus}</td>
                                                                    <td> {data?.Result}</td>
                                                                    <td className={data?.BetStatus.toLowerCase()==="win"?"green-text text-no-wrap":"red-text text-no-wrap"}>
                                                                    {(data?.BetStatus.toLowerCase()==="win") ? data?.ReturnBalance : data?.Exposure}
                                                                        </td>
                                                                    <td>{data?.IP_Address}</td>
                                                                    <td>{moment(data?.createdOn).format('DD-MM-YYYY h:mm:ss A')}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {/* <tr className="bg-gray-400">
                                                            <td>
                                                                <b className="text-no-wrap">TOTAL</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.pf}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.comm}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.totalpl}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.Comm_Dena}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.Comm_Lena}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.DPL}</b>
                                                            </td>
                                                            <td>
                                                                <b className='red-text'>{Total?.UPL}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.UPL}</b>
                                                            </td>
                                                            <td>
                                                                <b className='red-text'>{Total?.UPL}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.UPL}</b>
                                                            </td>
                                                            <td>
                                                                <b>{Total?.UPL}</b>
                                                            </td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* Footer */}
                    <Footer />
                </div>
            </div>
            {/* Logout Modal*/}
            <LogoutModel />
            {/* <PLBetListModal />*/}
        </div>
    );
};

export default MarketPLBetTable;
