import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import TableWrapper from '../../components/CreateMatch/TableWrapper';
import { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { useSelector } from 'react-redux';
import * as BsIcons from 'react-icons/bs';
import ScreenLoader from '../../components/ScreenLoader';
import { isRoleExits } from '../../utils';
import CreateCustomMarketModal from '../../components/Models/CreateCustomMarketModal';
import { notifyError } from '../../components/ToastContext';

const CreateMatch2 = () => {

    let data = useSelector((state) => state.match?.marketByCompList);
    let Compdata = useSelector((state) => state.match?.CompitonList);
    let dataByIDs = useSelector((state) => state.match?.marketByEventIdList);
    let marketIdsList = useSelector((state) => state.match?.marketIdsList?.Result);


    const [action, setAction] = useState(true);
    const [loader, setLoader] = useState(true);
    const [currentSportId, setCurrentSportId] = useState("4")
    const [selectedLeague, setSelectedLeague] = useState('');

    const handleMarketList = async (sportid) => {
        setLoader(true)
        setCurrentSportId(sportid);
        // actions.getMarketByCompList(0,0);
        // let res = await actions.getComptList(sportid);
        // if (res) {
        //     setLoader(false)
        // }
        try {
            let res = await actions.getComptList(sportid);
            console.log("asdf",res.data[0].id);
            await actions.getMarketByCompList(sportid, res.data[0].id);
        } catch (error) {
            notifyError("An error occurred:", error);
        }finally{
            setLoader(false);
        }
        
    }
    
    useEffect(() => {
        handleMarketList(4);
    }, [])
    
    useEffect(() => {
        //console.log(data);
    }, [data])

    const handleAction = async (eventId, sportId) => {
        setLoader(true)
        setAction((prevState) => !prevState)
        let res = await actions.getMarketListByEventId(sportId, eventId)
        if (res) { setLoader(false) }
    }

    const handleLeagueSelection =async (event) => {

        const selectedValue = event.target.value;
        setSelectedLeague(selectedValue);
        await actions.getMarketByCompList(currentSportId,selectedValue);
        let res = await actions.getMarketIdsList(currentSportId);
        if (res) {
            setLoader(false)
        }
        
        //console.log("Selected League:", selectedValue);
    };

    return (
        <NavbarSidebarWrapper>
            <CreateCustomMarketModal />
            <div>
                {action ?
                    <>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#tab2" onClick={() => handleMarketList(4)}>
                                    Cricket
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#tab3" onClick={() => handleMarketList(1)}>
                                    Soccer
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#tab4" onClick={() => handleMarketList(2)}>
                                    Tennis
                                </a>
                            </li>
                        </ul>
                        {loader ? <ScreenLoader /> :
                            <div className="tab-content">
                                <div className='text-right w-100'>
                                    {isRoleExits('matchmanual') && <button className='back-btn mb-1' data-toggle="modal" data-target="#createCustomMarketModal" > Create Match</button>}
                                </div>
                                <form>
                                    <div className="row">

                                        <div className="col-6 col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="textbox2">Select League</label>
                                                <select
                                                    className='form-control'
                                                    name="betStaus"
                                                    value={selectedLeague}
                                                    onChange={handleLeagueSelection}
                                                >
                                                    {Compdata && Array.isArray(Compdata) && Compdata?.map((role) => (
                                                        <option key={role.id} value={role.id}>
                                                            {role.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3">

                                        </div>
                                    </div>

                                </form>
                                <div className="tab-pane fade show active" id="tab2">
                                    <TableWrapper data={data} marketIdsList={marketIdsList} showAction={action} handleAction={handleAction} />
                                </div>
                                <div className="tab-pane fade" id="tab3">
                                    <TableWrapper data={data} marketIdsList={marketIdsList} showAction={action} handleAction={handleAction} />
                                </div>
                                <div className="tab-pane fade" id="tab4">
                                    <TableWrapper data={data} marketIdsList={marketIdsList} showAction={action} handleAction={handleAction} />
                                </div>
                            </div>}
                    </>
                    :
                    <>
                        {loader ? <ScreenLoader /> :
                            <>
                                <button className='back-btn mb-1' onClick={() => setAction((prevState) => prevState = true)}> <BsIcons.BsArrowReturnLeft /> Back</button>
                                <TableWrapper data={dataByIDs} marketIdsList={marketIdsList} showAction={action} handleAction={handleAction} />
                            </>
                        }
                    </>
                }
            </div>
        </NavbarSidebarWrapper>
    )
};

export default CreateMatch2;