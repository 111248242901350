import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    eventDetailData: [],
    eventDetailfancyData: null,
    matchBetModelList: [],
    BetfareTossOddsData: [],
    fancyPostionData: [],
    BetfareRetsMarketOdds: [],
    useBookData: [],
    userBookName: "",
    customeMarketData: [],
    BetfareRetsBMAndFancyData: [],
    fancyBetList: [],
    fancyActiveList: [],
    fancyUndeclareList: [],
    CustomeBMList: [],
    selectionData: null,
    matchSelectionData: { runner: [] },
    setCurrentFPData: [],
    profitLossOnRunnersData: [],
    blockEventData: [],
    blockSportData: [],
    tennisScoreData: []
}

const bookEventSlice = createSlice({
    name: 'BookEventData',
    initialState: initialState,
    reducers: {
        setEventDetail: (state, action) => {
            state.eventDetailData = action.payload
        },
        setEventFancyDetail: (state, action) => {
            state.eventDetailfancyData = action.payload
        },
        setMatchBetData: (state, action) => {
            state.matchBetModelList = action.payload
        },
        saveBfRTossOddds: (state, action) => {
            state.BetfareTossOddsData = action.payload
        },
        setFancyPostionData: (state, action) => {
            state.fancyPostionData = action.payload
        },
        saveBfRMarketOdddsOther: (state, action) => {
            state.BetfareRetsMarketOdds = action.payload
        },
        setUseBookData: (state, action) => {
            state.useBookData = action.payload
        },
        setUserBookName: (state, action) => {
            state.userBookName = action.payload
        },
        setCustomeMarketData: (state, action) => {
            state.customeMarketData = action.payload
        },
        saveBetfareRetsBMAndFancy: (state, action) => {
            state.BetfareRetsBMAndFancyData = action.payload
        },
        setFancyBetList: (state, action) => {
            state.fancyBetList = action.payload
        },
        setFancyActiveList: (state, action) => {
            state.fancyActiveList = action.payload
        },
        setfancyUndeclareList: (state, action) => {
            state.fancyUndeclareList = action.payload
        },
        setCustomeBMList: (state, action) => {
            state.CustomeBMList = action.payload
        },
        setSelectionData: (state, action) => {
            state.selectionData = action.payload
        },
        setMatchSelectionData: (state, action) => {
            state.matchSelectionData = action.payload
        },
        setCurrentFP: (state, action) => {
            state.setCurrentFPData = action.payload
        },
        setProfitLossOnRunnersData: (state, action) => {
            state.profitLossOnRunnersData = action.payload
        },
        setScoreData: (state, action) => {
            state.scoreData = action.payload
        },
        setTennisScoreData: (state, action) => {
            state.tennisScoreData = action.payload
        },
        setBlockEventData: (state, action) => {
            state.blockEventData = action.payload
        },
        setBlockSportData: (state, action) => {
            state.blockSportData = action.payload
        },
        resetMarketData: (state) => {
            Object.assign(state, initialState);
        }

    }
})

export const { resetMarketData, setMatchSelectionData, setEventFancyDetail, setCustomeBMList, setFancyActiveList, setfancyUndeclareList, setEventDetail, setMatchBetData, saveBfRTossOddds, setFancyPostionData, setTennisScoreData, saveBfRMarketOdddsOther, setUseBookData, setUserBookName, setCustomeMarketData, setScoreData, saveBetfareRetsBMAndFancy, setFancyBetList, setSelectionData, setCurrentFP, setProfitLossOnRunnersData, setBlockEventData, setBlockSportData } = bookEventSlice.actions;
export default bookEventSlice.reducer