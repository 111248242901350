import axios from 'axios';
import { GETCLIENTLIST,GETCLIENTNEWLIST,GETCLIENTFINDLIST, UPDATECLIENTSTATUS, CREATECLIENTSTATUS, GETAMOUNTMODAL, SAVEAMOUNTMODAL, GETALLROLES, SAVEROLE, USERSETTINGAPI, SAVEUSERSETTINGAPI, SAVEALLUSERSETTINGAPI, GETROLEBYUSER } from './url';


class ClientServiceClass {
    async getClientListService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${GETCLIENTLIST}`, data, {
            headers: headers,
        });
    }
    async getClientNewListService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${GETCLIENTNEWLIST}`, data, {
            headers: headers,
        });
    }
    async getClientFindListService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${GETCLIENTFINDLIST}`, data, {
            headers: headers,
        });
    }

   
    async updateClientStatusService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${UPDATECLIENTSTATUS}`, data, {
            headers: headers,
        });
    }

    async createClientStatusService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${CREATECLIENTSTATUS}`, data, {
            headers: headers,
        });
    }
    async getAmountService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${GETAMOUNTMODAL}`, data, {
            headers: headers,
        });
    }
    async saveAmountService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${SAVEAMOUNTMODAL}`, data, {
            headers: headers,
        });
    }
    async saveUserSettingService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${SAVEUSERSETTINGAPI}`, data, {
            headers: headers,
        });
    }
    async saveAllUserSettingService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.post(`${SAVEALLUSERSETTINGAPI}`, data, {
            headers: headers,
        });
    }
    async userSettingService(userId) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${USERSETTINGAPI}?userId=${userId}`, {
            headers: headers,
        });
    }
    async getAllRolesService() {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${GETALLROLES}`, {
            headers: headers,
        });
    }

    async getRoleByUserService(uid) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${GETROLEBYUSER}?uid=${uid}`, {
            headers: headers,
        });
    }
    async saveUserRoleService(data) {
        let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"))

        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${logindata?.Token}`
        };

        return await axios.get(`${SAVEROLE}?uid=${data?.uid}&roleid=${data?.roleid}`, {
            headers: headers,
        });
    }
}


const ClientService = new ClientServiceClass();
export default ClientService;
