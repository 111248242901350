import React from 'react';
import { useSelector } from 'react-redux';
import * as RiIcons from 'react-icons/ri'
import actions from '../../redux/actions';
import store from '../../redux/store';
import { setFancyBetList } from '../../redux/slices/bookEventSlice';

const PLBetListModal = () => {

    const plBetList = useSelector((state) => state.bookEvent.fancyBetList);

    
    
    return (
        <div>
            <div className="modal fade" id="plBetListModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Bet List
                            </h6>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                            <thead>
                                <tr role="row">
                                    <th>No.</th>
                                    <th>Market Name</th>
                                    <th>BetType</th>
                                    <th>Odd</th>
                                    <th>Stack</th>
                                    <th>MarketType</th>
                                    <th>Selection Name</th>
                                    <th>Date</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {plBetList?.map((item, index) => (
                                    <tr key={index} className={item.BetType === "No" || item.BetType === "Lay" ? "lay" : "back"}>
                                    <td>{index + 1}</td>
                                        <td>{item?.MatchName}</td>
                                        <td>{item?.BetType}</td>
                                        <td>{item?.MarketType === 'FANCY' ? item?.Odds + '(' + item?.Price + ')' : item?.Odds}</td>
                                        <td>{item?.Stack}</td>
                                        <td>{item?.MarketType}</td>
                                        <td>{item?.SelectionName}</td>
                                        <td>{item?.createdOn}</td>
 
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PLBetListModal
