import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import LiveTV from "../../components/LiveTV/LiveTV";
import Bookmaker from "../../components/Bookmaker/Bookmaker";
import FancyBets from "../../components/FancyBets/FancyBets";
import FancyUnDeclare from "../../components/FancyUnDeclare/FancyUnDeclare";
import AllBetList from "../../components/AllBetList/AllBetList";
import Footer from "../../components/footer";
import LogoutModel from "../../components/Models/LogoutModel";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import BookModal from "../../components/Models/BookModal";
import {
  setCustomeBMList,
  setEventDetail,
  setFancyActiveList,
  setUserBookName,
} from "../../redux/slices/bookEventSlice";
import store from "../../redux/store";
import FancyBetListModal from "../../components/Models/FancyBetListModal";
import FancyPleaceBet from "../../components/Models/FancyBet";
import MarketPleaceBet from "../../components/Models/MarketPleaceBet";
import {
  startConnection,
  stopConnection,
  subscribeToEvent,
} from "../../services/SignalRService";
import FancyCurrentPostionModal from "../../components/Models/FancyCurrentPostionModal";
import { handleConfirmation, isRoleExits } from "../../utils";

const MyBookEvent = () => {
  const { eventId, sportId, marketid } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [betLockMarketStatus, setBetLockMarketStatus] = useState(true);
  const navigate = useNavigate();
  const [marketID, setmarketID] = useState(marketid);

  const eventDetail = useSelector((state) => state.bookEvent.eventDetailData);
  const fancySetting = useSelector(
    (state) => state.bookEvent.eventDetailfancyData
  );
  const fancyOddsFareRate = useSelector(
    (state) => state.bookEvent.BetfareRetsBMAndFancyData?.fancy
  ); //fancy
  const fancyprofitLossData = useSelector(
    (state) => state.bookEvent.fancyPostionData?.Result
  );

  const activeFancyList = useSelector(
    (state) => state.bookEvent.fancyActiveList
  ); //fancy

  const UnDeclareFancyList = useSelector(
    (state) => state.bookEvent.fancyUndeclareList
  ); //fancy

  // const eventDetail = evmarketList?.MarketList;
  // const fancySetting = evmarketList?.fancy;
  console.log("eventDetail", eventDetail);
  var fancyCount = 0;
  // const eventDetail =

  //  const filteredMainlist = fancyOddsFareRate && typeof fancyOddsFareRate[Symbol.iterator] === 'function'
  //     ? [...fancyOddsFareRate]
  //    : [];
  var filteredMainlist = [];
  var filterUndeclareList = [];
  if (fancySetting?.IsFancyActive) {
    if (fancyOddsFareRate)
      filteredMainlist = JSON.parse(JSON.stringify(fancyOddsFareRate));
    if (filteredMainlist) {
      filteredMainlist.forEach((item) => {
        item.min = fancySetting?.SessionMinStake;
        item.max = fancySetting?.SessionMaxStake;
      });
    }
    if (
      fancyOddsFareRate &&
      typeof fancyOddsFareRate[Symbol.iterator] === "function"
    ) {
      activeFancyList.forEach((activelistItem) => {
        const index = filteredMainlist.findIndex(
          (item) => item.SelectionId === activelistItem.SelectionId
        );

        // Check if SelectionId is not present in filteredMainlist
        if (
          !filteredMainlist.some(
            (item) => item.SelectionId === activelistItem.SelectionId
          )
        ) {
          // Push the entire activelistItem to filteredMainlist
          // filteredMainlist.push(activelistItem);
        } else {
          // Find the index of the matching item in filteredMainlist
          if (activelistItem.isCustome) {
            // Replace the values of the matching item in filteredMainlist
            filteredMainlist[index] = {
              ...filteredMainlist[index],
              LayPrice1: activelistItem.LayPrice1,
              LaySize1: activelistItem.LaySize1,
              BackPrice1: activelistItem.BackPrice1,
              BackSize1: activelistItem.BackSize1,
              GameStatus: activelistItem.GameStatus,
              max: activelistItem.max,
              min: activelistItem.min,
            };
          } else {
            if (!activelistItem.isActive) {
              const item = filteredMainlist.findIndex(
                (item) => item.SelectionId === activelistItem.SelectionId
              );
              filteredMainlist.splice(item, 1);
            } else {
              filteredMainlist[index] = {
                ...filteredMainlist[index],
                max: activelistItem.max,
                min: activelistItem.min,
              };
            }
          }
        }
      });
    } else {
      //console.log("fancyOddsFareRate is not defined or not iterable.");
    }
  } else {
    if (
      fancyOddsFareRate &&
      typeof fancyOddsFareRate[Symbol.iterator] === "function"
    ) {
      activeFancyList.forEach((activelistItem) => {
        // Check if SelectionId is not present in filteredMainlist

        if (
          !fancyOddsFareRate.some(
            (item) =>
              item.SelectionId === activelistItem.SelectionId &&
              activelistItem.isCustome
          )
        ) {
          // Push the entire activelistItem to filteredMainlist
          if (activelistItem.isCustome) {
            filteredMainlist.push(activelistItem);
          }
        }
        // else {
        if (activelistItem.isActive) {
          var item = fancyOddsFareRate.find(
            (item) => item.SelectionId === activelistItem.SelectionId
          );
          if (item) {
            Object.freeze(item);
            const objCopy = { ...item }; // 👈️ create copy
            objCopy.max = activelistItem.max;
            objCopy.min = activelistItem.min;
            filteredMainlist.push(objCopy);
          }
        }
        // Find the index of the matching item in filteredMainlist
        if (activelistItem.isCustome) {
          const index = filteredMainlist.findIndex(
            (item) => item.SelectionId === activelistItem.SelectionId
          );
          // Replace the values of the matching item in filteredMainlist
          debugger;
          filteredMainlist[index] = {
            ...filteredMainlist[index],
            LayPrice1: activelistItem.LayPrice1,
            LaySize1: activelistItem.LaySize1,
            BackPrice1: activelistItem.BackPrice1,
            BackSize1: activelistItem.BackSize1,
            GameStatus: activelistItem.GameStatus,
            max: activelistItem.max,
            min: activelistItem.min,
          };
        }
        //  }
      });
    } else {
      //console.log("fancyOddsFareRate is not defined or not iterable.");
    }
  }

  // undeclate List

  var filterUndeclareList = [];
  if (UnDeclareFancyList) {
    UnDeclareFancyList.forEach((unditem) => {
      if (
        !filteredMainlist.some(
          (item) => item.SelectionId === unditem.SelectionId
        )
      ) {
        filterUndeclareList.push(unditem);
      }
    });
  }

  useEffect(() => {
    startConnection();

    subscribeToEvent("GetActiveSession", (message) => {
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.Marketid === marketID) {
        store.dispatch(setFancyActiveList(data.fancyList));
      }
    });

    subscribeToEvent("GetActiveBM", (message) => {
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.Marketid === marketID + "B") {
        store.dispatch(setCustomeBMList(data.BMList));
      }
    });
    subscribeToEvent("BetPlacedMessage", (message) => {
      //debugger;
      var master = JSON.stringify(eval("(" + message + ")"));
      var data = JSON.parse(master);
      if (data.EventId === eventId) {
        var marketid = data.MarketId;
        var mktype = data.MarketType;
        var BMID = marketid.replace("B", "");
        actions.GetMatchedBetList(eventId);
        if (mktype === "FANCY") {
          actions.GetFancyPostionList(BMID);
          //  call getFancyPosition api
        } else {
          actions.GetProfitLossOnRunnersList(BMID, sportId);
        }
      }
    });

    // Don't forget to stop the connection when the component is unmounted
    return () => {
      stopConnection();
    };
  }, [marketID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const delayInSeconds = 2;

  const fetchRetsBMAndFancyRepeat = async () => {
    if (eventDetail && eventDetail?.length > 0 && eventDetail !== undefined) {
      const marketids = eventDetail.map((market) =>
        market?.MarketId.replace(/[TB]+$/, "")
      );
      const uniqueMarketids = new Set(marketids);
      const queryString = [...uniqueMarketids].join(",");
      try {
        actions.getBetfareRetsMarketAndOther(queryString);
        if (marketID !== undefined) {
          var BMID = marketID.replace("B", "");
          if (sportId === "4") {
            actions.getBetfareRetsBMAndFancy(BMID);
            actions.getBetfareTossOdds(BMID);
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchRetsBMAndFancyRepeat();
    const intervalId = setInterval(() => {
      fetchRetsBMAndFancyRepeat();
    }, delayInSeconds * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventDetail, sportId]);

  // fancy Book
  useEffect(() => {
    actions.GetFancyPostionList(marketID);
    const intervalId = setInterval(() => {
      actions.GetFancyPostionList(marketID);
    }, delayInSeconds * 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [marketID]);

  useEffect(() => {
    actions.GetProfitLossOnRunnersEventList(eventId, sportId);
    const intervalId = setInterval(() => {
      actions.GetMatchedBetList(eventId);
      actions.GetProfitLossOnRunnersEventList(eventId, sportId);
    }, delayInSeconds * 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventId]);

  useEffect(() => {
    actions.getEventDetail(eventId);
    actions.GetMatchedBetList(eventId);
    actions.GetProfitLossOnRunnersEventList(eventId, sportId);
  }, [eventId]);

  useEffect(() => {
    if (eventDetail && eventDetail.length > 0 && eventDetail !== undefined) {
      if (marketID !== undefined) {
        var MID = marketID.replace("B", "");
        setmarketID(MID);
        actions.getactiveFancyList(MID);
        actions.getCustomeBMList(MID);
      }
    }
  }, [eventDetail]);

  useEffect(() => {
    actions.getactiveFancyList(marketID);
    actions.getCustomeBMList(marketID);
  }, [marketID]);

  const betEventStatusHandler = async (MarketData) => {
    if (handleConfirmation() && MarketData?.EvBetStatus !== undefined) {
      const status = MarketData.EvBetStatus ? 0 : 1;
      await actions.matchControlAction(eventId, status, 2, "0");
      actions.getEventDetail(eventId);
    }
  };

  const betLockMarket = (MarketData, marketid) => {
    if (handleConfirmation()) {
      const updatedEventDetail = [...eventDetail];
      const marketIndex = updatedEventDetail.findIndex(
        (market) => market.MarketId === marketid
      );

      if (marketIndex !== -1) {
        const updatedMarket = {
          ...updatedEventDetail[marketIndex],
          Liquidity: MarketData?.Liquidity == 0 ? 1 : 0,
        };
        // Replace the market with the updated one
        updatedEventDetail[marketIndex] = updatedMarket;
      }

      // Now you can dispatch the updated eventDetail using Redux.
      store.dispatch(setEventDetail(updatedEventDetail));
      actions.betLockMarketAction(marketid);
    }
  };

  const userBookHandler = (MarketName, marketId) => {
    store.dispatch(setUserBookName(MarketName));
    let data = {
      marketId: marketId,
      uid: encodeURIComponent(
        JSON.parse(sessionStorage.getItem("loginUserDetails"))?.uid
      ),
    };
    actions.getUserBook(data);
  };

  const betBMEditHandler = (marketId) => {
    actions.GetCustomeMarket(marketId);
    navigate(`/bmedit/${marketId}`);
  };

  const setUpdateFancyStatusHandler = (SelectionId) => {
    // const bm = eventDetail?.find((obj) => obj.MarketType === "Bookmaker");
    if (marketID) {
      const MID = marketID.replace("B", "");
      const data = {
        marketId: MID,
        fancyId: SelectionId,
      };
      actions.updateFancyStauts(data);
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar navTitle={"All Markets"} />
            <div className="container-fluid">
              <div className="d-flex flex-wrap" style={{ marginTop: "10px" }}>
                <div className="col-12 col-md-8 scrollable-content">
                  <div className="scrollable-content-inner">
                    {isMobile && <LiveTV />}{" "}
                    {/* Show LiveTV only in mobile view */}
                    <Bookmaker
                      eventDetail={eventDetail}
                      betLockMarket={betLockMarket}
                      betLockMarketStatus={betLockMarketStatus}
                      userBookHandler={userBookHandler}
                      betBMEditHandler={betBMEditHandler}
                      betEventStatusHandler={betEventStatusHandler}
                      EventId={eventId}
                      sportsId={sportId}
                    />
                    <FancyBets
                      fancyprofitLossData={fancyprofitLossData}
                      fancyOddsFareRate={filteredMainlist}
                      setUpdateFancyStatusHandler={setUpdateFancyStatusHandler}
                      marketID={marketID}
                      eventId={eventId}
                      EvBetStatus={fancySetting?.IsFancyActive}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="fixed-height-content">
                    {!isMobile && <LiveTV />}{" "}
                    {/* Show LiveTV only in desktop view */}
                    <AllBetList eventId={eventId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <LogoutModel />
      <BookModal />
      <FancyBetListModal />
      <FancyPleaceBet
        MarketId={marketID}
        EventId={eventId}
        sportsId={sportId}
      />
      {/* <MarketPleaceBet
        MarketId={marketID}
        EventId={eventId}
        sportsId={sportId}
      /> */}
      <FancyCurrentPostionModal />
    </>
  );
};

export default MyBookEvent;
