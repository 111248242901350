import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import actions from "../../redux/actions";
import { notifyError, notifySuccess } from "../ToastContext";

const WithdrawAndDepositModal = ({ data, handleClientList }) => {
  const parentAmount = useSelector(
    (state) => state.client?.amount?.ParentBalance
  );
  const [state, setState] = useState({});

  const [newChip, setNewChip] = useState({
    parentBalance: 0,
    amount: 0,
  });

  useEffect(() => {
    setState({
      uid: data?.uid,
      type: data?.type,
      trntype: data?.trntype,
      desc: "",
      amount: 0,
    });
    setNewChip({
      parentBalance: 0,
      amount: 0,
    });
  }, [data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { response } = await actions.saveAmountAction(state);
      if (response?.Status) {
        handleClientList();
        notifySuccess(response?.Result);
      } else {
        notifyError(response?.Result);
      }
    } catch (error) {
      notifyError(error);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="WithdrawAndDepositModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                {data?.title}
              </h6>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row col-12 mb-3 mb-2">
                <label className="col-4"> {data?.title?.split("(")[0]}:</label>
                <input
                  type="number"
                  className="form-control col-6 "
                  name="amount"
                  value={state?.amount}
                  onChange={(e) => {
                    setState({ ...state, amount: e.target.value });
                    console.log(typeof e.target.value);
                    setNewChip({
                      ...newChip,
                      parentBalance:
                        e.target.value?.trim() === ""
                          ? 0
                          : data?.trntype === "D"
                          ? parseInt(parentAmount) - parseInt(e.target.value)
                          : parseInt(parentAmount) + parseInt(e.target.value),
                      amount:
                        e.target.value?.trim() === ""
                          ? 0
                          : data?.trntype === "D"
                          ? parseInt(data?.amount) + parseInt(e.target.value)
                          : parseInt(data?.amount) - parseInt(e.target.value),
                    });
                  }}
                />
              </div>

              <table
                className="table table-bordered dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                role="grid"
                aria-describedby="dataTable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <b>Distibutor ({data?.parentName})</b>
                    </th>
                    <th>
                      <b>Client ({data?.clientName})</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Current Chips:</th>
                    <th>
                      <b>{parentAmount}</b>
                    </th>
                    <th>
                      <b>{data?.amount}</b>
                    </th>
                  </tr>
                  <tr>
                    <th>New Chips:</th>
                    <th>
                      <b>{newChip?.parentBalance}</b>
                    </th>
                    <th>
                      <b>{newChip?.amount}</b>
                    </th>
                  </tr>
                  <tr>
                    <th>Remarks:</th>
                    <th colSpan="2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        value={state?.desc}
                        onChange={(e) =>
                          setState({ ...state, desc: e.target.value })
                        }
                      />
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                className="modal-submit-btn"
                type="button"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="modal-cancel-btn"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawAndDepositModal;
