import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';

const MarketSettingModel = ({ marketSettingModalData }) => {

    const [data, setData] = useState("");

    useEffect(() => {
        setData(marketSettingModalData)
    }, [marketSettingModalData])

    const handleSubmitMarketSetting = () => {
        actions.postMarketSettingModelAction(data)
    }

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value })
    }



    return (
        <div>
            <div
                className="modal fade"
                id="marketSettingModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Market Setting
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox1">Adv. Bet Delay</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="AdvBetDelay"
                                                value={data?.AdvBetDelay}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox1">Max Profit</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="MaxProfit"
                                                value={data?.MaxProfit}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox2">Max Odds</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="MaxOdds"
                                                value={data?.MaxOdds}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox1">Odds Min Stake</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="MinStake"
                                                value={data?.MinStake}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox2">Odds Max Stake</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="MaxStake"
                                                value={data?.MaxStake}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox1">Session Min Stake</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="SessionMinStake"
                                                value={data?.SessionMinStake}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox2">Session Max Stake</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="SessionMaxStake"
                                                value={data?.SessionMaxStake}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox1">Bet Delay</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="MBetDelay"
                                                value={data?.MBetDelay}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox2">Session Delay</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="FBetDelay"
                                                value={data?.FBetDelay}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="textbox2">News/Message</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                name="Rules"
                                                value={data?.Rules}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                onClick={handleSubmitMarketSetting}
                            >
                                Submit
                            </button>
                            <a className="modal-cancel-btn" data-dismiss="modal">
                                No
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MarketSettingModel
