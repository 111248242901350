import React, { useState, useEffect } from "react";
import actions from "../../redux/actions";
import * as GrIcons from "react-icons/gr";
import MarketSettingModel from "../Models/MarketSettingModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isSuperAdmin, isRoleExits } from "../../utils";

const EventTable = ({ eventList }) => {
  const marketSettingModalData = useSelector(
    (state) => state.match?.MarketSetting?.Result
  );
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setData(eventList);
  }, [eventList]);

  const getEventList = (eventid) => {
    actions.getEventList(eventid);
  };

  const handleLockMarketToggle = async (marketID, eventId) => {
    const updatedData = data.map((obj) => {
      if (obj.MarketId === marketID) {
        return { ...obj, MarketLiquidity: !obj.MarketLiquidity };
      }
      return obj;
    });

    setData(updatedData);
    await actions.betLockMarketAction(marketID);

    getEventList(eventId);
  };

  const handleMatchControl = async (marketID, eventID, status, marketTIme) => {
    const updatedData = data.map((obj) => {
      if (obj.MarketId === marketID) {
        return { ...obj, InPlay: status };
      }
      return obj;
    });

    setData(updatedData);
    await actions.matchControlAction(marketID, status, 0, marketTIme);
    getEventList(eventID);
  };

  const handleMarketStatus = async (marketID, status, eventID) => {
    const updatedData = data.map((obj) => {
      if (obj.MarketId === marketID) {
        return { ...obj, HideStatus: !status };
      }
      return obj;
    });

    setData(updatedData);
    await actions.openCloseMarketAction(eventID, status);
    getEventList(eventID);
  };

  return (
    <div>
      <MarketSettingModel marketSettingModalData={marketSettingModalData} />
      <table
        className="table table-bordered dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
        role="grid"
        aria-describedby="dataTable_info"
        style={{ width: "100%" }}
      >
        <thead>
          <tr role="row">
            <th>Sr No. x</th>
            <th>Id</th>
            <th>Event Name</th>
            <th>Match Type</th>
            <th>Market Id</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((event, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}
              >
                <td>{index + 1}</td>
                <td>{event?.Id}</td>
                <td>{event?.MatchName}</td>
                <td>{event?.MarketType}</td>
                <td>{event?.MarketId}</td>

                <td>
                  <ul className="d-flex list-unstyled">
                    <li>
                      <button
                        className="custom-grey-btn"
                        onClick={() =>
                          navigate(
                            `/eventbets/${event?.Eventid}/${event?.MarketId}`
                          )
                        }
                      >
                        Bet List
                      </button>
                      <button
                        className="custom-grey-btn"
                        onClick={() =>
                          navigate(
                            `/my-book-event/${event?.MarketId}/${event?.Eventid}/${event?.RunnerName[0]?.SportsId}`,
                            {
                              state: {
                                seriesName: event?.SeriesName,
                                matchName: event?.MatchName,
                              },
                            }
                          )
                        }
                      >
                        MB
                      </button>
                    </li>
                  </ul>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTable;
