import { savePLByUser,savedeleteBets,saveEventname, saveAccountStatement, saveChipSummary, savePLByUserSpots, saveOverAllPandL, saveProfitLossEvent, saveProfitLossSports, saveSettlementSummary, saveUserLog, saveMarketSharingDetails, setSharingDetials } from "../slices/reportSlice";
import ReportService from "../../services/reportService";
import store from "../store";
import { notifyError } from "../../components/ToastContext";

export const profitLossByUserAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.profitLossByUserService(data).then((res) => {
            store.dispatch(savePLByUser(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const getPlByUserSpotsAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.getPlByUserSpotsService(data).then((res) => {
            store.dispatch(savePLByUserSpots(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const accountStatementAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.accountStatementService(data).then((res) => {
            store.dispatch(saveAccountStatement(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const marketSharingDetailsAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.marketSharingDetailsService(data).then((res) => {
            store.dispatch(saveMarketSharingDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const overAllPandLAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.overAllPandLService(data).then((res) => {
            store.dispatch(saveOverAllPandL(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const chipSummaryAction = (userId) => {
    return new Promise((resolve, rejact) => {
        ReportService.chipSummaryService(userId).then((res) => {
            store.dispatch(saveChipSummary(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}

export const settlementAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.settlementService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const settlementSummaryAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.settlementSummaryService(data).then((res) => {
            store.dispatch(saveSettlementSummary(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}


export const userLogAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.userLogService(data).then((res) => {
            store.dispatch(saveUserLog(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const getProfitLossSportsAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.getProfitLossSportsService(data).then((res) => {
            store.dispatch(saveProfitLossSports(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const getProfitLossEventAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.getProfitLossEventService(data).then((res) => {
            store.dispatch(saveProfitLossEvent(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            // rejact(error)
        })
    })
}


export function GetSharingDetails(data) {
    return new Promise((resolve, rejact) => {
        ReportService.GetSharingDetailsService(data).then((res) => {
            //console.log("res.data",res.data);
            store.dispatch(setSharingDetials(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

 
export const deleteBetListAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.deleteBetLisService(data).then((res) => {
            console.log("deleteBetListAction",res.data);
            store.dispatch(savedeleteBets(res.data))
 
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const superBetListAction = (data) => {
    return new Promise((resolve, rejact) => {
        ReportService.superBetLisService(data).then((res) => {
            console.log("deleteBetListAction",res.data);
            store.dispatch(savedeleteBets(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            notifyError(error.title)
        })
    })
}
export const eventNameListAction = (data) => {
    return new Promise((resolve, rejact) => {
      
        ReportService.eventNameLisService(data).then((res) => {
            store.dispatch(saveEventname(res.data))
 
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export const CasinoBetListAction = () => {
    return new Promise((resolve, rejact) => {
        ReportService.casinoBetLisService().then((res) => {
            console.log("deleteBetListAction",res.data);
            store.dispatch(savedeleteBets(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                localStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            notifyError(error.title)
        })
    })
}