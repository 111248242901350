import React, { useState } from "react";

const ChipStatementTable = () => {

  const [fromDate, setFromDate] = useState('2023-07-02');
  const [toDate, setToDate] = useState('2023-08-01');

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSubmit = () => {
    // handle the form submission with selected dates
  };

  return (
    <div className="container-fluid">
      {/* Page Heading */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
      </div>
      {/* Start List of Account statement DataTable */}
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              {/* Start select date view*/}
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">From:</label>
                    <input
                      type="date"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">To:</label>
                    <input
                      type="date"
                      value={toDate}
                      onChange={handleToDateChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="email">&nbsp;</label>
                    <div className="form-actions no-margin-bottom">
                      <button onClick={handleSubmit} className="btn btn-primary" style={{ marginLeft: 15 }}>
                        Submit
                      </button>
                    </div>
                    <div className="clearfix" />
                  </div>
                </div>
                <div className="col-md-3 col-sm-6"></div>
              </div>
              <hr />
              {/* Start List of Table Account statement Component*/}
              <div className="row">
                <div className="col-sm-12">
                  <table className="table table-bordered dataTable" >
                    <thead >
                      <tr className="bg-gray-200">
                        <th className="col-date">Date</th>
                        <th className="col-date">Ref ID</th>
                        <th className="col-debit">User</th>
                        <th className="col-debit">UserType</th>
                        <th className="col-debit">Type</th>
                        <th className="col-credit">CR</th>
                        <th className="col-credit">DR</th>
                        <th className="col-balance">Balance</th>
                      </tr>
                      <tr>
                        <td><input type="text" placeholder="Date." style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="Ref ID" style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="User" style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="UserType" style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="Type" style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="CR" style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="DR" style={{ width: '100%' }} /></td>
                        <td><input type="text" placeholder="Balance" style={{ width: '100%' }} /></td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="odd bg-danger">
                        <td>
                          <span className="text-white text-white text-no-wrap">01/08/2023 3:00 AM</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">113299</span>
                        </td>

                        <td>
                          <span className="text-white">SuperAdmin[super]</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap ">sa</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">debit</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">0.00</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">-2557.00</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">-2557.00</span>
                        </td>
                      </tr>
                      <tr className="even bg-success">
                        <td>
                          <span className="text-white text-no-wrap">01/08/2023 3:00 AM</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">113299</span>
                        </td>

                        <td>
                          <span className="text-white">SuperAdmin[super]</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap ">sa</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">credit</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">0.00</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">-2557.00</span>
                        </td>
                        <td>
                          <span className="text-white text-no-wrap">-2557.00</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ChipStatementTable;
