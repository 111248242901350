import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  style: "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion",
};

const styleSlice = createSlice({
  name: 'style',
  initialState,
  reducers: {
    toggleStyle: (state) => {
      state.style = state.style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        ? "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
        : "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion";
    },
    toggleStyleMob: (state) => {
      state.style = state.style === "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        ? "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggledMob"
        : "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion";
    },
  },
});

export const { toggleStyle,toggleStyleMob } = styleSlice.actions;
export default styleSlice.reducer;
