import React, { useEffect, useState } from 'react'
import SuperBetsTable from './SuperBetsTable';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import moment from 'moment';
import actions from '../../../redux/actions';
import ScreenLoader from '../../../components/ScreenLoader';
import { useSelector } from 'react-redux';


const SuperBets = () => {
   
  const eventNameData = useSelector((state) => state.report?.saveEventname?.Result);
  const plBetList = useSelector((state) => state.report?.savedeleteBets?.Result);
 console.log("plBetList",plBetList);
  const [loader, setLoader] = useState(false);
  const [eventid, setEventid] = useState('');

  useEffect(() => {
    console.log(eventNameData);
 }, [eventNameData]);
  useEffect(() => {
     actions.eventNameListAction(1)
    setLoader(false)
  }, []);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    let data = {
      eventid: eventid, 
   
    }
    await actions.superBetListAction(data)
    setLoader(false)
  };

  return (
    <NavbarSidebarWrapper>
      {loader ? <ScreenLoader /> :
        <div className="card shadow mb-4">
          <div className="">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                <label >Select Event</label>
                    <select
                      value={eventid}
                      onChange={(e) => setEventid(e.target.value)}
                      className="form-control form-control-user">
                      <option value="0">All</option>
                           {eventNameData  && Array.isArray(eventNameData) &&
                             eventNameData?.map((item, index) => (
                           <option value={item?.EventId} key={index}>
                           {item?.MarketName}  {moment(item?.MarketTime).format('DD-MM-YYYY h:mm:ss A')} 
                      </option>
                    ))}
                    </select>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
              <div className="form-group">
                  <label htmlFor="email">&nbsp;</label>
                  <div className="form-actions no-margin-bottom load-btn-container">
                    <button onClick={handleSubmit} style={{ marginLeft: 15 }}>
                      Load
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                
              </div>
              <div className="col-md-3 col-sm-6">
               
              </div>
              <div className="col-md-3 col-sm-6"></div>
            </div>
            <div className="table-responsive">
              <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <SuperBetsTable plBetList={plBetList}/>
              </div>
            </div>
          </div>
        </div>
      }
    </NavbarSidebarWrapper>
  )
}

export default SuperBets;
