import React, { useState, useEffect } from "react";
import * as BiIcons from "react-icons/bi";
import actions from "../../redux/actions";
import { isSuperAdmin } from "../../utils";
import { notifyError, notifySuccess } from "../ToastContext";
import moment from "moment";

const TableItemComponent = ({
  marketList,
  marketIdsList,
  showAction,
  handleAction,
}) => {
  const [data, setData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    if (marketList && Array.isArray(marketList) && marketIdsList) {
      setData(
        marketList?.map((item) => ({
          ...item,
          isChecked:
            marketIdsList?.length > 0
              ? marketIdsList?.some((ids) => ids.MarketId === item.MarketId)
              : false,
        }))
      );
    }
  }, [marketList, marketIdsList]);

  const handlePostApi = async (updatedData) => {
    let filteredData = [];
    let filteredData2 = [];
    if (marketIdsList && marketIdsList?.length > 0) {
      filteredData = updatedData?.filter((item) => {
        return !(
          item.isChecked &&
          marketIdsList.some((ids) => ids.MarketId === item.MarketId)
        );
      });
      filteredData2 = filteredData?.filter(
        (item) =>
          marketIdsList.some((ids) => ids.MarketId === item.MarketId) ||
          item.isChecked
      );
    } else {
      filteredData2 = updatedData?.filter((item) => item.isChecked);
    }
    // let postData = filteredData2.map((item) => ({
    //     SportsId: item.SportsId,
    //     MarketId: item.MarketId,
    //     EventId: item.EventId,
    //     MarketType: item.MarketName
    // }))
    let postData = filteredData2?.map((item) => ({
      SportsId: item.SportsId,
      SeriesId: item?.CompetitionId,
      SeriesName: item?.CompetitionName,
      MarketId: item?.MarketId,
      EventId: item?.EventId,
      MarketName: item?.EventName,
      MarketTime: item?.EventDate,
      MarketType: item?.MarketName,
      Runners: item?.Runners?.map((runner) => ({
        RunnerName: runner.RunnerName,
        SelectionId: String(runner.SelectionId),
      })),
    }));

    // let postData = filteredData2;

    if (postData.length === 1) {
      postData = postData[0];
    }
    if (postData.length === 0) {
      postData = {};
    }

    let res = await actions.postMarketModelAction(postData);
    if (res.data.Status) {
      notifySuccess(res.data.Result);
      actions.getMarketIdsList(marketList[0]?.SportsId);
      setCheckedAll((prevCheckedAll) => !prevCheckedAll);
    } else {
      notifyError(res.data.Result);
    }


  };

  const handleCheckAll = async () => {
    const updatedData = data?.map((item) => ({
      ...item,
      isChecked: !checkedAll,
    }));
    setData(updatedData);
    handlePostApi(updatedData);
  };

  const handleCheckRow = async (index) => {
    const updatedData = data.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });

    setData(updatedData);
    setCheckedAll(updatedData.every((item) => item.isChecked));
    handlePostApi(updatedData);
  };

  return (
    <div>
      <table
        className="table table-bordered dataTable"
        id="dataTable"
        width="100%"
        cellSpacing={0}
        role="grid"
        aria-describedby="dataTable_info"
        style={{ width: "100%" }}
      >
        <thead>
          <tr role="row">
            <th>
              <input
                type="checkbox"
                className="form-control"
                checked={checkedAll}
                onChange={handleCheckAll}
              />
            </th>
            {showAction && <th>Action</th>}
            <th>MarketId</th>
            <th>MarketName</th>
            <th>SportsId</th>
            <th>SportsName</th>
            <th>CompetitionId</th>
            <th>CompetitionName</th>
            <th>EventId</th>
            <th>EventName</th>
            <th>EventDate</th>
            <th>Runners</th>
            <th>RunnersName</th>
          </tr>
        </thead>
        <tbody>
          {marketList && Array.isArray(marketList) && marketList.map((match, index) => (
            <>
              {(
                (match?.Runners && match?.Runners?.length > 0 && !match?.Runners[1]?.RunnerName.includes("/") && !match?.Runners[0]?.RunnerName.includes("/")) ||
                (match?.runners && match?.runners?.length > 0 && !match?.runners[1]?.RunnerName.includes("/") && !match?.runners[0]?.RunnerName.includes("/"))
              ) && (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "even hover-row" : "odd hover-row"}
                  >
                    <td>
                      <input
                        type="checkbox"
                        className="form-control"
                        checked={data[index]?.isChecked}
                        onChange={() => handleCheckRow(index)}
                      />
                    </td>
                    {showAction && (
                      <td
                        onClick={() =>
                          handleAction(match?.SportsId, match?.EventId)
                        }
                      >
                        <BiIcons.BiEdit
                          style={{ fontSize: "18px", opacity: "0.6" }}
                        />
                      </td>
                    )}
                    <td>{match?.MarketId}</td>
                    <td>{match?.MarketName}</td>
                    <td>{match?.SportsId}</td>
                    <td>{match?.SportsName}</td>
                    <td>{match?.CompetitionId}</td>
                    <td>{match?.CompetitionName}</td>
                    <td>{match?.EventId}</td>
                    <td>{match?.EventName}</td>
                    <td>{moment(match?.EventDate).format("DD-MM-YYYY h:mm A")}</td>
                    <td>{match?.Runnercount}</td>
                    <td>
                      {match?.Runners && match?.Runners?.length > 0 && (
                        <>
                          <span>
                            {match?.Runners[0]?.RunnerName.charAt(0).toUpperCase() + match?.Runners[0]?.RunnerName.slice(1).toLowerCase()} |
                            {match?.Runners[1]?.RunnerName.charAt(0).toUpperCase() + match?.Runners[1]?.RunnerName.slice(1).toLowerCase()}
                          </span>
                        </>
                      )}
                      {match?.runners && match?.runners?.length > 0 && (
                        <>
                          <span>
                            {match?.runners[0]?.RunnerName.charAt(0).toUpperCase() + match?.runners[0]?.RunnerName.slice(1).toLowerCase()} |
                            {match?.runners[1]?.RunnerName.charAt(0).toUpperCase() + match?.runners[1]?.RunnerName.slice(1).toLowerCase()}
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                )}
            </>
          ))}
        </tbody>

      </table>
    </div>
  );
};

export default TableItemComponent;
