import React, { useEffect, useState } from 'react'
import UserLogTable from './UserLogTable';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import moment from 'moment';
import actions from '../../../redux/actions';
import ScreenLoader from '../../../components/ScreenLoader';
import { useSelector } from 'react-redux';


const UserLog = () => {

  const userLogData = useSelector((state) => state.report?.saveUserLog?.Result);
  let totalrow = useSelector((state) => state.report?.saveUserLog?.Count);
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [loader, setLoader] = useState(false);
  const [uid, setUid] = useState('0');
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [userInput, setUserInput] = useState('');

  let userlist = useSelector((state) => state.auth.userDataList?.Result);

  useEffect(() => {
    if (userlist && Array.isArray(userlist)) {
      setUserSuggestions(userlist);
    }
  }, [userlist]);

  const handleUserInputChange = (e) => {
    const { value } = e.target;
    setUserInput(value);
  }

  useEffect(() => {
    if (userInput.trim() !== '') {
      actions.getUserList(userInput);
    }
  }, [userInput]);

  const handleUserSelection = (user) => {
    setUid(user.uid)
    setUserInput(user.username);
    setUserSuggestions([]);
  }

  const handleUserLog = async () => {
    setLoader(true)
    let data = {
      skipRec: 0,
      psize: 10,
      uid: uid,
      startDate: fromDate,
      endDate: toDate
    }
    await actions.userLogAction(data)
    setLoader(false)
  }

  useEffect(() => {
    handleUserLog()
  }, [])

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    let data = {
      skipRec: 0,
      psize: 10,
      uid: uid,
      startDate: fromDate,
      endDate: toDate
    }
    await actions.userLogAction(data)
    setLoader(false)
  };

  return (
    <NavbarSidebarWrapper>
      {loader ? <ScreenLoader /> :
        <div className="card shadow mb-4">
          <div className="">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">From:</label>
                  <input
                    type="date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">To:</label>
                  <input
                    type="date"
                    value={toDate}
                    onChange={handleToDateChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">User:</label>
                  <input
                    type="text"
                    value={userInput}
                    onChange={handleUserInputChange}
                    className="form-control"
                  />
                  {userSuggestions?.length > 0 && (
                    <ul className="suggestion-list">
                      {userSuggestions?.map((user, index) => (
                        <li key={index} onClick={() => handleUserSelection(user)}>
                          {user?.username}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                  <label htmlFor="email">&nbsp;</label>
                  <div className="form-actions no-margin-bottom load-btn-container">
                    <button onClick={handleSubmit} style={{ marginLeft: 15 }}>
                      Load
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="col-md-3 col-sm-6"></div>
            </div>
            <div className="table-responsive">
              <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <UserLogTable data={userLogData} fromDate={fromDate} toDate={toDate} totalrow={totalrow} uid={uid} />
              </div>
            </div>
          </div>
        </div>
      }
    </NavbarSidebarWrapper>
  )
}

export default UserLog;
