import React, { useEffect, useState } from "react";
import * as BsIcons from 'react-icons/bs';
import { useNavigate } from "react-router-dom";

const ChipSummaryContent = ({ chipSummaryData, handleUserId, handleBackBtn, userId }) => {
  const Navigate = useNavigate()
  const [chipSummary, setChipSummary] = useState([]);
  const [totalBalance, setTotalBalance] = useState({
    leftTotal: 0.00,
    rightTotal: 0.00
  })
  useEffect(() => {
    if (chipSummaryData && Array.isArray(chipSummaryData)) {
      setChipSummary(chipSummaryData)
      const positiveData = chipSummaryData.filter(obj => obj.MyPL >= 0);
      const negativeData = chipSummaryData.filter(obj => obj.MyPL < 0);
      const positiveSum = positiveData.reduce((acc, obj) => acc + obj.MyPL, 0);
      const negativeSum = negativeData.reduce((acc, obj) => acc + obj.MyPL, 0);
      setTotalBalance({
        leftTotal: positiveSum,
        rightTotal: negativeSum
      })
    }
  }, [chipSummaryData])

  return (
    <div>
      <div className='content-header'>Chip Summary</div>
      {userId !== 1 && <button className='back-btn mb-1' onClick={() => handleBackBtn()}> <BsIcons.BsArrowReturnLeft /> Back</button>}
      <div className="card shadow mb-4">
        <div className="card-body ">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="table-responsive">
                <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                  <div className="">
                    <div className="">
                      <table className="table table-bordered dataTable" >
                        <thead >
                          <tr className="bg-success">
                            <th className="text-white col-desc">UserName</th>
                            <th className="text-white col-balance">Amount</th>
                            <th className="text-white col-balance">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {chipSummaryData && Array.isArray(chipSummaryData) && chipSummaryData?.filter((item) => item.MyPL >= 0).map((item, index) => (
                            <tr className="odd" key={index}>
                              <td>
                                <span className="text-no-wrap">{item?.UserName}</span>
                              </td>
                              <td>
                                <span className="text-no-wrap green-text">{item?.MyPL}</span>
                              </td>
                              <td className="text-center">
                                <button className="custom-grey-btn" onClick={() => Navigate(`/settlement-summmary/${item?.UserId}`)}>History</button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr style={{ backgroundColor: "#e3e6f0" }}>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{totalBalance?.leftTotal}</b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="table-responsive">
                <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                  <div className="">
                    <div className="">
                      <table className="table table-bordered dataTable" >
                        <thead >
                          <tr className="bg-danger">
                            <th className="text-white col-desc">UserName</th>
                            <th className="text-white col-balance">Amount</th>
                            <th className="text-white col-balance">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {chipSummaryData && Array.isArray(chipSummaryData) && chipSummaryData.filter((item) => item.MyPL < 0)?.map((item, index) => (
                            <>
                              <tr className="odd" key={index + 1}>
                                <td>
                                  <span className="text-no-wrap" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleUserId(item?.UserId)}>{item?.UserName}</span>
                                </td>
                                <td>
                                  <span className="text-no-wrap red-text">{item?.MyPL}</span>
                                </td>
                                <td className="text-center d-flex">
                                  <button className="custom-grey-btn" onClick={() => Navigate(`/settlement-summmary/${item?.UserId}`)}>History</button>
                                </td>
                              </tr>
                              {userId === 1 && <tr className="odd" key={index}>
                                <td>
                                  <span className="text-no-wrap">Parent PL</span>
                                </td>
                                <td>
                                  <span className="text-no-wrap red-text">{item?.MyParentPL}</span>
                                </td>
                                <td className="text-center d-flex">
                                </td>
                              </tr>}
                            </>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr style={{ backgroundColor: "#e3e6f0" }}>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{totalBalance?.rightTotal}</b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ChipSummaryContent;
