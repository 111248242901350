import React, { useState } from 'react'
import actions from '../../redux/actions';
import { notifySuccess, notifyError } from '../ToastContext';
const MyChangePasswordModal = () => {
    const [data, setData] = useState({
        oldPwd: '',
        newPwd: ''
    });

    const handleChangePassword = async () => {
        let res = await actions.changePwd(data)
        if (res?.data?.Status)
            notifySuccess(res?.data?.Result);
        else
            notifyError(res?.data?.Result);
        setData({
            oldPwd: '',
            newPwd: ''
        })
    }
    return (
        <div>
            <div
                className="modal fade"
                id="myChangePasswordModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Password Reset
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className=" col-sm-12 mb-3 mb-sm-0">
                                    <input
                                        type="password"
                                        className='form-control'
                                        id="oldPwd"
                                        name='oldPwd'
                                        placeholder="Old Password"
                                        value={data.oldPwd}
                                        onChange={(e) => setData({ ...data, oldPwd: e.target.value })}
                                    />
                                </div>
                                <div className=" col-sm-12 mb-3 mb-sm-0">
                                    <input
                                        type="password"
                                        className='form-control'
                                        id="newPwd"
                                        name='newPwd'
                                        placeholder="New Password"
                                        value={data.newPwd}
                                        onChange={(e) => setData({ ...data, newPwd: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                onClick={handleChangePassword}
                            >
                                Reset
                            </button>
                            <button
                                className="modal-cancel-btn"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyChangePasswordModal
