import React from 'react'
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import LogoutModel from '../../components/Models/LogoutModel';
import BlockSportsTable from './BlockSportsTable';


const BlockSports = () => {
  return (
    <div>
      {/* Page Wrapper */}
      <div id="wrapper">
        {/* Sidebar */}
        <Sidebar />
        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* Main Content */}
          <div id="content">
            {/* Topbar */}
            <Navbar navTitle={"Block Sports"}/>
            {/* Begin Page Content */}
            <BlockSportsTable/>
            {/* /.container-fluid */}
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
      {/* Logout Modal*/}
      <LogoutModel />
    </div>
  )
}

export default BlockSports;
