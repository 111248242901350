import React, { useEffect, useState } from 'react';
import BlockEvents from '../../screen/GameControl/BlockEvents';
import { useNavigate } from 'react-router-dom';
import actions from '../../redux/actions';
import { useSelector } from 'react-redux';
import store from '../../redux/store';
import { setBlockSportData } from '../../redux/slices/bookEventSlice';
import { notifyError } from '../ToastContext';

const TabsComponent = () => {

    const data = useSelector((state) => state.bookEvent.blockSportData);
    const [activeTab, setActiveTab] = useState(0); // State track the active tab index

    useEffect(() => {
        // Load data for the first tab 
        if (data.length > 0) {
            handleBlockEventList(data[0].SportsId);
        }
    }, [data]);

    const handleBlockEventList = (SportsId) => {
        actions.GetBlockEventList(SportsId);
    };

    const handleCheckboxChange = async (sportId, checked) => {

        const updatedData = data.map((sport) =>
            sport.SportsId === sportId ? { ...sport, status: checked } : sport
        );

        store.dispatch(setBlockSportData(updatedData))

        const blockData = {
            "SportsId": sportId,
            "Status": checked

        }
        // console.log(`Checkbox for sportId ${sportId} is now ${checked}`);
        try {
            await actions.PostBlockSport(blockData);
        } catch (error) {
            notifyError(error);
        }
    };

    if (!data) {
        return null; // return a loading state
    }

    return (
        <div className="mt-4">
            <div className="card shadow">
                <div className="card-body p-0">
                    <label className='content-header' style={{ borderRadius: '0.35rem 0 0' }}>Sports Type:</label>
                    <div className="row col-sm-12 m-1">
                        {data.map((sport, index) => (

                            <div className="form-check mr-4 custom-checkbox" key={index}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="status"
                                    id={sport.SportsId}
                                    checked={sport.status}
                                    onChange={(e) => handleCheckboxChange(sport.SportsId, e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor={sport.SportsId}>
                                    {sport.Sportsname}
                                </label>
                            </div>
                        ))}
                        {/* <div className="form-check mx-4 custom-checkbox">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="status"
                                id="Suspended"
                            />
                            <label className="form-check-label" htmlFor="Suspended">
                                Soccer
                            </label>
                        </div>

                        <div className="form-check custom-checkbox">
                            <input
                                className="form-check-input form-check-lg"
                                type="checkbox"
                                name="status"
                                id="Completed"
                            />
                            <label className="form-check-label" htmlFor="Completed">
                                Tennis
                            </label>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <ul className="nav nav-tabs" >
                {data.map((sport, index) => (
                    <li className="nav-item" key={index}>
                        <a className="nav-link active" data-toggle="tab" href={`#tab${index + 1}`} onClick={() => { handleBlockEventList(sport.SportsId) }}>
                            {sport.Sportsname}
                        </a>
                    </li>
                ))}
            </ul> */}
            <ul className="nav nav-tabs">
                {data.map((sport, index) => (
                    <li className="nav-item" key={index}>
                        <a
                            className={`nav-link ${index === activeTab ? 'active' : ''}`}
                            data-toggle="tab"
                            href={`#tab${index + 1}`}
                            onClick={() => {
                                handleBlockEventList(sport.SportsId);
                                setActiveTab(index);
                            }}
                        >
                            {sport.Sportsname}
                        </a>
                    </li>
                ))}
            </ul>

            <div className="tab-content mt-2">
                {/* {data.map((sportName, index) => ( */}
                {/* <div className="tab-pane fade show active" id={`tab1`}>
                    <BlockEvents />
                </div> */}
                {data.map((sport, index) => (
                    <div
                        key={index}
                        className={`tab-pane fade ${index === activeTab ? 'show active' : ''}`}
                        id={`tab${index + 1}`}
                    >
                        <BlockEvents />
                    </div>
                ))}
                {/* ))}  */}
            </div>

        </div>
    );
};

export default TabsComponent;
