import { saveAccountStatement, saveAccountBetHistory } from "../slices/clientStatementSlice";
import ReportService from "../../services/clientStatementService";
import store from "../store";

export function getAccountStatement(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountStatementService(data).then((res) => {
            //console.log("account statement res", res)
            store.dispatch(saveAccountStatement(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}

export function getAccountBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountBetHistoryService(data).then((res) => {
            //console.log("AccountBetHistory res", res)
            store.dispatch(saveAccountBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export function getAccountFancyBetHistory(data) {
    return new Promise((resolve, rejact) => {
        ReportService.getAccountFancyBetHistoryService(data).then((res) => {
            //console.log("AccountFancyBetHistory res", res)
            store.dispatch(saveAccountBetHistory(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
