import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const OsmMap = () => {
  // Parse the URL search parameters
  const urlParams = new URLSearchParams(window.location.search);

  // Get the latlong string from the URL
  const latlongString = urlParams.get('latlong');

  let latitude = 0, longitude = 0;

  if (latlongString && /^[0-9.-]+\s*,\s*[0-9.-]+$/.test(latlongString)) {

    // Split the latlong string into latitude and longitude values
    [latitude, longitude] = latlongString.split(',').map(parseFloat);

  } else {

    console.error('Invalid latlong string:', latlongString);

  }

  useEffect(() => {
    if (latitude === undefined || isNaN(latitude) || longitude === undefined || isNaN(longitude)) {
      return; // Do nothing if lat or long is undefined or NaN
    }

    const map = L.map('osm-map').setView([latitude, longitude], 17);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    const customIcon = L.icon({
      iconUrl: '/img/location_icon.png',
      iconAnchor: [26, 48],
      popupAnchor: [1, -34]
    });

    const marker = L.marker([latitude, longitude], { icon: customIcon }).addTo(map);
    console.log('Marker:', marker);

    return () => {
      map.remove();
    };
  }, [latitude, longitude]);

  return (
    <div>
      <div id="osm-map" style={{ height: '300px' }} />
    </div>
  );
};

export default OsmMap;
