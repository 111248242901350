import React from 'react';
import Lottie from 'react-lottie';
import animationData from './screenLoader.json';

const ScreenLoader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',  // Center horizontally
        height: '100vh',          // Set height to viewport height for vertical centering
        position: 'relative',     // Make the container relative to position the overlay
        top: '50px'
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.5)'
    };

    const animationStyle = {
        width: '300px',  // Set the width
        height: '300px', // Set the height
    };

    return (
        <div style={containerStyle}>
            <div style={animationStyle}>
                <Lottie options={defaultOptions} />
            </div>
            <div style={overlayStyle}></div> {/* The overlay */}
        </div>
    );
};

export default ScreenLoader;