import { createSlice } from "@reduxjs/toolkit";
const newsSlice = createSlice({
    name: 'news',
    initialState: {
        newsList: [],
        mynews:[]
    },
    reducers: {
        saveNewsData: (state, action) => {
            state.newsList = action.payload
        },
        saveMyNewsData: (state, action) => {
            state.mynews = action.payload
        }
    }
})

export const {saveNewsData,saveMyNewsData} = newsSlice.actions;
export default newsSlice.reducer
