import React from 'react'
import ClientSearchListTable from './ClientSearchListTable';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';

const ClientSearchList = () => {
  return (
    <NavbarSidebarWrapper>
      <ClientSearchListTable />
    </NavbarSidebarWrapper>
  )
}

export default ClientSearchList;
