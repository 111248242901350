import React from 'react'

const ConfirmationModal = ({ data, handleConfirm }) => {
    return (
        <div>
            <div
                className="modal fade"
                id="confirmModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                {data?.title}
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {data?.description}
                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                onClick={handleConfirm}
                            >
                                Yes
                            </button>
                            <button
                                className="modal-cancel-btn"
                                type="button"
                                data-dismiss="modal"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ConfirmationModal
