import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import TableWrapper from "../../components/CreateMatch/TableWrapper";
import { useEffect, useState } from "react";
import actions from "../../redux/actions";
import { useSelector } from "react-redux";
import * as BsIcons from "react-icons/bs";
import ScreenLoader from "../../components/ScreenLoader";
import { isRoleExits } from "../../utils";
import CreateCustomMarketModal from "../../components/Models/CreateCustomMarketModal";

const CreateMatch = () => {
  let data = useSelector((state) => state.match?.marketList);
  let dataByIDs = useSelector((state) => state.match?.marketByEventIdList);
  let marketIdsList = useSelector((state) => state.match?.marketIdsList?.Result);
  console.log("dataByIDs", dataByIDs);
  const [action, setAction] = useState(true);
  const [loader, setLoader] = useState(true);
  const [currentSportId, setCurrentSportId] = useState("4");

  const handleMarketList = async (sportid) => {
    setLoader(true);
    await actions.getMarketList(sportid);
    let res = await actions.getMarketIdsList(sportid);
    if (res) {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleMarketList(4);
  }, []);

  const handleAction = async (eventId, sportId) => {
    setLoader(true);
    setAction((prevState) => !prevState);
    let res = await actions.getMarketListByEventId(sportId, eventId);
    console.log("demo", res);
    if (res) {
      setLoader(false);
    }
  };

  return (
    <NavbarSidebarWrapper>
      <CreateCustomMarketModal />
      <div>
        {action ? (
          <>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#tab2"
                  onClick={() => handleMarketList(4)}
                >
                  Cricket
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tab3"
                  onClick={() => handleMarketList(1)}
                >
                  Soccer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#tab4"
                  onClick={() => handleMarketList(2)}
                >
                  Tennis
                </a>
              </li>
            </ul>
            {loader ? (
              <ScreenLoader />
            ) : (
              <div className="tab-content">
                <div className="text-right w-100">
                  {(isRoleExits("matchmanual") ||
                    isRoleExits("create_match") ||
                    isRoleExits("addmatch")) && (
                      <button
                        className="back-btn mb-1"
                        data-toggle="modal"
                        data-target="#createCustomMarketModal"
                      >
                        {" "}
                        Create Match
                      </button>
                    )}
                </div>
                <div className="tab-pane fade show active" id="tab2">
                  <TableWrapper
                    data={data}
                    marketIdsList={marketIdsList}
                    showAction={action}
                    handleAction={handleAction}
                  />
                </div>
                <div className="tab-pane fade" id="tab3">
                  <TableWrapper
                    data={data}
                    marketIdsList={marketIdsList}
                    showAction={action}
                    handleAction={handleAction}
                  />
                </div>
                <div className="tab-pane fade" id="tab4">
                  <TableWrapper
                    data={data}
                    marketIdsList={marketIdsList}
                    showAction={action}
                    handleAction={handleAction}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {loader ? (
              <ScreenLoader />
            ) : (
              <>
                <button
                  className="back-btn mb-1"
                  onClick={() => setAction((prevState) => (prevState = true))}
                >
                  {" "}
                  <BsIcons.BsArrowReturnLeft /> Back
                </button>
                <TableWrapper
                  data={dataByIDs}
                  marketIdsList={marketIdsList}
                  showAction={action}
                  handleAction={handleAction}
                />
              </>
            )}
          </>
        )}
      </div>
    </NavbarSidebarWrapper>
  );
};

export default CreateMatch;
