import React, { useEffect, useState } from 'react'
import ChipSummaryContent from './ChipSummaryContent';
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import actions from '../../../redux/actions';
import { useSelector } from 'react-redux';
import ScreenLoader from '../../../components/ScreenLoader';


const ChipSummary = () => {

  const chipSummaryData = useSelector((state) => state.report?.chipSummary?.Result)
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState(1);

  const handleGetChipSummary = async (userId) => {
    setLoader(true)
    await actions.chipSummaryAction(userId)
    setLoader(false)
  }

  useEffect(() => {
    handleGetChipSummary(userId)
  }, [userId])

  const handleUserId = (userId) => {
    if (userId !== 6) {
      setUserId(userId)
    }
  }
  const handleBackBtn = () => {
    setUserId((prevState) => prevState - 1)
  }

  return (
    <NavbarSidebarWrapper>
      {loader ? <ScreenLoader /> :
        <ChipSummaryContent chipSummaryData={chipSummaryData} handleUserId={handleUserId} handleBackBtn={handleBackBtn} userId={userId} />
      }
    </NavbarSidebarWrapper>

  )
}

export default ChipSummary;
