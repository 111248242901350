import React from "react";
import { Link } from "react-router-dom";

const BlockSportsTable = () => {
  return (
    <div className="container-fluid">
      {/* Page Heading */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
      </div>
      {/* Start List of Clints DataTable */}
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="dataTables_length" id="dataTable_length">
                    <label>
                      Show entries
                      <select
                        name="dataTable_length"
                        aria-controls="dataTable"
                        className="custom-select custom-select-sm form-control form-control-sm"
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                  <label>
                    Search:
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="dataTable"
                    />
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table table-bordered dataTable"
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                    role="grid"
                    aria-describedby="dataTable_info"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr role="row" className="bg-gray-300">
                        <th style={{ width: '90%' }}>
                          Name
                        </th>
                        <th style={{ width: '10%' }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="odd">
                        <td>
                          <Link to="/block-market">
                            <span className="text-no-wrap">Cricket</span>
                          </Link>
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                      </tr>
                      <tr className="even">
                        <td>
                          <Link to="/block-market">
                            <span className="text-no-wrap">Tennis</span>
                          </Link>
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockSportsTable;
