import React  from "react";
import moment from "moment";

const CasinoBetsTable = ({ plBetList }) => {

    return (
        <div>
            <table className="table table-bordered dataTable" >
                <thead>
                    <tr className="bg-gray-200">
                        <th className="col-balance">Match Name</th>
                        <th className="col-date">Market Name</th>
                        <th className="col-credit">Selction Name</th>
                        <th className="col-sno">Odds</th>
                        <th className="col-sno">Stack</th>
                        <th className="col-date">User</th>
                        <th className="col-sno">Bet Type</th>
                        <th className="col-date">IP</th>
                        <th className="col-date">Date</th>
                    </tr>
                </thead>
                <tbody className="">
                    {
                        plBetList && Array.isArray(plBetList) && plBetList?.map((data, index) => (
                            <tr key={index} className={data.BetType === "No" || data.BetType === "Lay" ? "lay" : "back"}>
                                {/* <td onClick={() => handlePlBetList(data)}><span>{data?.Sport}{" > "}{data?.Match}  ({data?.MType})</span> </td> */}
                                <td>{data?.MatchName}</td>
                                <td>{data?.MarketType}</td>
                                <td>{data?.SelectionName}</td>
                                <td>{data?.MarketType === 'FANCY' ? data?.Odds + '(' + data?.Price + ')' : data?.Odds}</td>
                                <td>{data?.Stack}</td>
                                <td>{data?.UserName}</td>
                                <td>{data?.BetType}</td>
                                <td>{data?.IP_Address}</td>
                                <td>{moment(data?.createdOn).format('DD-MM-YYYY h:mm:ss A')}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        </div>
    );
};

export default CasinoBetsTable;
