import MatchTable from './MatchTable';

const TableWrapper = ({ type, data, totalrow, showAction, handleAction }) => {

    return (
        <div className="card shadow mb-4">
            <div className="">
                <div className="table-responsive">
                    <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                        <div className="">
                            <div className="">
                                <MatchTable totalrow={totalrow} matchList={data} showAction={showAction} handleAction={handleAction} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableWrapper;
