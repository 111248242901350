import React from "react";
import { useSelector } from "react-redux";
import * as RiIcons from "react-icons/ri";
import actions from "../../redux/actions";
import store from "../../redux/store";
import { setFancyBetList } from "../../redux/slices/bookEventSlice";
import { isSuperAdmin } from "../../utils";

const FancyBetListModal = () => {
  const fancyBetList = useSelector((state) => state.bookEvent.fancyBetList);

  const handleDelete = (itemData) => {
    const isConfirmed = window.confirm(
      "Do you really want to delete this item?",
      "Delete Confirmation"
    );
    if (isConfirmed) {
      actions.GetDeleteUserBet(itemData);
      const updatedFancyBetList = fancyBetList.filter(
        (item) => item !== itemData
      );
      store.dispatch(setFancyBetList(updatedFancyBetList));
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="fancyBetListModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                Bet List
              </h6>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div style={{ overflowX: "auto" }}>
              <table
                className="table table-bordered dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                role="grid"
                aria-describedby="dataTable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr role="row">
                    <th>No.</th>
                    <th>User Name</th>
                    <th>BetType</th>
                    <th>Odd</th>
                    <th>Stack</th>
                    <th>MarketType</th>
                    <th>Selection Name</th>
                    <th>Date</th>
                    <th>IP_Address</th>
                  </tr>
                </thead>
                <tbody>
                  {fancyBetList?.map((item, index) => (
                    <tr
                      key={index}
                      className={
                        item.BetType === "No" || item.BetType === "Lay"
                          ? "lay"
                          : "back"
                      }
                    >
                      <td>{index + 1}</td>
                      <td>{item?.UserName}</td>
                      <td>{item?.BetType}</td>
                      <td>
                        {item?.MarketType === "FANCY"
                          ? item?.Odds + "(" + item?.Price + ")"
                          : item?.Odds}
                      </td>
                      <td>{item?.Stack}</td>
                      <td>{item?.MarketType}</td>
                      <td>{item?.SelectionName}</td>
                      <td>{item?.createdOn}</td>
                      <td>{item?.IP_Address}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FancyBetListModal;
