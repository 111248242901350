import React, { useEffect, useState } from 'react';
import actions from '../../../redux/actions';
import { useSelector } from 'react-redux';


const UserRole = ({ managerUid }) => {
    let AllRoles = useSelector((state) => state.client?.userRolesList?.Result)
    let UserRoles = useSelector((state) => state.client?.userRoles?.Result)

    const [userRolesData, setUserRolesData] = useState([])

    useEffect(() => {
        actions.getAllRoles();
    }, [])

    useEffect(() => {
        if (UserRoles && Array.isArray(UserRoles)) {
            setUserRolesData(UserRoles)
        }
    }, [UserRoles])

    useEffect(() => {
        if (managerUid && managerUid !== 0) {
            actions.getRoleByUser(managerUid);
        }
    }, [managerUid])

    const handleRoleChange = (checked, obj) => {

        setUserRolesData(prevArray => {
            if (checked) {
                // If checked is true, add obj to the array if it doesn't exist
                if (!prevArray.some(item => item.ID === obj.ID)) {
                    return [...prevArray, obj];
                }
            } else {
                // If checked is false, remove obj from the array if it exists
                return prevArray.filter(item => item.ID !== obj.ID);
            }
            return prevArray; // If no changes needed, return the original array
        });
        let data = {
            uid: managerUid,
            roleid: obj.ID
        }
        actions.saveUserRole(data)

    }

    return (
        <div>
            <div
                className="modal fade"
                id="UserRoleModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                User Roles
                            </h6>
                            <button
                                className="close"
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='m-1'>
                                {AllRoles && Array.isArray(AllRoles) && AllRoles.length > 0 ? (
                                    JSON.parse(sessionStorage.getItem("loginUserDetails"))?.Role === "Manager" ? (
                                        AllRoles
                                            .filter(roleA => userRolesData?.some(roleB => roleA.ID === roleB.ID))
                                            .map((data, index, array) => (
                                                <div key={index}>
                                                    {index === 0 || data.GroupId !== array[index - 1]?.GroupId ? (
                                                        <b className="row col-12" style={{ marginBottom: "5px", marginTop: "5px" }}>{data.GroupName}</b>
                                                    ) : null}
                                                    <div className="col-6 form-check float-left" >
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id={`exampleCheckbox-${index}`}
                                                            checked={userRolesData?.some(role => role.ID === data.ID)}
                                                            onChange={(e) => handleRoleChange(e.target.checked, data)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`exampleCheckbox-${index}`}>
                                                            {data?.RoleName}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        AllRoles.map((data, index) => (
                                            <div key={index}>
                                                {index === 0 || data.GroupName !== AllRoles[index - 1]?.GroupName ? (
                                                    <strong className="row col-12">{data.GroupName}</strong>
                                                ) : null}
                                                <div className="col-6 form-check float-left" >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`exampleCheckbox-${index}`}
                                                        checked={userRolesData?.some(role => role.ID === data.ID)}
                                                        onChange={(e) => handleRoleChange(e.target.checked, data)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`exampleCheckbox-${index}`}>
                                                        {data?.RoleName}
                                                    </label>
                                                </div>
                                            </div>
                                        ))
                                    )
                                ) : null}
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button
                                className="modal-cancel-btn"
                                type="button"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default UserRole;
