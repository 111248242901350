import React from "react";

const BlockMarketTable = () => {
  return (
    <div className="container-fluid">
      {/* Page Heading */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
      </div>
      {/* Start List of Clints DataTable */}
      <div className="card shadow mb-4">
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table table-bordered dataTable"
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                    role="grid"
                    aria-describedby="dataTable_info"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr role="row" className="bg-gray-300">
                        <th style={{ width: '20%' }}>
                          Match ID
                        </th>
                        <th style={{ width: '65%' }}>
                          Match
                        </th>
                        <th style={{ width: '5%' }}>
                          Session Open/Close
                        </th>
                        <th style={{ width: '5%' }}>
                          Odds Open/Close
                        </th>
                        <th style={{ width: '5%' }}>
                          Match Open/Close
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="odd">
                        <td>
                          <span className="text-no-wrap">3462</span>
                        </td>
                        <td>
                          <span className="text-no-wrap">Aman singh de</span>
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                      </tr>
                      <tr className="even">
                        <td>
                          <span className="text-no-wrap">3462</span>
                        </td>
                        <td>
                          <span className="text-no-wrap">Aman singh de</span>
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src="img/active.png"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockMarketTable;
