import { saveCompitDetails,saveCompitMarketDetails,saveMarketDetails, saveMarketByEventId, saveMarketIdsList, saveMatchDetails, saveResultList, saveRunningFancy, saveMarketCurrentBets, saveMarketName, saveMarketSetting, saveEventDetails, setMarketAnalysis } from "../slices/matchSlice";
import MarketService from "../../services/matchService";
import store from "../store";

export function getComptList(sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.getCompetitionService(sportId).then((res) => {
            store.dispatch(saveCompitDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form market", error)
            rejact(error)
        })
    })
}
export function getMarketByCompList(sportId,compId) {
    return new Promise((resolve, rejact) => {
        MarketService.getCoMpMarketDetailsService(sportId,compId).then((res) => {
            store.dispatch(saveCompitMarketDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form market", error)
            rejact(error)
        })
    })
}
export function getMarketList(sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketDetailsService(sportId).then((res) => {
            store.dispatch(saveMarketDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form market", error)
            rejact(error)
        })
    })
}

export function getMarketListByEventId(sportId, eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketByEventIdService(sportId, eventId).then((res) => {
            store.dispatch(saveMarketByEventId(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form market ByEventId", error)
            rejact(error)
        })
    })
}

export function getMarketIdsList(sportId) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketIdsListService(sportId).then((res) => {
            store.dispatch(saveMarketIdsList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form market ids list", error)
            rejact(error)
        })
    })
}



export function postMarketModelAction(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postMarketModelService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketModel", error)
            rejact(error)
        })
    })
}

export function postMarketCustomAction(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postMarketCustomService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postCustomMarket", error)
            // rejact(error)
        })
    })
}


export function getOpenMatchList(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getOpenMatchListService(data).then((res) => {
            let obj = { ...(res.data), type: data?.type }
            store.dispatch(saveMatchDetails(obj))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form open match", error)
            rejact(error)
        })
    })
}

export function getSuspendedMatchList(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getSuspendedMatchListService(data).then((res) => {
            let obj = { ...(res.data), type: "suspended" }
            store.dispatch(saveMatchDetails(obj))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form suspended match", error)
            rejact(error)
        })
    })
}

export function getCompletedMatchList(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getCompletedMatchListService(data).then((res) => {
            let obj = { ...(res.data), type: "completed" }
            store.dispatch(saveMatchDetails(obj))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form Completed match", error)
            rejact(error)
        })
    })
}

export function getEventList(eventId) {
    return new Promise((resolve, rejact) => {
        MarketService.getEventListService(eventId).then((res) => {
            store.dispatch(saveEventDetails(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form event list", error)
            rejact(error)
        })
    })
}
export function betLockMarketAction(marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.BetLockMarketService(marketId).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form blockEvent api", error)
            rejact(error)
        })
    })
}
export function matchControlAction(eventId, status, type, marketTime) {
    return new Promise((resolve, rejact) => {
        MarketService.matchControlService(eventId, status, type, marketTime).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}
export function eventControlAction(eventId, status, type, marketTime) {
    return new Promise((resolve, rejact) => {
        MarketService.eventControlService(eventId, status, type, marketTime).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}


export function openCloseMarketAction(eventId, status) {
    return new Promise((resolve, rejact) => {
        MarketService.openCloseMarketService(eventId, status).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form openCloseMarket api", error)
            rejact(error)
        })
    })
}

export function getMarketSettingModelAction(eventId, marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketSettingModelService(eventId, marketId).then((res) => {
            store.dispatch(saveMarketSetting(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getMarketSettingModelapi", error)
            rejact(error)
        })
    })
}
export function postMarketSettingModelAction(data) {
    return new Promise((resolve, rejact) => {
        MarketService.postMarketSettingModelService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form postMarketSettingModelapi", error)
            rejact(error)
        })
    })
}

export function getMarketCurrentBetsAction(data) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketCurrentBetsService(data).then((res) => {
            store.dispatch(saveMarketCurrentBets(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form MarketCurrentBets", error)
            rejact(error)
        })
    })
}
export function getMarketNameAction(eventid) {
    return new Promise((resolve, rejact) => {
        MarketService.getMarketNameService(eventid).then((res) => {
            store.dispatch(saveMarketName(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getMarketName", error)
            rejact(error)
        })
    })
}
export function getRunningFancyAction(marketId, type) {
    return new Promise((resolve, rejact) => {
        MarketService.getRunningFancyService(marketId, type).then((res) => {
            store.dispatch(saveRunningFancy(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getRunningFancy", error)
            rejact(error)
        })
    })
}
export function getResultListAction(marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.getResultListService(marketId).then((res) => {
            store.dispatch(saveResultList(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form getResultList", error)
            rejact(error)
        })
    })
}
export function transferFancyCoinsAction(marketId, selectionId, runValue) {
    return new Promise((resolve, rejact) => {
        MarketService.transferFancyCoinsService(marketId, selectionId, runValue).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form transferFancyCoins", error)
            rejact(error)
        })
    })
}
export function terminateFancyAction(marketId, selectionId) {
    return new Promise((resolve, rejact) => {
        MarketService.terminateFancyService(marketId, selectionId).then((res) => {
            //console.log("terminateFancy api res", res)
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form terminateFancy", error)
            rejact(error)
        })
    })
}
export function rollBackFancyResultAction(marketId, selectionId) {
    return new Promise((resolve, rejact) => {
        MarketService.rollBackFancyResultService(marketId, selectionId).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form rollBackFancyResult", error)
            rejact(error)
        })
    })
}

export function saveCustomFancyAction(data) {
    return new Promise((resolve, rejact) => {
        MarketService.saveCustomFancyService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form saveCustomFancy", error)
            rejact(error)
        })
    })
}

export function declareWinnerAction(marketId, selectionId) {
    return new Promise((resolve, rejact) => {
        MarketService.declareWinnerService(marketId, selectionId).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form declareWinnerService", error)
            // rejact(error)
        })
    })
}
export function reverseCoinAction(marketId) {
    return new Promise((resolve, rejact) => {
        MarketService.reverseCoinService(marketId).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            //console.log("Error getting form reverseCoinService", error)
            // rejact(error)
        })
    })
}

export function GetMarkerAnalysis() {
    return new Promise((resolve, rejact) => {
        MarketService.GetMarkerAnalysisService().then((res) => {
            //console.log("dASHBORD getting form reverseCoinService", res)
            store.dispatch(setMarketAnalysis(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}

