import * as auth from './authActions';
import * as match from './matchActions';
import * as bookEvent from './bookEventActions';
import * as report from './reportActions';
import * as fancy from './fancyActions';
import * as client from './clientActions';
import * as news from './newsActions';
import * as ClientStatement from './ClientStatementAction'

const actions = {
    ...auth,
    ...match,
    ...bookEvent,
    ...report,
    ...fancy,
    ...client,
    ...news,
    ...ClientStatement
}

export default actions;