import { saveNewsData,saveMyNewsData} from "../slices/newsSlice";
import NewsService from "../../services/newsService";
import store from "../store";

export const saveNewsAction = (data) => {
    return new Promise((resolve, rejact) => {
        NewsService.saveNewsService(data).then((res) => {
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
}

export const getNewsListAction = (data) => {
    return new Promise((resolve, rejact) => {
        NewsService.getNewsListService().then((res) => {
            store.dispatch(saveNewsData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
            rejact(error)
        })
    })
    
}
export function GetNews() {
    return new Promise((resolve, rejact) => {
        NewsService.GetNewsService().then((res) => {
            store.dispatch(saveMyNewsData(res.data))
            resolve(res)
        }).catch((error) => {
            if (error?.response?.status === 401) {
                sessionStorage.removeItem('loginUserDetails')
                window.location = '/login'
            }
        })
    })
}
