import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import actions from "../../../redux/actions";
import CreateClientModal from "../../../components/Models/CreateClientModal";
import ChangePasswordModal from "../../../components/Models/ChangePasswordModal";
import WithdrawAndDepositModal from "../../../components/Models/withdrawAndDepositModal";
import ScreenLoader from "../../../components/ScreenLoader";
import EditClientModal from "../../../components/Models/EditClientModal";
import UserRole from "../UserRole/UserRole";
import {
  showForAllExceptManager,
  isRoleExits,
  handleConfirmation,
} from "../../../utils";
import PLBetListModal from "../../../components/Models/PLBetListModal";
import { notifyError } from "../../../components/ToastContext";

const ClientSearchListTable = () => {
  const clientData = useSelector(
    (state) => state.client.clientNewDataList?.Result
  );
  const totalrow = useSelector(
    (state) => state.client.clientNewDataList?.Count
  );
  let navigate = useNavigate();
  const [totalPage, setTotalPage] = useState(0);
  const [editClientUid, setEditClientUid] = useState(0);
  const [managerUid, setManagerUid] = useState(0);
  const [editFormData, setEditFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const [WDModalData, setWDModalData] = useState({
    title: "",
    clientName: "",
    amount: "",
    parentName: "",
    uid: "",
    type: "",
    trntype: "",
  });

  let { pageIndex, pageSize, mid, role } = useParams();
  let [clientActionReq, setClientActionReq] = useState({
    uid: decodeURIComponent(mid),
    searchData: "",
    Status: false,
    PageIndex: parseInt(pageIndex),
    PageSize: parseInt(pageSize),
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleEditShow = () => {
    setShowEditModal(true);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };
  const handleCreateShow = () => setShowCreateModal(true);
  const handleCreateClose = () => setShowCreateModal(false);
  const [active, setActive] = useState(true);
  const [deactive, setDeactive] = useState(false);

  const handleClientList = async (data) => {
    setLoader(true);
    await actions.getClientNewList(data ? data : clientActionReq);
    setLoader(false);
  };
  useEffect(() => {
    let data = {
      uid: decodeURIComponent(mid),
      searchData: "",
      Status: false,
      PageIndex: parseInt(pageIndex),
      PageSize: parseInt(pageSize),
    };
    handleClientList(data);
    setClientActionReq(data);
  }, [pageIndex, pageSize, mid]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / pageSize));
  }, [totalrow, pageSize]);

  const handlePageNoChange = (newPageNo) => {
    setClientActionReq((prevState) => ({ ...prevState, PageIndex: newPageNo }));
    let data = { ...clientActionReq, PageIndex: newPageNo };
    actions.getClientNewList(data);
  };

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, clientActionReq.PageIndex + 1)
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${clientActionReq?.PageIndex + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
              }`}
            onClick={() => {
              setClientActionReq((prevState) => ({
                ...prevState,
                PageIndex: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  const handleUpdateClientStatus = async (uid, type, value) => {
    if (handleConfirmation()) {
      let data = {
        uid: uid,
        type: type,
        value: value,
      };
      setLoader(true);
      try {
        await actions.updateClientStatus(data);
        await actions.getClientNewList(clientActionReq);
      } catch (error) {
        notifyError(error);
      } finally {
        setLoader(false);
      }
    }
  };

  const handleSearch = (searchText) => {
    actions.getClientNewList({ ...clientActionReq, searchData: searchText });
  };

  const handleNewPassword = (type, value) => {
    handleUpdateClientStatus(editClientUid, type, value);
    handleClientList();
  };

  const handleGetAmount = (
    type,
    title,
    amount,
    clientName,
    parentName,
    type2,
    trntype,
    uid
  ) => {
    setWDModalData({
      title,
      clientName,
      parentName,
      amount,
      type: type2,
      trntype,
      uid,
    });
    let data = {
      uid: decodeURIComponent(mid),
      type: type,
      value: trntype,
    };
    actions.getAmountAction(data);
  };

  const handleConfig = (userId) => {
    navigate("/user-setting", { state: { userId: userId } });
  };

  const handleClientActiveDeactive = (status) => {
    setClientActionReq((prevState) => ({ ...prevState, Status: status }));
    handleClientList({ ...clientActionReq, Status: status });
  };
  const exposerBetListHandler = ((uid) => {
    actions.getExposerBetList({ uid: encodeURIComponent(uid) })

  });
  return (
    <>
      <PLBetListModal />
      <CreateClientModal
        mid={decodeURIComponent(mid)}
        role={role}
        handleClientList={handleClientList}
        showModal={showCreateModal}
        handleModalClose={handleCreateClose}
      />
      <EditClientModal
        editFormData={editFormData}
        handleClientList={handleClientList}
        showModal={showEditModal}
        handleModalClose={handleEditClose}
      />
      <ChangePasswordModal
        handleNewPassword={handleNewPassword}
        handleClientList={handleClientList}
      />
      <WithdrawAndDepositModal
        data={WDModalData}
        handleClientList={handleClientList}
      />
      <UserRole managerUid={managerUid} />
      <div className="content-header-btn">
        Client Search List
        <div className="text-right">
          {(showForAllExceptManager() || isRoleExits("user_create")) && (
            <button
              className="custom-green-btn"
              data-toggle="modal"
              data-target="#createClientModal"
              style={{ fontSize: "13px" }}
              onClick={handleCreateShow}
            >
              Add Client
            </button>
          )}
        </div>
      </div>
      {loader ? (
        <ScreenLoader />
      ) : (
        <div className="card shadow mb-4">
          <div className="row">
            <div
              className="col-5 col-md-2 col-lg-1 mt-2"
              style={{ marginLeft: "30px" }}
            >
              <label className="form-check-label">
                <input
                  type="radio"
                  checked={active}
                  className="form-check-input mr-2"
                  onChange={(e) => {
                    handleClientActiveDeactive(false);
                    setActive(true);
                    setDeactive(false);
                  }}
                />
                Active
              </label>
            </div>
            <div className="col-5 col-md-5 col-lg-5 mt-2">
              <label className="form-check-label">
                <input
                  type="radio"
                  checked={deactive}
                  className="form-check-input mr-2"
                  onChange={(e) => {
                    handleClientActiveDeactive(true);
                    setDeactive(true);
                    setActive(false);
                  }}
                />{" "}
                De-active
              </label>
            </div>
          </div>
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div className="d-flex d-wrap">
                <div className="col-sm-12 d-flex justify-content-end">
                  <label>
                    {/* Search: */}
                    <input
                      type="search"
                      className="form-control form-control-sm mt-1"
                      placeholder="Search"
                      aria-controls="dataTable"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <table
                className="table table-bordered dataTable"
                id="dataTable"
                width="100%"
                cellSpacing={0}
                role="grid"
                aria-describedby="dataTable_info"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr role="row" className="bg-gray-300">
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Age: activate to sort column ascending"
                      style={{ width: 30 }}
                    >
                      Edit
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Age: activate to sort column ascending"
                      style={{ width: 68 }}
                    >
                      Id
                    </th>
                    <th
                      className="sorting text-wrap sorting_asc"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Name: activate to sort column descending"
                      style={{ width: 200 }}
                    >
                      Name [UserName]
                    </th>
                    <th
                      className="sorting sorting_asc"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Name: activate to sort column descending"
                      style={{ width: 100 }}
                    >
                      ParentName
                    </th>
                    <th
                      className="sorting sorting_asc"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label="Name: activate to sort column descending"
                      style={{ width: 200 }}
                    >
                      Remark
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Position: activate to sort column ascending"
                      style={{ width: "350px" }}
                    >
                      Password
                    </th>
                    <th
                      className="sorting text-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Office: activate to sort column ascending"
                      style={{ width: 145 }}
                    >
                      Credit Limit
                    </th>
                    <th
                      className="sorting text-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Age: activate to sort column ascending"
                      style={{ width: 68 }}
                    >
                      Ava.Credit Limit
                    </th>
                    <th
                      className="sorting textwrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Start date: activate to sort column ascending"
                      style={{ width: 137 }}
                    >
                      [Cash]+[P/L]
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Balance
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Liability
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Share(%)
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Credit
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Cash
                    </th>
                    <th
                      className="sorting textwrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      D/W Commision
                    </th>
                    <th
                      className="sorting text-no-wrap"
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Action
                    </th>
                    <th
                      className="sorting "
                      tabIndex={0}
                      aria-controls="dataTable"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Salary: activate to sort column ascending"
                      style={{ width: 123 }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clientData &&
                    Array.isArray(clientData) &&
                    clientData?.map((client, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even" : "odd"}
                      >
                        <td className="sorting_1">
                          <img
                            alt="edit"
                            className="iconWidthHeight"
                            src="../../../../img/Icon_edit.png"
                            data-toggle="modal"
                            data-target="#editClientModal"
                            onClick={() => {
                              setEditFormData(client);
                              handleEditShow();
                            }}
                          />
                        </td>
                        <td>{client.Id}</td>
                        <td>
                          <Link
                            to={`/clients/${pageIndex}/${pageSize}/${encodeURIComponent(
                              client.uid
                            )}/${client.role}`}
                          >
                            {" "}
                            <span className="text-no-wrap">
                              {" "}
                              <button className="custom-yellow-btn">
                                {client.role}
                              </button>
                              {client.Name}[{client.UserName}]
                            </span>
                          </Link>
                        </td>
                        <td>
                          <span className="text-wrap">{client.ParentName}</span>
                        </td>
                        <td>
                          <span className="text-wrap">{client.Remark}</span>
                        </td>

                        <td>
                          <button
                            className="custom-red-btn"
                            data-toggle="modal"
                            data-target="#changePasswordModal"
                            onClick={() => setEditClientUid(client.uid)}
                          >
                            <span className="text text-no-wrap">
                              Change PWD
                            </span>
                          </button>
                        </td>
                        <td>
                          <span className="text-no-wrap">
                            {client.CreditLimit}
                          </span>
                        </td>
                        <td>
                          <span className="text-no-wrap">
                            {client.AVCreditLimit}
                          </span>
                        </td>
                        <td>
                          <span
                            className={
                              client?.Profitloss < 0
                                ? "text-no-wrap red-text"
                                : "text-no-wrap green-text"
                            }
                          >
                            {client?.Profitloss}
                          </span>
                        </td>
                        <td>
                          <span className="text-no-wrap">{client.Balance}</span>
                        </td>
                        <td>
                          {client?.role == 'Client' ? (<button
                            className="custom-red-btn"
                            data-toggle="modal"
                            data-target="#plBetListModal"
                            onClick={() => exposerBetListHandler(client.uid)}                         >
                            {client.Exposure}

                          </button>) :
                            (<span
                              className={
                                client?.Exposure != 0
                                  ? "text-no-wrap red-text"
                                  : "text-no-wrap green-text"
                              }
                            >
                              {client.Exposure}
                            </span>)}
                        </td>
                        <td>
                          <span className="text-no-wrap">{client.MyShare}</span>
                        </td>
                        <td>
                          <div className="inline-container">
                            {client?.role === "Manager" ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="custom-grey-btn"
                                  data-toggle="modal"
                                  data-target="#WithdrawAndDepositModal"
                                  onClick={() =>
                                    handleGetAmount(
                                      "d",
                                      `Deposite Chips (${client?.UserName})`,
                                      client.Balance,
                                      client.Name,
                                      client.ParentName,
                                      "Coin",
                                      "D",
                                      client.uid
                                    )
                                  }
                                >
                                  D
                                </button>
                                <button
                                  className="custom-red-btn"
                                  data-toggle="modal"
                                  data-target="#WithdrawAndDepositModal"
                                  onClick={() =>
                                    handleGetAmount(
                                      "w",
                                      `Withdraw Chips (${client?.UserName})`,
                                      client.Balance,
                                      client.Name,
                                      client.ParentName,
                                      "Coin",
                                      "W",
                                      client.uid
                                    )
                                  }
                                >
                                  W
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="inline-container">
                            {client?.role === "Manager" ? (
                              ""
                            ) : (
                              <>
                                {isRoleExits("coin_add") && (
                                  <button
                                    className="custom-grey-btn"
                                    data-toggle="modal"
                                    data-target="#WithdrawAndDepositModal"
                                    onClick={() =>
                                      handleGetAmount(
                                        "d",
                                        `Deposite Cash (${client?.UserName})`,
                                        client.Profitloss,
                                        client.Name,
                                        client.ParentName,
                                        "Cash",
                                        "D",
                                        client.uid
                                      )
                                    }
                                  >
                                    CD
                                  </button>
                                )}
                                {isRoleExits("coin_wid") && (
                                  <button
                                    className="custom-red-btn"
                                    data-toggle="modal"
                                    data-target="#WithdrawAndDepositModal"
                                    onClick={() =>
                                      handleGetAmount(
                                        "w",
                                        `Withdraw Cash (${client?.UserName})`,
                                        client.Profitloss,
                                        client.Name,
                                        client.ParentName,
                                        "Cash",
                                        "W",
                                        client.uid
                                      )
                                    }
                                  >
                                    CW
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="inline-container">
                            {client?.role === "Manager" ? (
                              ""
                            ) : (
                              <>
                                <button
                                  className="custom-grey-btn"
                                  data-toggle="modal"
                                  data-target="#WithdrawAndDepositModal"
                                  onClick={() =>
                                    handleGetAmount(
                                      "d",
                                      `Deposite Commision (${client?.UserName})`,
                                      client.Balance,
                                      client.Name,
                                      client.ParentName,
                                      "Comm",
                                      "D",
                                      client.uid
                                    )
                                  }
                                >
                                  D
                                </button>
                                <button
                                  className="custom-red-btn"
                                  data-toggle="modal"
                                  data-target="#WithdrawAndDepositModal"
                                  onClick={() =>
                                    handleGetAmount(
                                      "w",
                                      `Withdraw Commision (${client?.UserName})`,
                                      client.Balance,
                                      client.Name,
                                      client.ParentName,
                                      "Comm",
                                      "W",
                                      client.uid
                                    )
                                  }
                                >
                                  W
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="inline-container">
                            {client?.role === "Manager" ? (
                              <button
                                className="custom-grey-btn"
                                data-toggle="modal"
                                data-target="#UserRoleModal"
                                onClick={() => setManagerUid(client?.uid)}
                              >
                                Roles
                              </button>
                            ) : (
                              <>
                                <button
                                  className={
                                    client?.BetStatus
                                      ? "custom-green-btn"
                                      : "custom-red-btn"
                                  }
                                  onClick={() =>
                                    handleUpdateClientStatus(
                                      client?.uid,
                                      "BA",
                                      client?.BetStatus ? "0" : "1"
                                    )
                                  }
                                >
                                  {client?.BetStatus ? "BetLock" : "BetUnlock"}
                                </button>
                                <button
                                  className="custom-grey-btn"
                                  onClick={() =>
                                    navigate(
                                      `/client-statement/${encodeURIComponent(
                                        client?.uid
                                      )}`
                                    )
                                  }
                                >
                                  Log
                                </button>
                                <button
                                  className="custom-grey-btn"
                                  onClick={() => {
                                    handleConfig(client?.Id);
                                  }}
                                >
                                  Config
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <img
                            alt="status"
                            className="iconWidthHeight"
                            src={
                              client.status
                                ? "../../../../img/active.png"
                                : "../../../../img/deactive.png"
                            }
                            onClick={() =>
                              handleUpdateClientStatus(
                                client.uid,
                                "S",
                                client.status ? "0" : "1"
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {clientData &&
                Array.isArray(clientData) &&
                clientData?.length > 0 && (
                  <div className="row m-1">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="dataTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        Total Page : {totalPage}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="dataTable_paginate"
                      >
                        <ul className="pagination d-flex justify-content-end">
                          <li
                            className="paginate_button page-item previous disabled"
                            id="dataTable_previous"
                          >
                            <button
                              className="pagination-action-btn"
                              disabled={
                                clientActionReq?.PageIndex === 0 ? true : false
                              }
                              onClick={() => {
                                handlePageNoChange(
                                  clientActionReq?.PageIndex - 1
                                );
                              }}
                            >
                              Previous
                            </button>
                          </li>
                          {DynamicPagination(totalPage)}
                          <li
                            className="paginate_button page-item next"
                            id="dataTable_next"
                          >
                            <button
                              className="pagination-action-btn"
                              disabled={
                                clientActionReq?.PageIndex === totalPage - 1
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                handlePageNoChange(
                                  clientActionReq?.PageIndex + 1
                                );
                              }}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientSearchListTable;
