import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';

const EditNews = ({ newsData }) => {
    const [data, setData] = useState({
        id: 0,
        deleted: false,
        status: true,
        createdOn: new Date(),
        updateOn: new Date(),
        Message: "",
        Role: "Admin"
    });
    const [newsErr, setNewsErr] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    useEffect(() => {
        setData(newsData);
    }, [newsData]);

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };

    const updateNews = async () => {
        try {
            if (!data.Message.trim()) {
                setNewsErr("Please enter news headline.");
                return;
            }

            setNewsErr("");
            setSuccessMsg("");

            const response = await actions.saveNewsAction(data);

            if (response && response.status === 200) {
                setSuccessMsg("News updated successfully!");
                await actions.getNewsListAction();
            } else {
                setNewsErr("Error saving news. Unexpected response.");
            }
        } catch (error) {
            setNewsErr("An error occurred while saving news. Please try again later.");
        }
    };

    return (
        <div className="modal fade" id="newsModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="exampleModalLabel">News</h6>
                        <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="email">News</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Message"
                                            value={data.Message}
                                            onChange={handleChange}
                                        />
                                        <p className='red-text' style={{ fontSize: "12px" }}>{newsErr}</p>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p className='green-text' style={{ fontSize: "12px" }}>{successMsg}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-submit-btn" type="button" onClick={updateNews}>Save</button>
                        <button className="modal-cancel-btn" data-dismiss="modal" onClick={() => { setSuccessMsg(""); setNewsErr("") }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditNews;
