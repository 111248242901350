import React, { useEffect, useState } from 'react'
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import actions from '../../../redux/actions';
import ScreenLoader from '../../../components/ScreenLoader';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';

const ShowMatchBet = () => {
  const ShowMatchBetData = useSelector((state) => state.report?.overAllPandL?.Result)
  const { userId, eventId } = useParams();
  const [matchBetData, setMatchBetData] = useState([]);
  const [loader, setLoader] = useState(false);
  const Navigate = useNavigate()

  const handleMatchBetList = async () => {
    setLoader(true)
    let data = {
      userId: userId,
      marketId: eventId
    }
    await actions.overAllPandLAction(data)
    setLoader(false)
  }

  useEffect(() => {
    handleMatchBetList()
  }, [])

  useEffect(() => {
    setMatchBetData(ShowMatchBetData)
  }, [ShowMatchBetData])


  return (
    <NavbarSidebarWrapper>
      <button className='back-btn mb-1' onClick={() => Navigate('/account-statement')}> <BsIcons.BsArrowReturnLeft /> Back</button>
      {loader ? <ScreenLoader /> :
        <div className="card shadow mb-4">
          <div className="">
            <div className="table-responsive">
              <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                <div>
                  <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th className="col-sno">S.No.</th>
                        <th className="col-date">Market Name</th>
                        <th className="col-desc">Market Type</th>
                        <th className="col-credit">P&L</th>
                        <th className="col-balance">Commission</th>
                        <th className="col-balance">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        matchBetData && Array.isArray(matchBetData) && matchBetData?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textWrap: 'nowrap' }}>{item?.MarketName}</td>
                            <td>{item?.marketType}</td>
                            <td className="green-text">{item?.profitLoss}</td>
                            <td>{item?.commission}</td>
                            <td><Link to={`/profit_loss_statement/${item?.marketId}/1`}>Show Bet</Link></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div >
              </div>
            </div>
          </div>
        </div>
      }
    </NavbarSidebarWrapper>
  )
}

export default ShowMatchBet;