import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleStyleMob } from "../../redux/slices/styleSlice";
import actions from "../../redux/actions";
import { formatAmount } from "../../utils";

const Navbar = () => {
  const dispatch = useDispatch();
  const balanceDetails = useSelector(
    (state) => state.auth.balanceDetails?.Result
  );
  const news = useSelector((state) => state.news?.mynews?.Result);

  useEffect(() => {
    const fetchData = async () => {
      actions.GetNews();
      await actions.getBalanceDetails();
    };
    const checkTokenValidity = async () => {
      await fetchData();
    };
    checkTokenValidity();
    const intervalId = setInterval(checkTokenValidity, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const changeStyleMob = () => {
    dispatch(toggleStyleMob());
  };

  return (
    <div>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        {/* Sidebar Toggle (Topbar) */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={changeStyleMob}
        >
          <i className="fa fa-bars" />
        </button>
        {/* Topbar Search */}
        <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
          {/* <h1 className="h4 mb-0 text-gray-800">{navTitle}</h1> */}
        </form>
        {/* Topbar Navbar */}
        <ul className="navbar-nav ml-auto">
          {/* Nav Item - Search Dropdown (Visible Only XS) */}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <span className="text-gray-600 nav-link dropdown-toggle">
              {formatAmount(balanceDetails?.Balance)}
            </span>
            {/* <div
              className="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw" />
            </div> */}
            {/* Dropdown - Messages */}
            {/* <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm" />
                    </button>
                  </div>
                </div>
              </form>
            </div> */}
          </li>

          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                {formatAmount(balanceDetails?.Balance)}
              </span>
              <img alt="wallet" className="img-profile " src="img/wallet.png" />
              {/* <img
                alt="user profile"
                className="img-profile rounded-circle"
                src={`${window.location.origin}+/img/undraw_profile.svg`}
              /> */}
            </div>
            {/* Dropdown - User Information */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <div className="dropdown-item">
                {/* <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" /> */}
                Credit Limit:{"  "}
                {formatAmount(balanceDetails?.CreditLimit)}
              </div>

              <div className="dropdown-item">
                My Share :{"  "}
                {formatAmount(balanceDetails?.MyShare)}
              </div>
              <div className="dropdown-item">
                P&L :{"  "}
                {formatAmount(balanceDetails?.PL)}
              </div>
              <div className="dropdown-divider" />
              <div
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#myChangePasswordModal"
              >
                <i className="fas fa-key fa-sm fa-fw mr-2 text-gray-400" />
                Change Password
              </div>
              <div
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                Logout
              </div>
            </div>
          </li>
          <li className="nav-item dropdown no-arrow mx-1">
            {/* <div
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            > */}
            <span className="mr-2 text-gray-600 small nav-link dropdown-toggle">
              {JSON.parse(sessionStorage.getItem("loginUserDetails"))?.UserName}
            </span>
            {/* </div> */}
          </li>
        </ul>
      </nav>
      <div
        className="marquee_area d-flex align-items-center w-100 bg-gray-800 py-1"
        style={{ fontSize: "12px" }}
      >
        News2:
        <div className="scrolling-text text-white">
          News:
          {news &&
            news.map((item, index) => (
              <span key={index}> * {item.Message}</span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
