import React, { useEffect, useState } from "react";
import actions from "../../../redux/actions";

const UserLogTable = ({ data, fromDate, toDate, totalrow, uid }) => {
  const [userLog, setuserLog] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [userLogReq, setuserLogReq] = useState({
    skipRec: 0,
    psize: 10,
  });
  useEffect(() => {
    setuserLog(data);
  }, [data]);

  useEffect(() => {
    setuserLogReq((prevState) => ({
      ...prevState,
      startDate: fromDate,
      endDate: toDate,
      uid: uid,
    }));
  }, [fromDate, toDate, uid]);

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / 10));
  }, [totalrow]);

  const handlePageNoChange = (newPageNo) => {
    setuserLogReq((prevState) => ({ ...prevState, skipRec: newPageNo }));
    let data = { ...userLogReq, skipRec: newPageNo };
    actions.userLogAction(data);
  };

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, userLogReq?.skipRec + 1)
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              userLogReq?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setuserLogReq((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <div>
      <table className="table table-bordered dataTable">
        <thead>
          <tr className="bg-gray-300">
            <th className="col-sno">S.No.</th>
            <th className="col-date">Date</th>
            <th className="col-desc">Username</th>
            <th className="col-debit">UpLine</th>
            <th className="col-credit">Ip Address</th>
          
            <th className="col-balance">Lat,Long</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(userLog) &&
            userLog?.length > 0 &&
            userLog?.map((log, index) => (
              <tr key={index}>
                <td>#</td>
                <td className="text-no-wrap">{log?.LoginTime}</td>
                <td className="text-no-wrap">{log?.UserName}</td>
                <td className="text-no-wrap">{log?.UpLine}</td>
                <td className="text-no-wrap">{log?.UserIP}</td>
              
                <td className="text-no-wrap blue-text" style={{cursor:"pointer"}} onClick={() => { window.open(`/user-location?latlong=${log?.latlong}`, '_blank'); }}>{log?.latlong}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {userLog && userLog?.length > 0 && (
        <div className="row m-1">
          <div className="col-sm-12 col-md-5">
            <div
              className="dataTables_info"
              id="dataTable_info"
              role="status"
              aria-live="polite"
            >
              Total Page : {totalPage}
            </div>
          </div>
          <div className="col-sm-12 col-md-7">
            <div
              className="dataTables_paginate paging_simple_numbers"
              id="dataTable_paginate"
            >
              <ul className="pagination d-flex justify-content-end">
                <li
                  className="paginate_button page-item previous disabled"
                  id="dataTable_previous"
                >
                  <button
                    className="pagination-action-btn"
                    disabled={userLogReq?.skipRec === 0 ? true : false}
                    onClick={() => {
                      handlePageNoChange(userLogReq?.skipRec - 1);
                    }}
                  >
                    Previous
                  </button>
                </li>
                {DynamicPagination(totalPage)}
                <li
                  className="paginate_button page-item next"
                  id="dataTable_next"
                >
                  <button
                    className="pagination-action-btn"
                    disabled={
                      userLogReq?.skipRec === totalPage - 1 ? true : false
                    }
                    onClick={() => {
                      handlePageNoChange(userLogReq?.skipRec + 1);
                    }}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserLogTable;
