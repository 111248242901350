import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import * as BsIcons from "react-icons/bs";
import actions from "../../redux/actions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { formattedDateTime } from "../../utils";
import { useNavigate, useParams } from "react-router-dom";

const ClientStatements = () => {
  let navigate = useNavigate();
  let { uid } = useParams();
  const accountStatementList = useSelector(
    (state) => state.clientStatement?.accountStatementDetails
  );
  const [totalPage, setTotalPage] = useState(0);

  const [data, setData] = useState({
    uid: encodeURIComponent(uid),
    skipRec: 0,
    type: "All",
    psize: 10,
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    sportsId: 0,
  });

  useEffect(() => {
    let data = {
      uid: encodeURIComponent(uid),
      skipRec: 0,
      type: "All",
      psize: 10,
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sportsId: 0,
    };
    actions.getAccountStatement(data);
  }, []);

  useEffect(() => {
    if (
      accountStatementList?.Result &&
      Array.isArray(accountStatementList?.Result) &&
      accountStatementList?.Result?.length > 0
    ) {
      let totalRow = accountStatementList?.Result[0]?.SqlCount;
      setTotalPage(Math.ceil(totalRow / 10));
    } else {
      setTotalPage(0);
    }
  }, [accountStatementList]);

  const handleChange = (e) => {
    let { value, name } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleResetData = (e) => {
    e.preventDefault();
    setData({
      ...data,
      type: "All",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sportsId: 0,
    });
  };

  const callAccountAction = (e) => {
    e.preventDefault();
    actions.getAccountStatement(data);
  };

  const handlePageNoChange = (newPageNo) => {
    setData((prevState) => ({ ...prevState, skipRec: newPageNo }));
    let data2 = { ...data, skipRec: newPageNo };
    actions.getAccountStatement(data2);
  };

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(number - maxPagesToShow + 1, data?.skipRec + 1)
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              data?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setData((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        {" "}
        {/* main content wrapper */} {/* Apply the custom border color */}
        <div className="content-header d-flex justify-content-between">
          <span className="">Account Statement</span>
        </div>
        <div className="row ">
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">Type</label>
            <select
              className="form-control"
              value={data?.type}
              name="type"
              onChange={handleChange}
            >
              <option value="All">All</option>
              <option value="FreeChips">Balance</option>
              <option value="Settlement">Settlement</option>
              <option value="ProfitLoss">Profit/Loss</option>
            </select>
          </div>
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose from Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.startDate}
              name="startDate"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 col-md-4 col-lg-3 mt-2">
            <label className="form-label white-input-label mb-0">
              Choose to Date
            </label>
            <input
              type="date"
              className="form-control custom-input"
              value={data?.endDate}
              name="endDate"
              onChange={handleChange}
            />
          </div>
          <div className="col-6 col-md-4 col-lg-3 mt-0 mt-lg-4">
            <button
              type="button"
              className="custom-yellow-btn me-2"
              onClick={callAccountAction}
            >
              Go
            </button>
            <button
              type="button"
              className="custom-grey-btn"
              onClick={handleResetData}
            >
              <BsIcons.BsEraserFill /> Reset
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-5 col-md-5 col-lg-5 mt-2">
            Opening Balance :{accountStatementList?.open}
          </div>
          <div className="col-5 col-md-5col-lg-5 mt-2">
            Closing Balance : {accountStatementList?.close}{" "}
          </div>
        </div>
        <div className="table-responsive mt-2">
          <div className="table-wrapper">
            <table className="table table-striped">
              <thead>
                <th>Date</th>
                <th>Amount</th>
                <th> Balance</th>
                <th>Remarks</th>
                <th>Description</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td className="dark-table-cell"> </td>
                  <td className="dark-table-cell"> </td>
                  <td className="dark-table-cell">
                    {accountStatementList?.close}{" "}
                  </td>
                  <td className="dark-table-cell long-row-td"> </td>
                  <td className="dark-table-cell"> Closing balance </td>
                  <td className="dark-table-cell"> </td>
                </tr>
                {accountStatementList?.Result &&
                Array.isArray(accountStatementList?.Result) &&
                accountStatementList?.Result?.length > 0 ? (
                  accountStatementList?.Result?.map((item, index) => (
                    <tr key={index}>
                      <td className="dark-table-cell">
                        {" "}
                        {formattedDateTime(item?.createdOn)}
                      </td>
                      <td
                        className={`${
                          item?.Amount < 0
                            ? "dark-table-cell red-text"
                            : "dark-table-cell green-text"
                        }`}
                      >
                        {item?.Amount}{" "}
                      </td>
                      <td className="dark-table-cell">{item?.Balance} </td>
                      <td className="dark-table-cell long-row-td">
                      {
                        item?.Remarks
                        
                        /* {item?.EventId == "1111"
                          ? "Comm"
                          : item?.EventId == "1234"
                          ? "Coin"
                          : item?.EventId == "1212"
                          ? "Cash"
                          : ""}{" "} */}
                      </td>
                      <td className="dark-table-cell">{item?.Discription} </td>
                      {item?.EventId.length < 5 ? (
                        <td className="dark-table-cell"></td>
                      ) : (
                        <td
                          className="dark-table-cell active-link"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              `/betting_history/${
                                item?.EventId
                              }/0/${encodeURIComponent(uid)}`,
                              {
                                state: {
                                  eventid: 0,
                                  SelectionID: "",
                                  marketType: item?.MarketType,
                                },
                              }
                            )
                          }
                        >
                          show bet
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="5">
                      No Result
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {accountStatementList?.Result &&
              Array.isArray(accountStatementList?.Result) &&
              accountStatementList?.Result?.length > 0 && (
                <div className="row m-1">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Total Page : {totalPage}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination d-flex justify-content-end">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="dataTable_previous"
                        >
                          <button
                            className="pagination-action-btn"
                            disabled={data?.skipRec === 0 ? true : false}
                            onClick={() => {
                              handlePageNoChange(data?.skipRec - 1);
                            }}
                          >
                            Previous
                          </button>
                        </li>
                        {DynamicPagination(totalPage)}
                        <li
                          className="paginate_button page-item next"
                          id="dataTable_next"
                        >
                          <button
                            className="pagination-action-btn"
                            disabled={
                              data?.skipRec === totalPage - 1 ? true : false
                            }
                            onClick={() => {
                              handlePageNoChange(data?.skipRec + 1);
                            }}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </NavbarSidebarWrapper>
  );
};

export default ClientStatements;
