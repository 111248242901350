import NavbarSidebarWrapper from "../../components/navbarSidebarWrapper";
import actions from "../../redux/actions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { formattedDateTime } from "../../utils";
import * as MdIcons from 'react-icons/md';

const BettingHistory = () => {
    const matchBetList = useSelector((state) => state.clientStatement?.accountBetHistoryDetails?.Result)
    let { marketid, uid } = useParams();
    const location = useLocation();
    const eventid = location.state?.eventid;
    const marketType = location.state?.marketType;
    const SelectionId = location.state?.SelectionID;
    let navigate = useNavigate();
    useEffect(() => {
        let data = {
            marketId: marketid,
            role: 'Client',
            userId: encodeURIComponent(uid)
        }
        //debugger;
        if (marketType.toLowerCase() === 'fancy') {
            actions.getAccountFancyBetHistory({ ...data, selectionId: SelectionId });
            return
        }
        actions.getAccountBetHistory(data)
    }, [uid, marketid, SelectionId])




    return (
        <NavbarSidebarWrapper>
            <div className="content-wrapper me-0" > {/* main content wrapper */} {/* Apply the custom border color */}
                <div className="content-header d-flex justify-content-between">
                    <span className="">Betting History</span>
                </div>
                <button className="custom-grey-btn" onClick={() => navigate(`/client-statement/${encodeURIComponent(uid)}`)}><MdIcons.MdArrowBackIos />Back</button>
                <div className="table-responsive mt-2">
                    <div className="table-wrapper">
                        <table className="table table-striped">
                            <thead>
                            <th>UserName</th>
                                <th>Placed</th>
                                <th>Description</th>
                                <th>Selection Type</th>
                                <th>B&L</th>
                                <th>Odds</th>
                                <th>Stake</th>
                                <th>BetStatus</th>
                                <th>Result</th>
                                <th>Exp/Profit</th>
                                <th>IP_Address</th>
                            </thead>
                            <tbody>
                                {matchBetList && Array.isArray(matchBetList) && matchBetList?.length > 0 ? matchBetList?.map((item, index) => (
                                    <tr key={index}>
                                        <td className="dark-table-cell">{item?.UserName}</td>
                                        <td className="dark-table-cell">{formattedDateTime(item?.createdOn)}</td>
                                        <td className='dark-table-cell'>{item?.MatchName} </td>
                                        <td className="dark-table-cell">{item?.SelectionName} </td>
                                        <td className={`${item?.BetType === 'Back' ? 'dark-table-cell back market-lay-back' : 'dark-table-cell lay market-lay-back'}`} style={{ borderRadius: '0px' }}>{item?.BetType} </td>
                                        <td className="dark-table-cell">{item?.MarketType === 'FANCY' ? item?.Odds + '(' + item?.Price + ')' : item?.Odds}</td>
                                         
                                        <td className="dark-table-cell">{item?.Stack} </td>
                                        <td className={`${item?.BetStatus === 'Lost' ? 'dark-table-cell red-text' : 'dark-table-cell green-text'}`}>{item?.BetStatus} </td>
                                        <td className="dark-table-cell">{item?.Role} </td>
                                        <td className={`${item?.BetStatus === 'Lost' ? 'dark-table-cell red-text' : 'dark-table-cell green-text'}`}>{item?.BetStatus === 'Lost' ? item?.Exposure : item?.ReturnBalance} </td>
                                        <td className='dark-table-cell'>{item?.IP_Address} </td>
                                   
                                        
                                    </tr>
                                ))
                                    :
                                    <tr>
                                        <td className="text-center" colSpan='9'>No Result</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </NavbarSidebarWrapper>
    )

}

export default BettingHistory;