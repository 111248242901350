import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { useSelector } from 'react-redux';
import { notifyError } from '../ToastContext';
const FancyPleaceBet = ({ MarketId, EventId, sportsId }) => {

    const [userSuggestions, setUserSuggestions] = useState([]);
    const [userInput, setUserInput] = useState(''); // Initialize the input value
    const [formErrors, setFormErrors] = useState({});
    const [isFormFilled, setIsFormFilled] = useState(false);

    let userlist = useSelector((state) => state.auth.userDataList?.Result);
    let selectionData = useSelector((state) => state.bookEvent.selectionData);

    useEffect(() => {
        if (userlist !== undefined && userlist !== "No Data") {
            setUserSuggestions(userlist);
        }
        setData({
            ...data, SelectionID: selectionData?.SelectionId, SelectionName: selectionData?.RunnerName,
        });

    }, [userlist, selectionData]);

    const [data, setData] = useState({
        "UserId": 0,
        "sportsId": 0,
        "MarketId": "",
        "EventId": "",
        "SelectionID": selectionData?.SelectionId,
        "SelectionName": selectionData?.RunnerName,
        "Odds": 0,
        "Price": 0,
        "Stack": 0,
        "Exposure": 0,
        "ReturnBalance": 0,
        "BetType": "Yes",
        "BetStatus": "Pending",
        "MarketType": "FANCY",
    })

    const handleChange = (e) => {
        let { value, name } = e.target;
        setData({ ...data, [name]: value })
        setFormErrors({ ...formErrors, [name]: null });
        if (validateForm()) {
            setIsFormFilled(true);
        }
    }

    const saveFancy = async () => {
        if (validateForm()) {
            try {
                await actions.postFancyBetAdmin({ ...data, MarketId, EventId })
                setUserInput('');
            } catch (error) {
                notifyError(error);
            }
        }
    }


    const handleUserInputChange = (e) => {
        const { value } = e.target;
        setUserInput(value);

    }

    useEffect(() => {
        actions.getUserList(userInput);
    }, [userInput]);

    const handleUserSelection = (user) => {
        setData({ ...data, UserId: user.id })
        setUserInput(user.username);
        setUserSuggestions([]);
        setFormErrors({ ...formErrors, UserId: null });
        if (validateForm()) {
            setIsFormFilled(true);
        }

    }

    const validateForm = () => {
        const errors = {};
        if (data.UserId === 0) {
            errors.UserId = "User is required.";
        }
        if (data.Price <= 0) {
            errors.Price = "Price must be greater than 0.";
        }
        if (data.Odds <= 0) {
            errors.Odds = "RUN must be greater than 0.";
        }
        if (data.Stack <= 0) {
            errors.Stack = "Stack must be greater than 0.";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    return (
        <div>
            <div className="modal fade" id="fancyPleaceBet" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">
                                Fancy Bet
                            </h6>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="px-2">
                                <div className="row">
                                    <div className="col ">
                                        <label htmlFor="textbox1">Selection</label>
                                        <input
                                            disabled={true}
                                            type="text"
                                            className="form-control"
                                            name="SelectionID"
                                            value={data?.SelectionName}
                                        />
                                    </div>
                                    <div className="col ">
                                        <label htmlFor="textbox1">User</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="UserId"
                                            value={userInput}
                                            onChange={handleUserInputChange}
                                        />
                                        {formErrors.UserId && <span className="error-message">{formErrors.UserId}</span>}
                                        {userSuggestions.length > 0 && (
                                            <ul className="suggestion-list">
                                                {userSuggestions.map((user, index) => (
                                                    <li key={index} onClick={() => handleUserSelection(user)}>
                                                        {user?.username}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="textbox1">Bet Type</label>
                                        <select
                                            className="form-control"
                                            name="BetType"
                                            value={data.BetType}
                                            onChange={handleChange}
                                        >
                                            <option value="Yes">YES</option>
                                            <option value="No">NO</option>
                                        </select>
                                    </div>

                                    <div className="col  pl-1">
                                        <label htmlFor="textbox1">SIZE</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="Price"
                                            value={data?.Price}
                                            onChange={handleChange}
                                        />
                                        {formErrors.Price && <span className="error-message">{formErrors.Price}</span>}

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col ">
                                        <label htmlFor="textbox1">RUN</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="Odds"
                                            value={data?.Odds}
                                            onChange={handleChange}
                                        />
                                        {formErrors.Odds && <span className="error-message">{formErrors.Odds}</span>}

                                    </div>
                                    <div className="col  pl-1">
                                        <label htmlFor="textbox1">Stack</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="Stack"
                                            value={data?.Stack}
                                            onChange={handleChange}
                                        />
                                        {formErrors.Stack && <span className="error-message">{formErrors.Stack}</span>}

                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button className="modal-cancel-btn" data-dismiss="modal">
                                Close
                            </button>
                            <button
                                className="modal-submit-btn"
                                type="button"
                                data-dismiss="modal"
                                disabled={!isFormFilled}
                                onClick={saveFancy}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FancyPleaceBet
