import React, { useEffect, useState } from "react";
import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import moment from "moment";
import actions from "../../../redux/actions";
import ScreenLoader from "../../../components/ScreenLoader";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

const SettlementSummary = () => {
  const settlementSummaryData = useSelector(
    (state) => state.report?.settlementSummary?.Result
  );
  let totalrow = useSelector((state) => state.report?.settlementSummary?.Count);
  let { userId } = useParams();
  const [loader, setLoader] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [settlementSummaryDataReq, setsettlementSummaryDataReq] = useState({
    skipRec: 0,
    userId: userId,
  });

  const handleSettlementSummary = async () => {
    setLoader(true);
    let data = {
      skipRec: 0,
      userId: userId,
    };
    await actions.settlementSummaryAction(data);
    setLoader(false);
  };

  useEffect(() => {
    handleSettlementSummary();
  }, []);

  useEffect(() => {
    setTotalPage(Math.ceil(totalrow / 10));
  }, [totalrow]);

  const handlePageNoChange = (newPageNo) => {
    setsettlementSummaryDataReq((prevState) => ({
      ...prevState,
      skipRec: newPageNo,
    }));
    let data = { ...settlementSummaryDataReq, skipRec: newPageNo };
    actions.settlementSummaryAction(data);
  };

  const DynamicPagination = (number) => {
    const maxPagesToShow = 4; // Maximum number of pages to show
    const startPage = Math.max(
      1,
      Math.min(
        number - maxPagesToShow + 1,
        settlementSummaryDataReq?.skipRec + 1
      )
    ); // Determine the start page

    const divs = [];

    for (
      let i = startPage;
      i < startPage + maxPagesToShow && i <= number;
      i++
    ) {
      divs.push(
        <li key={i} className="page-item me-1">
          <button
            className={`${
              settlementSummaryDataReq?.skipRec + 1 === i
                ? "pagination-number "
                : "pagination-inactive-number "
            }`}
            onClick={() => {
              setsettlementSummaryDataReq((prevState) => ({
                ...prevState,
                skipRec: i - 1,
              }));
              handlePageNoChange(i - 1);
            }}
          >
            {i}
          </button>
        </li>
      );
    }
    return <>{divs}</>;
  };

  return (
    <NavbarSidebarWrapper>
      <div className="content-header">Settlement Summary</div>
      {loader ? (
        <ScreenLoader />
      ) : (
        <div className="card shadow mb-4">
          <div className="">
            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper dt-bootstrap4"
              >
                <table
                  className="table table-bordered dataTable"
                  id="dataTable"
                  width="100%"
                  cellSpacing={0}
                  role="grid"
                  aria-describedby="dataTable_info"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th className="col-sno">S.No.</th>
                      <th className="col-date">Name</th>
                      <th className="col-desc">Description</th>
                      <th className="col-credit">Amount</th>
                      <th className="col-balance">Remarks</th>
                      <th className="col-date">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {settlementSummaryData &&
                      Array.isArray(settlementSummaryData) &&
                      settlementSummaryData?.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.UserName}</td>
                          <td style={{ textWrap: "nowrap" }}>
                            <Link to={`/ShowMatchBet/${item?.EventId}/1`}>
                              {item?.Discription}
                            </Link>
                          </td>
                          <td className="green-text">{item?.Amount}</td>
                          <td>{item?.Remarks}</td>
                          <td style={{ textWrap: "nowrap" }}>
                            {" "}
                            {moment(item?.Date).format("DD-MM-YYYY h:mm:ss A")}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {settlementSummaryData &&
                  Array.isArray(settlementSummaryData) &&
                  settlementSummaryData?.length > 0 && (
                    <div className="row m-1">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="dataTable_info"
                          role="status"
                          aria-live="polite"
                        >
                          Total Page : {totalPage}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="dataTable_paginate"
                        >
                          <ul className="pagination d-flex justify-content-end">
                            <li
                              className="paginate_button page-item previous disabled"
                              id="dataTable_previous"
                            >
                              <button
                                className="pagination-action-btn"
                                disabled={
                                  settlementSummaryDataReq?.skipRec === 0
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  handlePageNoChange(
                                    settlementSummaryDataReq?.skipRec - 1
                                  );
                                }}
                              >
                                Previous
                              </button>
                            </li>
                            {DynamicPagination(totalPage)}
                            <li
                              className="paginate_button page-item next"
                              id="dataTable_next"
                            >
                              <button
                                className="pagination-action-btn"
                                disabled={
                                  settlementSummaryDataReq?.skipRec ===
                                  totalPage - 1
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  handlePageNoChange(
                                    settlementSummaryDataReq?.skipRec + 1
                                  );
                                }}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </NavbarSidebarWrapper>
  );
};

export default SettlementSummary;
