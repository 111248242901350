import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    fancyList: []

}
const fancySlice = createSlice({
    name: 'FancyData',
    initialState: initialState,
    reducers: {
        saveFancyList: (state, action) => {
            state.fancyList = action.payload
        },
        resetFancyData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const { resetFancyData, saveFancyList } = fancySlice.actions;
export default fancySlice.reducer