import React from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";
import Footer from './footer';
import LogoutModel from './Models/LogoutModel';
import MyChangePasswordModal from "./Models/MyChangePasswordModal";

const NavbarSidebarWrapper = (props) => {

    return (
        <div>
            <>
                {/* Page Wrapper */}
                <div id="wrapper">
                    {/* Sidebar */}
                    <Sidebar />
                    {/* End of Sidebar */}
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* Main Content */}
                        <div id="content">
                            {/* Topbar */}
                            <Navbar />
                            {/* End of Topbar */}
                            {/* Begin Page Content */}
                            <div className="mr-1 ml-2" style={{ marginTop: '-22px' }}>
                                {/* Page Heading */}
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                </div>
                                {/* Tabs Nav */}
                                {props?.children}
                            </div>
                            {/* /.container-fluid */}
                        </div>
                        {/* End of Main Content */}
                        {/* Footer */}
                        <Footer />
                        {/* End of Footer */}
                    </div>
                    {/* End of Content Wrapper */}
                </div>
                {/* Logout Modal*/}
                <LogoutModel />
                <MyChangePasswordModal/>
            </>
        </div>
    )
};

export default NavbarSidebarWrapper;
