import React, { useState, useEffect } from 'react';
import actions from '../../redux/actions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavbarSidebarWrapper from '../../components/navbarSidebarWrapper';
import { isRoleExits } from '../../utils';
import { notifyError } from '../../components/ToastContext';

const MarketResult = () => {
    const ResultList = useSelector((state) => state.match?.ResultList?.Result);
    let { marketid } = useParams();
    const [selectedRunnerId, setSelectedRunnerId] = useState(null);

    useEffect(() => {
        if (marketid) {
            actions.getResultListAction(marketid);
        }
    }, [marketid]);

    const handleDeclareWinner = async (e) => {
        e.preventDefault();
        if (selectedRunnerId) {
            await actions.declareWinnerAction(marketid, selectedRunnerId);
            actions.getResultListAction(marketid);
        } else {
            notifyError('No runner selected');
        }
    };

    const handleReverseCoin = async (e) => {
        e.preventDefault();
        await actions.reverseCoinAction(marketid);
        actions.getResultListAction(marketid);
    };

    const handleRunnerSelection = (e) => {
        setSelectedRunnerId(e.target.value);
    };

    return (
        <NavbarSidebarWrapper>
            <div className='content-header'>View Match</div>
            <div className="form-container">
                <form>
                    {ResultList && ResultList?.map((item, index) => (
                        <div className="row" key={index}>
                            <div className="col-6">
                                <div className="form-group">
                                    <select
                                        className='form-control'
                                        onChange={handleRunnerSelection}
                                    >
                                        <option value="Pending">Runner Name</option>
                                        {item?.RunnerName?.map((runner, runnerIndex) => (
                                            <option key={runnerIndex} value={runner?.id}>
                                                {runner?.RunnerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                {item?.IsBetExists ?
                                    (item.WinnerName == null || item.WinnerName == "" ?
                                        (isRoleExits('match_result') && <button className="btn btn-success btn-sm text-no-wrap text-white" onClick={handleDeclareWinner}>Declare Winner</button>)
                                        :
                                        (isRoleExits('match_rollback') && <button className="btn btn-danger btn-sm text-no-wrap text-white" onClick={handleReverseCoin}>Reverse Coin</button>)
                                    )
                                    : "No Bet Exists For This Market"}
                            </div>
                        </div>
                    ))}
                </form>
            </div>
        </NavbarSidebarWrapper>
    );
};

export default MarketResult;


