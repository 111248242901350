import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    marketList: [],
    CompitonList: [],
    marketByCompList: [],
    marketByEventIdList: [],
    marketIdsList: [],
    matchList: [],
    eventList: [],
    MarketSetting: {},
    MarketCurrentBets: [],
    MarketName: [],
    RunningFancyList: [],
    ResultList: [],
    MarketAnalysis: []
}
const matchSlice = createSlice({
    name: 'MatchData',
    initialState: initialState,
    reducers: {
        saveCompitDetails: (state, action) => {
            state.CompitonList = action.payload
        },
        saveCompitMarketDetails: (state, action) => {
            state.marketByCompList = action.payload
        },

        saveMarketDetails: (state, action) => {
            state.marketList = action.payload
        },
        saveMarketByEventId: (state, action) => {
            state.marketByEventIdList = action.payload
        },
        saveMarketIdsList: (state, action) => {
            state.marketIdsList = action.payload
        },
        saveMatchDetails: (state, action) => {
            state.matchList = action.payload
        },
        saveEventDetails: (state, action) => {
            state.eventList = action.payload
        },
        saveMarketSetting: (state, action) => {
            state.MarketSetting = action.payload
        },
        saveMarketCurrentBets: (state, action) => {
            state.MarketCurrentBets = action.payload
        },
        saveMarketName: (state, action) => {
            state.MarketName = action.payload
        },
        saveRunningFancy: (state, action) => {
            state.RunningFancyList = action.payload
        },
        saveResultList: (state, action) => {
            state.ResultList = action.payload
        },
        setMarketAnalysis: (state, action) => {
            state.MarketAnalysis = action.payload
        },
        resetMatchData: (state) => {
            Object.assign(state, initialState);
        }
    }
})

export const { resetMatchData, saveCompitDetails, saveCompitMarketDetails, saveMarketDetails, saveMarketByEventId, saveResultList, saveMarketIdsList, saveRunningFancy, saveMarketCurrentBets, saveMarketName, saveMarketSetting, saveMatchDetails, saveEventDetails, setMarketAnalysis } = matchSlice.actions;
export default matchSlice.reducer