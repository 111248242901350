import {
  GETCOMPETITONLISTAPI,
  GETMARKETBYCOMPLISTAPI,
  GETMARKETLISTAPI,
  POSTMARKETMODELAPI,
  POSTBULKMARKETMODELAPI,
  GETTRANSFERFANCYCOINSAPI,
  GETMARKETIDSLISTAPI,
  POSTMARKETCUSTOMAPI,
  SAVECUSTOMFANCYAPI,
  GETROLLBACKFACNYAPI,
  GETTERMINATEFANCYAPI,
  GETMARKETSETTINGAPI,
  GETRESULTLISTAPI,
  GETRUNNINGFANCYAPI,
  GETMARKETNAMEAPI,
  GETMARKETCURRENTBETSAPI,
  POSTMARKETSETTINGAPI,
  GETOPENCLOSEMARKETAPI,
  GETOPENMATCHAPI,
  GETOPENRETESEAPI,
  GETMATCHCONTROLAPI,
  GETEVENTCONTROLAPI,
  GETSUSPENDEDMATCHAPI,
  GETCOMPLETEDMATCHAPI,
  GETEVENTLISTAPI,
  POSTREVERCECOIN,
  POSTDECLAREWINNER,
  POSTROLLBACKMATCHAPI,
  GETMARKETANALYSISAPI,
} from "./url";
import axios from "axios";

class MatchServiceClass {
  async getCompetitionService(sportId) {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axios.get(`${GETCOMPETITONLISTAPI}/${sportId}`, {
      headers: headers,
    });
  }
  async getCoMpMarketDetailsService(sportId, compId) {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axios.get(`${GETMARKETBYCOMPLISTAPI}/${sportId}/${compId}`, {
      headers: headers,
    });
  }
  async getMarketDetailsService(sportId) {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axios.get(`${GETMARKETLISTAPI}/${sportId}`, {
      headers: headers,
    });
  }

  async getMarketByEventIdService(sportId, eventId) {
    const headers = {
      "Content-Type": "application/json",
    };
    return await axios.get(`${GETMARKETLISTAPI}/${eventId}/${sportId}`, {
      headers: headers,
    });
  }

  async getMarketIdsListService(sportId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${GETMARKETIDSLISTAPI}?sportsId=${sportId}`, {
      headers: headers,
    });
  }

  async postMarketModelService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    let API = Array.isArray(data) ? POSTBULKMARKETMODELAPI : POSTMARKETMODELAPI;
    return await axios.post(`${API}`, data, {
      headers: headers,
    });
  }

  async postMarketCustomService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.post(`${POSTMARKETCUSTOMAPI}`, data, {
      headers: headers,
    });
  }

  async BetLockMarketService(marketId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${GETOPENRETESEAPI}?marketId=${marketId}`, {
      headers: headers,
    });
  }

  async getOpenMatchListService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETOPENMATCHAPI}?sportsId=${data?.sportsId}&value=${data?.value}&skipRec=${data?.skipRec}&take=${data?.take}&type=${data?.type}`,
      {
        headers: headers,
      }
    );
  }

  async getSuspendedMatchListService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETSUSPENDEDMATCHAPI}?sportsId=${data?.sportsId}&value=${data?.value}&skipRec=${data?.skipRec}&take=${data?.take}`,
      {
        headers: headers,
      }
    );
  }

  async getCompletedMatchListService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETCOMPLETEDMATCHAPI}?sportsId=${data?.sportsId}&value=${data?.value}&skipRec=${data?.skipRec}&take=${data?.take}`,
      {
        headers: headers,
      }
    );
  }

  async getEventListService(eventId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${GETEVENTLISTAPI}?eventId=${eventId}`, {
      headers: headers,
    });
  }

  async matchControlService(eventId, status, type, marketTime) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETMATCHCONTROLAPI}?marketid=${eventId}&status=${status}&type=${type}&MarketTime=${marketTime}`,
      {
        headers: headers,
      }
    );
  }

  async eventControlService(eventId, status, type, marketTime) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETEVENTCONTROLAPI}?eventId=${eventId}&status=${status}&type=${type}&MarketTime=${marketTime}`,
      {
        headers: headers,
      }
    );
  }

  async openCloseMarketService(eventId, status) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETOPENCLOSEMARKETAPI}?eventId=${eventId}&status=${status}`,
      {
        headers: headers,
      }
    );
  }

  async getMarketSettingModelService(eventId, marketid) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETMARKETSETTINGAPI}?eventId=${eventId}&marketId=${marketid}`,
      {
        headers: headers,
      }
    );
  }
  async getMarketCurrentBetsService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETMARKETCURRENTBETSAPI}?userName=${data?.userName}&Eventid=${data?.Eventid}&marketType=${data?.marketType}&betStaus=${data?.betStaus}&fancyid=${data?.fancyid}&startDate=${data?.startDate}&endDate=${data?.endDate}&skipRec=${data?.skipRec}&pagesize=${data?.pagesize}`,
      {
        headers: headers,
      }
    );
  }
  async getMarketNameService(eventId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${GETMARKETNAMEAPI}?eventId=${eventId}`, {
      headers: headers,
    });
  }
  async getRunningFancyService(marketId, type) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETRUNNINGFANCYAPI}?marketId=${marketId}&type=${type}`,
      {
        headers: headers,
      }
    );
  }
  async getResultListService(marketId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${GETRESULTLISTAPI}?marketId=${marketId}`, {
      headers: headers,
    });
  }
  async transferFancyCoinsService(marketId, selectionId, runValue) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETTRANSFERFANCYCOINSAPI}?marketId=${marketId}&selectionId=${selectionId}&runValue=${runValue}`,
      {
        headers: headers,
      }
    );
  }
  async terminateFancyService(marketId, selectionId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETTERMINATEFANCYAPI}?marketId=${marketId}&selectionId=${selectionId}`,
      {
        headers: headers,
      }
    );
  }
  async rollBackFancyResultService(marketId, selectionId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${GETROLLBACKFACNYAPI}?marketId=${marketId}&selectionId=${selectionId}`,
      {
        headers: headers,
      }
    );
  }
  async postMarketSettingModelService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.post(`${POSTMARKETSETTINGAPI}`, data, {
      headers: headers,
    });
  }
  async saveCustomFancyService(data) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.post(`${SAVECUSTOMFANCYAPI}`, data, {
      headers: headers,
    });
  }
  async declareWinnerService(marketId, selectionId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(
      `${POSTDECLAREWINNER}?marketId=${marketId}&selectionId=${selectionId}`,
      {
        headers: headers,
      }
    );
  }
  async reverseCoinService(marketId) {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${POSTROLLBACKMATCHAPI}?marketId=${marketId}`, {
      headers: headers,
    });
  }

  async GetMarkerAnalysisService() {
    let logindata = JSON.parse(sessionStorage.getItem("loginUserDetails"));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${logindata?.Token}`,
    };
    return await axios.get(`${GETMARKETANALYSISAPI}`, {
      headers: headers,
    });
  }
}

const MatchService = new MatchServiceClass();

export default MatchService;
