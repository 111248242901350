import React, { useEffect, useState } from 'react'
import NavbarSidebarWrapper from '../../../components/navbarSidebarWrapper';
import actions from '../../../redux/actions';
import ScreenLoader from '../../../components/ScreenLoader';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as BiIcons from 'react-icons/bi';
import * as AiIcons from 'react-icons/ai';
import { notifyError, notifySuccess } from '../../../components/ToastContext';

const UserSetting = () => {
    const userSettingData = useSelector((state) => state.client?.userSettingList?.Result)
    const location = useLocation();
    const userId = location?.state?.userId;
    const [loader, setLoader] = useState(true);
    const [userSettingList, setUserSettingList] = useState([]);
    const [showTextBox, setShowTextBox] = useState({
        index: -1,
        show: false
    });

    const handleUserSettingApi = async () => {
        setLoader(true)
        await actions.userSettingAction(userId)
        setLoader(false)
    }

    useEffect(() => {
        handleUserSettingApi()
    }, [])

    useEffect(() => {
        setUserSettingList(userSettingData);
    }, [userSettingData])

    const handleAction = (index) => {
        setShowTextBox({ ...showTextBox, index })
    }
    const handleSave = async (index) => {
        setShowTextBox({ ...showTextBox, index: -1 })
        try {
            let res = await actions.saveUserSettingAction(userSettingList[index])
            if (res?.data?.Status) {
                notifySuccess(res?.data?.Result)
                actions.userSettingAction(userId)
            } else {
                notifyError("Something wants wrong");
            }
        } catch (error) {
            notifyError(error);
        }
    }
    const handleAllSave = async (index) => {
        setShowTextBox({ ...showTextBox, index: -1 })
        try {
            let res = await actions.saveAllUserSettingAction(userSettingList[index])
            if (res?.data?.Status) {
                notifySuccess(res?.data?.Result)
                actions.userSettingAction(userId)
            } else {
                notifyError("Something wants wrong");
            }
        } catch (error) {
            notifyError(error);
        }
    }

    const handleChange = (value, name, index) => {
        const updatedItems = [...userSettingList];
        const updatedItem = { ...updatedItems[index] };
        updatedItem[name] = value;
        updatedItems[index] = updatedItem;
        setUserSettingList(updatedItems);
    }

    return (
        <NavbarSidebarWrapper>
            <div className='content-header'>User Setting</div>
            {loader ? <ScreenLoader /> :
                <div className="card shadow mb-4">
                    <div className="">
                        <div className="table-responsive">
                            <div id="dataTable_wrapper" className="dataTables_wrapper dt-bootstrap4">
                                <table className="table table-bordered dataTable" id="dataTable" width="100%" cellSpacing={0} role="grid" aria-describedby="dataTable_info" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th className="col-sno">Sport Name</th>
                                            {/*<th className="col-date">Match Commission Type</th>
                                            <th className="col-desc">Fancy Commission Type</th>
                                            <th className="col-credit">Match Comm.</th>
                                            <th className="col-balance">Session Comm.</th>
                                        
                                            <th className="col-date">Session Min Stack</th>
                                            <th className="col-date">Session Max Stack</th>
                                            <th className="col-date">Max Odds</th>
                                           
                                            <th className="col-date">Bet Delay</th>
                                            <th className="col-date">Session Delay</th>
                                             */}
                                            <th className="col-date">Max Profit</th>
                                            <th className="col-date">Odds Max Stack</th>
                                            <th className="col-date">Odds Min Stack</th>
                                            <th className="col-date">Actions</th>
                                            <th className="col-date">Apply To Child</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userSettingList && Array.isArray(userSettingList) && userSettingList?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.SportsName}</td>
                                                    {/*   <td>
                                                        <select className='form-control' name="MatchComType" value={item?.MatchComType} readOnly={showTextBox?.index !== index} disabled={showTextBox?.index !== index} style={{ width: '100px' }} onChange={(e) => { handleChange(e.target.value, e.target.name, index) }}>
                                                            <option value="Minus">Minus</option>
                                                            <option value="Plus">Plus</option>
                                                            <option value="None">None</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className='form-control' name="FancyComType" value={item?.FancyComType} readOnly={showTextBox?.index !== index} disabled={showTextBox?.index !== index} style={{ width: '100px' }} onChange={(e) => { handleChange(e.target.value, e.target.name, index) }}>
                                                            <option value="Minus">Minus</option>
                                                            <option value="Plus">Plus</option>
                                                            <option value="None">None</option>
                                                        </select>
                                                    </td>
                                     */}
                                                    {showTextBox?.index !== index &&
                                                        <>
                                                            {/* <td>{item?.MatchCom}</td>
                                                            <td>{item?.SessinCom}</td>
                                                          
                                                            <td>{item?.SessionMinStake}</td>
                                                            <td>{item?.SessionMaxStake}</td>
                                                            <td>{item?.OddsMax}</td>
                                                            <td>{item?.BetDelay}</td>
                                                            <td>{item?.FancyDelay}</td>
                                                             */}
                                                            <td style={{ textWrap: 'nowrap' }}>{item?.MaxProfit}</td>
                                                            <td>{item?.StakeMax}</td>
                                                            <td>{item?.StakeMin}</td>

                                                        </>
                                                    }
                                                    {showTextBox?.index === index &&
                                                        <>
                                                            {/*   <td>
                                                                <input type="text" className='form-control no-wrap' value={item?.MatchCom} name="MatchCom" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.SessinCom} name="SessinCom" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                           
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.SessionMinStake} name="SessionMinStake" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.SessionMaxStake} name="SessionMaxStake" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.OddsMax} name="OddsMax" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                           
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.BetDelay} name="BetDelay" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.FancyDelay} name="FancyDelay" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                             */}
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.MaxProfit} name="MaxProfit" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.StakeMax} name="StakeMax" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                            <td>
                                                                <input type="text" className='form-control' value={item?.StakeMin} name="StakeMin" style={{ width: '70px' }} onChange={(e) => { handleChange(e.target.value === "" ? 0 : parseInt(e.target.value), e.target.name, index) }} />
                                                            </td>
                                                        </>
                                                    }
                                                    {showTextBox?.index === index &&
                                                        <>
                                                            <td style={{ cursor: 'pointer' }} onClick={() => { handleSave(index) }}>
                                                                <span><AiIcons.AiOutlineSave style={{ fontSize: '16px' }} /></span>
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }} onClick={() => { handleAllSave(index) }}>
                                                                <span ><AiIcons.AiOutlineSave style={{ fontSize: '16px' }} /></span>
                                                            </td>
                                                        </>
                                                    }
                                                    {showTextBox?.index !== index &&
                                                        <>
                                                            <td style={{ cursor: 'pointer' }} onClick={() => { handleAction(index) }}>
                                                                <span><BiIcons.BiEdit style={{ fontSize: '16px' }} /></span>
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }} onClick={() => { handleAction(index) }}>
                                                                <span><BiIcons.BiEdit style={{ fontSize: '16px' }} /></span>
                                                            </td>
                                                        </>}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </NavbarSidebarWrapper>
    )
}

export default UserSetting;
